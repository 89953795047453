import { Dispatch, SetStateAction, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import InputPhone from "src/components/InputPhone";
import Button from "src/components/Button";
import styles from "./styles.module.scss";
import { GetPlanner } from "src/graphql/queries";
import { AddEventVendor } from "src/graphql/mutations";
import { addEventVendor, addEventVendorVariables } from "src/types/api";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";

interface Values {
  phoneNumberValue: string;
  teamname: string;
  teamrole: string;
  email: string;
  instagram: string;
}

const AddVendorFormSchema = Yup.object().shape({
  teamname: Yup.string()
    .min(2, "Too Short!")
    .required("Please fill in your vendor's name."),
  teamrole: Yup.string()
    .min(2, "Too Short!")
    .matches(/^[aA-zZ\s]+$/, "Please fill in only alphabets")
    .required("Please fill in your vendor's role"),
  email: Yup.string().email("Please enter a correct email address"),
  instagram: Yup.string().optional(),
});

interface AddPlannerProps {
  page: number;
  pageSize: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddPlanner = ({ page, pageSize, setOpen }: AddPlannerProps) => {
  const { event } = useAppStore((state) => state);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const navigate = useNavigate();

  const [addEventVendorFn, { loading }] = useMutation<
    addEventVendor,
    addEventVendorVariables
  >(AddEventVendor, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">You have successfully updated a vendor.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={`${styles["addPlannerModal--content"]} ${styles["show"]}`}>
      <h4> Add Vendor </h4>
      <Formik
        initialValues={{
          teamname: "",
          teamrole: "",
          email: "",
          instagram: "",
          phoneNumberValue: "",
        }}
        validationSchema={AddVendorFormSchema}
        onSubmit={(values) => {
          addEventVendorFn({
            variables: {
              input: {
                event_id: event.id,
                role: values.teamrole,
                name: values.teamname,
                email: values.email,
                instagram_handle: values.instagram,
                phone_number: `${phoneNumberValue}`,
              },
            },
            refetchQueries: [
              {
                query: GetPlanner,
                variables: { eventId: event.id, page, pageSize },
              },
            ],
          });
          // actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["addPlannerModal--content-form"]}>
            <div className={styles["addPlannerModal--content-form_container"]}>
              <Input
                type="text"
                name="teamname"
                label="Vendor Name"
                placeholder="e.g Steve Bakery"
              />
              <Input
                type="text"
                name="teamrole"
                label="Vendor Role"
                placeholder="e.g Catering Service"
              />
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="user@gmail.com"
              />
              <InputPhone
                name="number"
                label="WhatsApp Number"
                placeholder="Enter WhatsApp Number"
                value={phoneNumberValue}
                setValue={setPhoneNumberValue}
              />
              <Input
                type="text"
                name="instagram"
                label="Instagram (Optional)"
                placeholder="@vendorname"
              />
            </div>
            <div className={styles["addPlannerModal--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Vendor"
                  loader={loading}
                  disabled={
                    props.values.teamname === "" ||
                    props.values.teamrole === "" ||
                    phoneNumberValue === ""
                  }
                >
                  Add Vendor
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {/*  */}
    </div>
  );
};

export default AddPlanner;
