import { useState } from "react";
import Icon from "src/components/Icon";
import styles from "./backstory.module.scss";
import { useQuery } from "@apollo/client";
import { GetBackStory } from "src/graphql/queries";
import BackstoryCard from "./BackstoryCard";
import InnerLoading from "src/components/InnerLoading";
import AddBackstory from "./AddBackstory";
// import Pagination from "src/components/Pagination";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { getBackStory } from "src/types/api";
import Dialog from "src/components/Dialog";
import Info from "src/components/Info";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";
import NoBackStory from "src/assets/icons/NoBackStory";

const Backstory = () => {
  const [openAddBackstory, setOpenAddBackstory] = useState(false);
  // const [totalPages, setTotalPages] = useState(1);
  // const [startPage, setStartPage] = useState(1);
  // const [pageSize, setPageSize] = useState(100);
  // const [page, setPage] = useState(1);
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { data: stories, loading: getBackStoryLoading } =
    useQuery<getBackStory>(GetBackStory, {
      variables: {
        eventId: event.id,
        page: 1,
        pageSize: 20,
      },
      // onCompleted(response) {
      //   if (!!response?.getBackStory && response?.getBackStory.length) {
      //     setTotalPages(Math.floor(response?.getBackStory.length / pageSize));
      //   }
      // },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const emptyBackstory = (
    <div className={styles["backstory__empty"]}>
      <div className={styles["backstory__empty-container"]}>
        <Info>
          <Icon iconName="bulb" />
          <p>
            We all love a good love story, tell us about yours by adding a
            backstory
          </p>
        </Info>
        <NoBackStory />
        <p>Your haven't told us a story yet.</p>

        <div className={styles["backstory__empty-button-container"]}>
          <div className={styles["backstory__empty-button"]}>
            <Dialog
              open={openAddBackstory}
              onOpenChange={setOpenAddBackstory}
              trigger={
                <div
                  onClick={() => setOpenAddBackstory(true)}
                  className={styles["backstory__trigger"]}
                >
                  <span>Add Backstory</span>
                </div>
              }
            >
              <AddBackstory setToggleModal={setOpenAddBackstory} />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );

  const isEmpty = !!(
    stories === undefined || stories?.getBackStory?.length === 0
  );

  return (
    <>
      {getBackStoryLoading ? (
        <div className={styles["backstory__loading"]}>
          <InnerLoading />
        </div>
      ) : isEmpty ? (
        emptyBackstory
      ) : (
        <div className={styles["backstory"]}>
          <div className={styles["backstory__add-cards-container"]}>
            <div className={styles["backstory__add-cards"]}>
              <Dialog
                open={openAddBackstory}
                onOpenChange={setOpenAddBackstory}
                trigger={
                  <div
                    onClick={() => setOpenAddBackstory(true)}
                    className={styles["backstory__trigger"]}
                  >
                    <span>Add Backstory</span>
                  </div>
                }
              >
                <AddBackstory setToggleModal={setOpenAddBackstory} />
              </Dialog>
            </div>
          </div>
          <div className={styles["backstory__container"]}>
            {stories?.getBackStory?.map((element) => {
              return <BackstoryCard key={uuidv4()} story={element} />;
            })}
          </div>
          {/* {!getBackStoryLoading && stories?.getBackStory && (
            <Pagination
              pageSize={pageSize}
              startPage={startPage}
              setPageSize={setPageSize}
              setStartPage={setStartPage}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default Backstory;
