import {
  OperationVariables,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useCustomQuery = <T,>(
  query: TypedDocumentNode<T>,
  variables: OperationVariables,
  validate: boolean = true
) => {
  const navigate = useNavigate();

  const { data, loading } = useQuery<T>(query, {
    variables,
    skip: validate,
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return { data, isLoading: loading && !data, isRefetching: data && loading };
};

export default useCustomQuery;
