const NoInviteList = () => {
  return (
    <svg
      width="154"
      height="77"
      viewBox="0 0 154 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="38.2908" cy="26.1311" r="25.1311" fill="#619D94" />
      <path
        d="M75.9881 76.393C75.9881 71.4426 75.0131 66.5407 73.1186 61.9671C71.2242 57.3935 68.4475 53.2379 64.947 49.7374C61.4466 46.2369 57.2909 43.4602 52.7173 41.5658C48.1437 39.6713 43.2418 38.6963 38.2914 38.6963C33.341 38.6963 28.4391 39.6713 23.8655 41.5658C19.2919 43.4602 15.1363 46.2369 11.6358 49.7374C8.13537 53.2379 5.35865 57.3935 3.46422 61.9671C1.56978 66.5407 0.594726 71.4426 0.594727 76.393L38.2914 76.393H75.9881Z"
        fill="url(#paint0_linear_790_12855)"
      />
      <path
        d="M75.9881 76.393C75.9881 71.4426 75.0131 66.5407 73.1186 61.9671C71.2242 57.3935 68.4475 53.2379 64.947 49.7374C61.4466 46.2369 57.2909 43.4602 52.7173 41.5658C48.1437 39.6713 43.2418 38.6963 38.2914 38.6963C33.341 38.6963 28.4391 39.6713 23.8655 41.5658C19.2919 43.4602 15.1363 46.2369 11.6358 49.7374C8.13537 53.2379 5.35865 57.3935 3.46422 61.9671C1.56978 66.5407 0.594726 71.4426 0.594727 76.393L38.2914 76.393H75.9881Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g opacity="0.7">
        <path
          d="M86 9.9C86 4.43238 90.4324 0 95.9 0H144.1C149.568 0 154 4.43238 154 9.9V66.1C154 71.5676 149.568 76 144.1 76H95.9C90.4324 76 86 71.5676 86 66.1V9.9Z"
          fill="url(#paint1_linear_790_12855)"
        />
        <path
          d="M86 9.9C86 4.43238 90.4324 0 95.9 0H144.1C149.568 0 154 4.43238 154 9.9V66.1C154 71.5676 149.568 76 144.1 76H95.9C90.4324 76 86 71.5676 86 66.1V9.9Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M93.6534 34.6182C92.7419 34.6182 92.0029 33.8792 92.0029 32.9676L92.0029 32.4175C92.0029 31.5059 92.7419 30.767 93.6534 30.767L132.165 30.767C133.077 30.767 133.816 31.5059 133.816 32.4175L133.816 32.9676C133.816 33.8792 133.077 34.6182 132.165 34.6182L93.6534 34.6182Z"
        fill="url(#paint2_linear_790_12855)"
      />
      <path
        d="M93.6534 34.6182C92.7419 34.6182 92.0029 33.8792 92.0029 32.9676L92.0029 32.4175C92.0029 31.5059 92.7419 30.767 93.6534 30.767L132.165 30.767C133.077 30.767 133.816 31.5059 133.816 32.4175L133.816 32.9676C133.816 33.8792 133.077 34.6182 132.165 34.6182L93.6534 34.6182Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g opacity="0.7">
        <path
          d="M98.356 43.1202C98.356 42.2087 99.0949 41.4697 100.006 41.4697H138.519C139.43 41.4697 140.169 42.2087 140.169 43.1202V43.6704C140.169 44.582 139.43 45.3209 138.519 45.3209H100.006C99.0949 45.3209 98.356 44.582 98.356 43.6704V43.1202Z"
          fill="url(#paint3_linear_790_12855)"
        />
        <path
          d="M98.356 43.1202C98.356 42.2087 99.0949 41.4697 100.006 41.4697H138.519C139.43 41.4697 140.169 42.2087 140.169 43.1202V43.6704C140.169 44.582 139.43 45.3209 138.519 45.3209H100.006C99.0949 45.3209 98.356 44.582 98.356 43.6704V43.1202Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M98.356 53.1202C98.356 52.2087 99.0949 51.4697 100.006 51.4697H138.519C139.43 51.4697 140.169 52.2087 140.169 53.1202V53.6704C140.169 54.582 139.43 55.3209 138.519 55.3209H100.006C99.0949 55.3209 98.356 54.582 98.356 53.6704V53.1202Z"
          fill="url(#paint4_linear_790_12855)"
        />
        <path
          d="M98.356 53.1202C98.356 52.2087 99.0949 51.4697 100.006 51.4697H138.519C139.43 51.4697 140.169 52.2087 140.169 53.1202V53.6704C140.169 54.582 139.43 55.3209 138.519 55.3209H100.006C99.0949 55.3209 98.356 54.582 98.356 53.6704V53.1202Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M98.356 63.1202C98.356 62.2087 99.0949 61.4697 100.006 61.4697H138.519C139.43 61.4697 140.169 62.2087 140.169 63.1202V63.6704C140.169 64.582 139.43 65.3209 138.519 65.3209H100.006C99.0949 65.3209 98.356 64.582 98.356 63.6704V63.1202Z"
          fill="url(#paint5_linear_790_12855)"
        />
        <path
          d="M98.356 63.1202C98.356 62.2087 99.0949 61.4697 100.006 61.4697H138.519C139.43 61.4697 140.169 62.2087 140.169 63.1202V63.6704C140.169 64.582 139.43 65.3209 138.519 65.3209H100.006C99.0949 65.3209 98.356 64.582 98.356 63.6704V63.1202Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <circle
        cx="99.6599"
        cy="18.5002"
        r="4.50024"
        fill="url(#paint6_linear_790_12855)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_790_12855"
          x1="16.0848"
          y1="38.6963"
          x2="41.8686"
          y2="96.4699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_790_12855"
          x1="99.971"
          y1="2.12683e-07"
          x2="169.809"
          y2="70.0067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_790_12855"
          x1="92.0029"
          y1="33.8269"
          x2="92.8126"
          y2="25.9807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_790_12855"
          x1="106.947"
          y1="41.4697"
          x2="107.525"
          y2="48.5001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_790_12855"
          x1="106.947"
          y1="51.4697"
          x2="107.525"
          y2="58.5001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_790_12855"
          x1="106.947"
          y1="61.4697"
          x2="107.525"
          y2="68.5001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_790_12855"
          x1="99.6599"
          y1="14"
          x2="105.359"
          y2="18.1368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37645D" />
          <stop offset="1" stopColor="#1F5C53" stopOpacity="0.38" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoInviteList;
