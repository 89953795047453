import {
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useCustomMutation = <T = unknown, V = OperationVariables>(
  mutation: TypedDocumentNode<T, V>,
  { onSuccess }: { onSuccess?: (data?: T) => void }
) => {
  const navigate = useNavigate();

  const [mutate, { loading: mutating, data }] = useMutation<T, V>(mutation, {
    onCompleted(data) {
      onSuccess?.(data);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return { mutate, mutating, data };
};

export default useCustomMutation;
