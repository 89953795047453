import NoHistory from "src/assets/icons/NoHistory";
import styles from "./styles.module.scss";

function EmptyState() {
  return (
    <div className={styles["EmptyState"]}>
      <div className={styles["EmptyState--main"]}>
        <NoHistory />

        <p>You haven’t received any gift yet</p>
      </div>
    </div>
  );
}

export default EmptyState;
