import Button from "src/components/Button";
import {
  ReactNode,
  ReactElement,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import styles from "./dashboard.module.scss";
import Icon from "src/components/Icon";
import {
  Bag,
  Chart,
  Global,
  Guests,
  Menu,
  Note,
  People,
  PersonalCard,
  Photobook,
  Wallet,
} from "./Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import withAuth from "src/hocs/withAuth";
import { AppContext, ValueProps } from "src/context";
import { useMutation, useQuery } from "@apollo/client";
import { EventParts, GetEventsForDashboard } from "src/graphql/queries";
import * as Popover from "@radix-ui/react-popover";
import * as Avatar from "@radix-ui/react-avatar";
import * as Tooltip from "@radix-ui/react-tooltip";
import toast from "react-hot-toast";
import {
  eventParts,
  getEventsForDashboard,
  getTeamMember,
  getTeamMemberVariables,
  removeEventVendor,
  removeTeamMemberVariables,
} from "src/types/api";
import { v4 as uuidv4 } from "uuid";
import DeleteModal from "src/components/DeleteModal";
import AddTeamMemberModal from "./AddTeamMember";
import Dialog from "src/components/Dialog";
import { GetTeamMember } from "src/graphql/queries/event.queries";
import { RemoveTeamMember } from "src/graphql/mutations/event.mutation";
import { getRefreshToken } from "src/helper/storage";
import { LiveHall } from "./Icons";
import moment from "moment";

import PageLoader from "src/components/PageLoader";
import useAppStore from "src/store/utils";

interface DashboardProps {
  children: ReactNode;
  pageName?: string;
}

interface Links {
  url: string;
  name: string;
  Icon?: ReactElement | null;
}

export const PryColor: string = "#34544f";

export const SecColor: string = "#808080";

const DashboardLayout = ({ children, pageName }: DashboardProps) => {
  const { width } = useWindowDimensions();

  const navigate = useNavigate();
  const [toggle, setToggle] = useState<boolean>(false);
  const [floatButton, setFloatButton] = useState<boolean>(false);
  const { pathname } = useLocation();
  const contextValue = useContext<ValueProps | null>(AppContext);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [addTeam, setAddTeam] = useState<boolean>(false);
  const [daysToStartDate, setDaysToStartDate] = useState(0);
  const setPhone = contextValue?.setPhone;
  const isAdmin = contextValue?.isAdmin;
  const setIsAdmin = contextValue?.setIsAdmin;
  const setWalletCode = contextValue?.setWalletCode;
  const eventPreferences = contextValue?.eventPreferences;
  const setEventPreferences = contextValue?.setEventPreferences;
  const setEventName = contextValue?.setEventName;
  const eventName = contextValue?.eventName;
  const refreshToken = getRefreshToken();
  const splitLocation = pathname.split("/");
  const { event, setEvent } = useAppStore((state) => state);
  const [ongoing, setOngoing] = useState<boolean>(false);

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const setActiveLink = useCallback(
    (path: string) => {
      return splitLocation[1] === path ? true : false;
    },
    [splitLocation]
  );

  const openSidebar = {
    width: toggle ? `100%` : `0px`,
    transform: toggle ? `translateX(${0}px` : `translateX(${-500}px`,
    opacity: toggle ? `1` : `0`,
  };

  const closeSidebar = {
    // display: "none",
    transform: "none",
    opacity: "1",
  };

  const changeOpacity = {
    display: "flex",
    opacity: "1",
  };

  const routeToLobby = () => {
    navigate("/events");
    setDropdown(!dropdown);
  };

  const handleLink = (id: number, uuid: string, walletId?: string) => {
    navigate("/my-plan");

    setEvent({ id, uuid });

    if (setWalletCode && walletId !== undefined) {
      localStorage.setItem("walletCode", JSON.stringify(walletId));
      setWalletCode(walletId);
    }
  };

  const links: Links[] = [
    {
      url: "my-plan",
      name: "My Plan",
      Icon: <Chart fill={setActiveLink("my-plan") ? PryColor : SecColor} />,
    },
    {
      url: "guests",
      name: "Guests",
      Icon: <Guests fill={setActiveLink("guests") ? PryColor : SecColor} />,
    },
    {
      url: "invitations",
      name: "Invitations",
      Icon: (
        <PersonalCard
          fill={setActiveLink("invitations") ? PryColor : SecColor}
        />
      ),
    },
    {
      url: "rsvp",
      name: "RSVP",
      Icon: <Menu fill={setActiveLink("rsvp") ? PryColor : SecColor} />,
    },
    {
      url: "event-details",
      name: "Event Details",
      Icon: (
        <Note fill={setActiveLink("event-details") ? PryColor : SecColor} />
      ),
    },
    {
      url: "gifts",
      name: "Gifts",
      Icon: <Bag fill={setActiveLink("gifts") ? PryColor : SecColor} />,
    },
    {
      url: "wallet",
      name: "Wallet",
      Icon: <Wallet fill={setActiveLink("wallet") ? PryColor : SecColor} />,
    },
    {
      url: "event-vendors",
      name: "Event Vendors",
      Icon: (
        <People fill={setActiveLink("event-vendors") ? PryColor : SecColor} />
      ),
    },
    {
      url: "website",
      name: "Website",
      Icon: <Global fill={setActiveLink("website") ? PryColor : SecColor} />,
    },
    {
      url: "live-hall",
      name: "Live Hall",
      Icon: (
        <LiveHall fill={setActiveLink("live-hall") ? PryColor : SecColor} />
      ),
    },
    {
      url: "photobook",
      name: "Photobook",
      Icon: (
        <Photobook fill={setActiveLink("photobook") ? PryColor : SecColor} />
      ),
    },
  ];

  const { loading: loadingDashboardEventsData, data: dashboardEventsData } =
    useQuery<getEventsForDashboard>(GetEventsForDashboard, {
      variables: {
        isInvited: false,
        page: 1,
        pageSize: 20,
      },
      fetchPolicy: "cache-first",
      onCompleted(res) {
        if (res.user?.phone_number && setPhone) {
          setPhone(res.user.phone_number);
        }
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const { loading: loadingTeamData, data: teamData } = useQuery<
    getTeamMember,
    getTeamMemberVariables
  >(GetTeamMember, {
    skip: !event.id,
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "cache-first",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const [removeTeamMemberFn, { loading: loadingRemoveTeamMember }] =
    useMutation<removeEventVendor, removeTeamMemberVariables>(
      RemoveTeamMember,
      {
        onCompleted() {
          toast.success(
            <p className="toast">
              You have successfully deleted the team member.
            </p>
          );
        },
        onError(error) {
          if (error?.message !== "unauthenticated") {
            toast.error(
              <p className="toast">{error?.message ?? "An error occured"}</p>
            );
          } else {
            navigate("/clear");
          }
        },
      }
    );

  useEffect(() => {
    const currentElement =
      !loadingDashboardEventsData &&
      !!dashboardEventsData &&
      dashboardEventsData.events?.find((item) => item!.id === event.id);

    if (currentElement) {
      if (setEventName) {
        setEventName(currentElement?.name);
      }
      setEvent({
        uuid: currentElement?.uuid,
        slug: currentElement?.slug!,
      });

      // set the users role of the event
      setIsAdmin && setIsAdmin(currentElement?.is_admin);

      if (
        currentElement.event_wallet?.find(
          (item) => !!item && item.currency === "ngn"
        )?.id
      ) {
        localStorage.setItem(
          "walletCode",
          JSON.stringify(
            currentElement.event_wallet?.find(
              (item) => !!item && item.currency === "ngn"
            )?.id
          )
        );
      }

      if (
        setWalletCode &&
        currentElement.event_wallet?.find(
          (item) => !!item && item.currency === "ngn"
        )?.id
      ) {
        setWalletCode(
          currentElement.event_wallet?.find(
            (item) => !!item && item.currency === "ngn"
          )?.id
        );
      }

      if (setEventPreferences && eventPreferences) {
        setEventPreferences({
          ...eventPreferences,
          live_hall: currentElement.event_preference?.live_hall,
          private: currentElement.event_preference?.private,
          asoebi_selection: currentElement.event_preference?.asoebi_selection,
          reservation: currentElement.event_preference?.reservation,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardEventsData, event.id, loadingDashboardEventsData]);

  const handleRemoveTeamMember = (ownerId: number) => {
    removeTeamMemberFn({
      variables: {
        eventId: event.id,
        ownerId: ownerId,
      },
      refetchQueries: [
        {
          query: GetEventsForDashboard,
          variables: {
            isInvited: false,
            page: 1,
            pageSize: 20,
          },
        },
      ],
    });
  };

  const { data: eventsData, loading: loadingCountdown } = useQuery<eventParts>(
    EventParts,
    {
      variables: {
        eventId: event.id,
        page: 1,
        pageSize: 50,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error occurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    }
  );

  useEffect(() => {
    if (!eventsData?.eventParts?.length) return;

    const now = moment();
    let isOngoing = false;
    let closestUpcomingDays = Infinity;

    eventsData.eventParts.forEach((event) => {
      const eventStart = moment(event.time);
      const daysLeft = eventStart.diff(now, "days");

      // Check if any event is ongoing
      if (now.isSame(eventStart, "day")) {
        isOngoing = true;
      }

      // Find closest upcoming event
      if (daysLeft > 0 && daysLeft < closestUpcomingDays) {
        closestUpcomingDays = daysLeft;
      }
    });

    setOngoing(isOngoing);
    setDaysToStartDate(
      //TODO: remove +1 day once the lagging date issue has been resolved.
      closestUpcomingDays === Infinity ? 0 : closestUpcomingDays + 1
    );
  }, [eventsData]);

  return (
    <PageLoader loading={loadingDashboardEventsData && initialLoad}>
      <div className={styles["dashboardLayout"]}>
        <div
          className={styles[`dashboardLayout__sidebar`]}
          style={width < 769 ? openSidebar : closeSidebar}
        >
          <div className={styles["dashboardLayout__sidebar--mobile"]}>
            <span
              onClick={() => setToggle(!toggle)}
              className={styles["dashboardLayout__sidebar--mobile-close"]}
            >
              <Icon iconName="close2" />
            </span>

            <div className={styles["dashboardLayout__sidebar--mobile-logo"]}>
              <Icon iconName="logo" />
            </div>

            <Popover.Root>
              <Popover.Trigger className={styles["dashboardLayout__popover"]}>
                <div
                  className={`${styles["dashboardLayout__main__event-title"]} ${styles["dashboardLayout__main__event-title__aside"]}`}
                >
                  <p>
                    {loadingDashboardEventsData && !!dashboardEventsData ? (
                      <>&nbsp;</>
                    ) : eventName ? (
                      `${eventName}`
                    ) : (
                      "..."
                    )}
                  </p>
                  <Icon iconName="caretDouble" />
                </div>
              </Popover.Trigger>

              <Popover.Content
                className={styles["dashboardLayout__main__event-list"]}
              >
                {!loadingDashboardEventsData && !!dashboardEventsData && (
                  <div
                    className={styles["dropdown"]}
                    style={dropdown && width < 670 ? changeOpacity : {}}
                  >
                    <div className={styles["dropdown-item"]}>
                      <ul className={styles["dropdown-item--content"]}>
                        <li className={styles["dropdown-item--list--first"]}>
                          <p>{eventName ? `${eventName}` : "..."}</p>
                        </li>
                        {!!dashboardEventsData.events &&
                          dashboardEventsData.events
                            .filter((item) => item!.name !== eventName)
                            .map((data, _i) => (
                              <li
                                key={uuidv4()}
                                className={styles["dropdown-item--list"]}
                                onClick={() =>
                                  handleLink(
                                    data!.id,
                                    data!.uuid,
                                    data?.event_wallet?.find(
                                      (item) =>
                                        !!item && item.currency === "ngn"
                                    )?.id
                                  )
                                }
                              >
                                {data!.name}
                              </li>
                            ))}
                        <li
                          onClick={routeToLobby}
                          className={styles["dropdown-item--list--last"]}
                        >
                          <Button
                            label="back to lobby"
                            type="button"
                            handleClick={routeToLobby}
                          >
                            <Icon iconName="redArrowLeft" />
                            Back To Lobby
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </Popover.Content>
            </Popover.Root>
          </div>

          <ul className={styles["dashboardLayout__sidebar--links"]}>
            <div className="flex flex-col justify-start mb-6">
              <div
                className={styles["dashboardLayout__sidebar--links-logo"]}
                onClick={routeToLobby}
              >
                <Icon iconName="logo" />
              </div>
              <div className="flex items-center gap-3 ml-[0.75rem]">
                <div>
                  <p className="text-[#C6C6C6] text-[18.27px] font-medium">
                    WEDDING
                  </p>
                </div>
                <div className="px-[6.14px] py-[7.26px] rounded-[8.37px] border-[0.7px] border-[rgba(198,198,198,0.40)] bg-white">
                  <p className="text-[#ACACAC] text-base font-medium">
                    {loadingCountdown
                      ? "**"
                      : eventsData?.eventParts?.length === 0
                      ? "No date"
                      : ongoing
                      ? "Ongoing"
                      : daysToStartDate > 0
                      ? `in ${daysToStartDate} days`
                      : "Ended"}
                  </p>
                </div>
              </div>
            </div>

            {links
              .filter((item) =>
                // if planner hide wallet
                isAdmin ? item : !["Wallet"].includes(item.name)
              )
              .map((link) => (
                <Link
                  to={`/${link.url}`}
                  key={uuidv4()}
                  onClick={() => setInitialLoad(false)}
                >
                  <li>
                    {link.Icon}
                    <span
                      className={
                        setActiveLink(link.url) ? styles["active"] : ""
                      }
                    >
                      {link.name}
                    </span>
                  </li>
                </Link>
              ))}
            {/* Rendering only in development */}
            {/* {process.env.REACT_APP_INAWO_ENV === "dev" && ( */}
            {/* <>
              <Link
                to={"/event-details?tab=preferences"}
                className={
                  styles["dashboardLayout__sidebar--links__preferences__title"]
                }
              >
                Preferences
              </Link>
              {preferences
                .filter((item) =>
                  // if live hall isn't turned on don't show it.
                  eventPreferences?.live_hall
                    ? item
                    : !["Live Hall"].includes(item.name)
                )
                .map((link) => (
                  <Link to={`/${link.url}`} key={uuidv4()}>
                    <li>
                      {link.Icon}
                      <span
                        className={
                          setActiveLink(link.url) ? styles["active"] : ""
                        }
                      >
                        {link.name}
                      </span>
                    </li>
                  </Link>
                ))}
            </> */}
            {/* )} */}
          </ul>
        </div>

        <main className={styles["dashboardLayout__main"]}>
          <header className={styles["dashboardLayout__main--header"]}>
            <div
              className={`${
                styles["dashboardLayout__main--header-hamburger"]
              } ${
                // !setActiveLink("my-plan") &&
                styles["dashboardLayout__main--header-hamburger__other"]
              }`}
              onClick={() => setToggle(!toggle)}
            >
              <div></div>
            </div>

            {pageName && <h3>{pageName}</h3>}

            <div className={styles["dashboardLayout__main--header-wrapper"]}>
              <Popover.Root>
                <Popover.Trigger className={styles["dashboardLayout__popover"]}>
                  {!loadingTeamData ? (
                    <Tooltip.Provider delayDuration={200}>
                      <Tooltip.Root>
                        <Tooltip.Trigger
                          className={styles["dashboardLayout__popover-tooltip"]}
                        >
                          <div
                            className={`${
                              styles["dashboardLayout__main__bubble"]
                            } ${
                              !setActiveLink("my-plan") &&
                              styles["dashboardLayout__main__bubble__other"]
                            }`}
                          >
                            <div>
                              <Avatar.Root
                                className={
                                  styles[
                                    "dashboardLayout__main__bubble__container"
                                  ]
                                }
                              >
                                {!!teamData?.getTeamMember[0].user?.image ? (
                                  <>
                                    <Avatar.Image
                                      src={
                                        teamData?.getTeamMember[0].user
                                          ?.image ?? ""
                                      }
                                      alt={`${teamData?.getTeamMember[0].user?.first_name}'s avatar`}
                                      className={
                                        styles[
                                          "dashboardLayout__main__bubble__img"
                                        ]
                                      }
                                    />
                                    <Avatar.Fallback
                                      delayMs={0}
                                      className={`${
                                        styles[
                                          "dashboardLayout__main__bubble-avatar"
                                        ]
                                      } ${
                                        !setActiveLink("my-plan") &&
                                        styles[
                                          "dashboardLayout__main__bubble__other-avatar"
                                        ]
                                      }`}
                                    >
                                      {`${
                                        teamData?.getTeamMember[0].user
                                          ?.first_name[0]
                                      }${
                                        !!teamData?.getTeamMember[0].user
                                          ?.last_name
                                          ? teamData?.getTeamMember[0].user
                                              ?.last_name[0]
                                          : ""
                                      }`}
                                    </Avatar.Fallback>
                                  </>
                                ) : (
                                  <span
                                    className={`${
                                      styles[
                                        "dashboardLayout__main__bubble-avatar"
                                      ]
                                    } ${
                                      !setActiveLink("my-plan") &&
                                      styles[
                                        "dashboardLayout__main__bubble__other-avatar"
                                      ]
                                    }`}
                                  >
                                    {`${
                                      teamData?.getTeamMember[0].user
                                        ?.first_name[0]
                                    }${
                                      !!teamData?.getTeamMember[0].user
                                        ?.last_name
                                        ? teamData?.getTeamMember[0].user
                                            ?.last_name[0]
                                        : ""
                                    }`}
                                  </span>
                                )}
                              </Avatar.Root>
                            </div>
                            + {teamData?.getTeamMember.length}
                          </div>
                        </Tooltip.Trigger>

                        <Tooltip.Content
                          className={
                            styles["dashboardLayout__main__bubble-content"]
                          }
                        >
                          See all the people involved in this wedding.
                          <Tooltip.Arrow
                            className={
                              styles["dashboardLayout__main__bubble-fill"]
                            }
                          />
                        </Tooltip.Content>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  ) : (
                    <></>
                  )}
                </Popover.Trigger>

                <Popover.Content
                  className={styles["dashboardLayout__main__team-content"]}
                >
                  <h4>Team Members</h4>

                  <div
                    className={
                      styles["dashboardLayout__main__team-content__body"]
                    }
                  >
                    {!loadingTeamData ? (
                      teamData?.getTeamMember.map((member) => (
                        <div
                          key={uuidv4()}
                          className={
                            styles[
                              "dashboardLayout__main__team-content__body-group"
                            ]
                          }
                        >
                          <div
                            className={
                              styles[
                                "dashboardLayout__main__team-content__body-group__details"
                              ]
                            }
                          >
                            <h5>{`${member.user?.first_name} ${member.user?.last_name}`}</h5>
                            <p>{member.role} </p>
                          </div>
                          {member.user_id !== member.user?.id && (
                            <Dialog
                              trigger={
                                <div
                                  className={
                                    styles[
                                      "dashboardLayout__main__team-content__body-group__btn"
                                    ]
                                  }
                                >
                                  Remove
                                </div>
                              }
                            >
                              <DeleteModal
                                title="Remove Team Member?"
                                handleGoBack={() => null}
                                handleDelete={() =>
                                  !!member.user?.id
                                    ? handleRemoveTeamMember(member.user?.id)
                                    : null
                                }
                                loading={loadingRemoveTeamMember}
                                message="Are you sure you want to remove Kemi Adetiba from your wedding?"
                              />
                            </Dialog>
                          )}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>

                  <Dialog
                    open={addTeam}
                    onOpenChange={setAddTeam}
                    trigger={
                      <div
                        className={
                          styles[
                            "dashboardLayout__main__team-content__body-group__btn2"
                          ]
                        }
                      >
                        <span>+ Add New Member</span>
                      </div>
                    }
                  >
                    <AddTeamMemberModal
                      id={event.id}
                      handleGoBack={() => setAddTeam(false)}
                    />
                  </Dialog>
                </Popover.Content>
              </Popover.Root>

              {!loadingDashboardEventsData &&
                !!dashboardEventsData &&
                !!event.slug && (
                  <Tooltip.Provider delayDuration={200}>
                    <Tooltip.Root>
                      <Tooltip.Trigger
                        className={styles["dashboardLayout__popover-tooltip"]}
                      >
                        <a
                          target="_blank"
                          href={`https://${event.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}?jelfo=og5xovf7i8f6ic678h_hk_8nl&evd=${refreshToken}&ppptf=ew2n498hdae3dd2h3a0gh24`}
                          rel="noreferrer"
                          className={styles["dashboardLayout__preview"]}
                        >
                          <Icon iconName="previewEye" />
                        </a>
                      </Tooltip.Trigger>

                      <Tooltip.Content
                        className={
                          styles["dashboardLayout__main__bubble-content"]
                        }
                      >
                        Preview your wedding website.
                        <Tooltip.Arrow
                          className={
                            styles["dashboardLayout__main__bubble-fill"]
                          }
                        />
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                )}

              <Popover.Root>
                <Popover.Trigger className={styles["dashboardLayout__popover"]}>
                  <div
                    className={`${styles["dashboardLayout__main__event-title"]}
										${
                      // !setActiveLink("my-plan") &&
                      styles["dashboardLayout__main__event-title__other"]
                    }`}
                  >
                    <p>
                      {loadingDashboardEventsData && !!dashboardEventsData ? (
                        <>&nbsp;</>
                      ) : eventName ? (
                        `${eventName}`
                      ) : (
                        "..."
                      )}
                    </p>
                    <Icon iconName="caretDouble" />
                  </div>
                </Popover.Trigger>

                <Popover.Content
                  className={styles["dashboardLayout__main__event-list"]}
                >
                  {!loadingDashboardEventsData && !!dashboardEventsData && (
                    <div className={styles["dropdown"]}>
                      <div className={styles["dropdown-item"]}>
                        <ul className={styles["dropdown-item--content"]}>
                          <li className={styles["dropdown-item--list--first"]}>
                            <p>{eventName ? `${eventName}` : "..."}</p>
                          </li>
                          {!!dashboardEventsData.events &&
                            dashboardEventsData.events
                              .filter((item) => item!.name !== eventName)
                              .map((data, _i) => (
                                <li
                                  key={uuidv4()}
                                  className={styles["dropdown-item--list"]}
                                  onClick={() =>
                                    handleLink(
                                      data!.id,
                                      data!.uuid,
                                      data?.event_wallet?.find(
                                        (item) =>
                                          !!item && item.currency === "ngn"
                                      )?.id
                                    )
                                  }
                                >
                                  {data!.name}
                                </li>
                              ))}
                          <li
                            onClick={routeToLobby}
                            className={styles["dropdown-item--list--last"]}
                          >
                            <Button
                              label="back to lobby"
                              type="button"
                              handleClick={routeToLobby}
                            >
                              <Icon iconName="redArrowLeft" />
                              Back To Lobby
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </Popover.Content>
              </Popover.Root>
            </div>
          </header>
          {/* <section className={styles[`dashboardLayout__main--body`]}> */}
          {children}
          {/* </section> */}
        </main>
      </div>
      <Popover.Root open={floatButton} onOpenChange={setFloatButton}>
        <Popover.Trigger
          className={styles["dashboardLayout__float"]}
          onClick={() => setFloatButton(true)}
        >
          {floatButton ? (
            <Icon iconName="close3" />
          ) : (
            <Icon iconName="caretDouble" />
          )}
        </Popover.Trigger>

        <Popover.Content
          side="top"
          className={`${styles["dashboardLayout__main__event-list"]} ${styles["dashboardLayout__main__fixed-list"]}`}
        >
          {!loadingDashboardEventsData && !!dashboardEventsData && (
            <div
              className={styles["dropdown"]}
              style={dropdown && width < 670 ? changeOpacity : {}}
            >
              <div className={styles["dropdown-item"]}>
                <ul className={styles["dropdown-item--content"]}>
                  <li className={styles["dropdown-item--list--first"]}>
                    <p>{eventName ? `${eventName}` : "..."}</p>
                  </li>
                  {!!dashboardEventsData.events &&
                    dashboardEventsData.events
                      .filter((item) => item!.name !== eventName)
                      .map((data, _i) => (
                        <li
                          key={uuidv4()}
                          className={styles["dropdown-item--list"]}
                          onClick={() =>
                            handleLink(
                              data!.id,
                              data!.uuid,
                              data?.event_wallet?.find(
                                (item) => !!item && item.currency === "ngn"
                              )?.id
                            )
                          }
                        >
                          {data!.name}
                        </li>
                      ))}
                  <li
                    onClick={routeToLobby}
                    className={styles["dropdown-item--list--last"]}
                  >
                    <Button
                      label="back to lobby"
                      type="button"
                      handleClick={routeToLobby}
                    >
                      <Icon iconName="redArrowLeft" />
                      Back To Lobby
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Popover.Content>
      </Popover.Root>
    </PageLoader>
  );
};

export default withAuth(DashboardLayout);
