const NoRSVPComing = () => {
  return (
    <svg
      width="129"
      height="129"
      viewBox="0 0 129 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.008 110.276H21.4106C15.5258 110.276 10.7109 105.461 10.7109 99.576V43.6705C10.7109 40.1931 12.3159 36.9832 15.2583 34.8433L64.2091 0.604492L113.16 34.8433C116.102 36.7157 117.707 40.1931 117.707 43.6705V99.576C117.707 105.461 112.892 110.276 107.008 110.276Z"
        fill="url(#paint0_linear_713_11480)"
      />
      <path
        d="M32 38.3134C32 33.722 35.722 30 40.3134 30H87.6866C92.278 30 96 33.722 96 38.3134V83.6866C96 88.278 92.278 92 87.6866 92H40.3134C35.722 92 32 88.278 32 83.6866V38.3134Z"
        fill="url(#paint1_linear_713_11480)"
      />
      <path
        d="M53.4593 43.8812C52.9878 43.4096 52.9878 42.6451 53.4593 42.1735L53.7439 41.8889C54.2155 41.4173 54.9801 41.4173 55.4516 41.8889L75.3748 61.812C75.8464 62.2836 75.8464 63.0482 75.3748 63.5197L75.0902 63.8044C74.6186 64.2759 73.8541 64.2759 73.3825 63.8044L53.4593 43.8812Z"
        fill="url(#paint2_linear_713_11480)"
      />
      <path
        d="M53.4593 43.8812C52.9878 43.4096 52.9878 42.6451 53.4593 42.1735L53.7439 41.8889C54.2155 41.4173 54.9801 41.4173 55.4516 41.8889L75.3748 61.812C75.8464 62.2836 75.8464 63.0482 75.3748 63.5197L75.0902 63.8044C74.6186 64.2759 73.8541 64.2759 73.3825 63.8044L53.4593 43.8812Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M53.4593 63.7162C52.9878 63.2446 52.9878 62.48 53.4593 62.0085L73.3825 42.0853C73.8541 41.6137 74.6186 41.6137 75.0902 42.0853L75.3748 42.3699C75.8464 42.8415 75.8464 43.606 75.3748 44.0776L55.4516 64.0008C54.9801 64.4723 54.2155 64.4723 53.7439 64.0008L53.4593 63.7162Z"
        fill="url(#paint3_linear_713_11480)"
      />
      <path
        d="M53.4593 63.7162C52.9878 63.2446 52.9878 62.48 53.4593 62.0085L73.3825 42.0853C73.8541 41.6137 74.6186 41.6137 75.0902 42.0853L75.3748 42.3699C75.8464 42.8415 75.8464 43.606 75.3748 44.0776L55.4516 64.0008C54.9801 64.4723 54.2155 64.4723 53.7439 64.0008L53.4593 63.7162Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M107.008 110.275H21.4106C15.5258 110.275 10.7109 105.46 10.7109 99.5753V46.0771L64.2091 80.8509L117.707 46.0771V99.5753C117.707 105.46 112.892 110.275 107.008 110.275Z"
        fill="url(#paint4_linear_713_11480)"
      />
      <path
        d="M107.008 110.275H21.4106C15.5258 110.275 10.7109 105.46 10.7109 99.5753V46.0771L64.2091 80.8509L117.707 46.0771V99.5753C117.707 105.46 112.892 110.275 107.008 110.275Z"
        fill="black"
        fillOpacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_713_11480"
          x1="32.694"
          y1="0.604492"
          x2="133.076"
          y2="110.324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#446762" stopOpacity="0.29" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_713_11480"
          x1="64"
          y1="30"
          x2="64"
          y2="92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0D8D4" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_713_11480"
          x1="53.0148"
          y1="42.618"
          x2="57.4927"
          y2="38.9778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_713_11480"
          x1="57.0497"
          y1="58.4181"
          x2="60.9857"
          y2="61.7561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_713_11480"
          x1="32.694"
          y1="46.0771"
          x2="81.7974"
          y2="137.765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoRSVPComing;
