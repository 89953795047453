interface IconProps {
  fill: string;
}
const Photobook = ({ fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 46 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03058 0.757446C3.64153 0.757446 0.0834961 4.31547 0.0834961 8.70453V25.4817C0.0834961 29.8708 3.64152 33.4288 8.03058 33.4288H10.6227C10.0939 32.3648 9.7966 31.1655 9.7966 29.8968V13.1196C9.7966 8.73052 13.3546 5.17249 17.7437 5.17249H31.9288C30.6284 2.55575 27.9281 0.757446 24.8078 0.757446H8.03058Z"
        fill={fill}
      />
      <path
        d="M13.3286 15.7686C13.3286 11.3796 16.8866 7.82153 21.2757 7.82153H38.0529C42.4419 7.82153 46 11.3796 46 15.7686V32.5458C46 36.9349 42.4419 40.4929 38.0529 40.4929H21.2757C16.8866 40.4929 13.3286 36.9349 13.3286 32.5458V15.7686Z"
        fill={fill}
      />
    </svg>
  );
};
export { Photobook };
