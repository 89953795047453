import { LazyLoadImage } from "react-lazy-load-image-component";
import useMediaQuery from "src/hooks/useMediaQuery";
import blurDataUrl from "src/utils/generateBlurPlaceholder";

const StaticImage = ({
  src,
  className,
  imageClassName,
  alt,
  scrollPosition,
}: {
  src: string;
  className?: string;
  imageClassName?: string;
  alt?: string;
  scrollPosition?: { x: number; y: number };
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <LazyLoadImage
      src={src}
      alt={alt ?? "image"}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = isMobile
          ? "/unavailable.png"
          : "/unavailable-square.png";
      }}
      wrapperClassName={className}
      className={imageClassName}
      visibleByDefault
      placeholderSrc={blurDataUrl()}
      scrollPosition={scrollPosition}
    />
  );
};

export default StaticImage;
