import { gql } from "@apollo/client";

export const InvitationListByCategory = gql`
  query invitationListByCategory(
    $eventId: Int!
    $categoryId: Int
    $page: Int
    $pageSize: Int
  ) {
    invitationListByCategory(
      event_id: $eventId
      categoryId: $categoryId
      page: $page
      pageSize: $pageSize
    ) {
      id
      first_name
      last_name
      category_id
    }
  }
`;

export const GetGeneralInviteToken = gql`
  query getGeneralInviteToken($eventId: Int!) {
    getGeneralInviteToken(event_id: $eventId)
  }
`;
