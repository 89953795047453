import { motion } from "framer-motion";
import Icon from "../Icon";

const containerVariants = {
  loading: {
    scale: [0.5, 1.3, 0.5],
    transition: {
      duration: 1.8,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
  complete: {
    scale: 1,
    transition: {
      duration: 0,
    },
  },
};

const PageLoader = ({
  loading = true,
  children,
}: {
  loading?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <motion.div
      variants={containerVariants}
      animate={loading ? "loading" : "complete"}
      className="overflow-hidden fixed inset-0 h-screen w-screen"
    >
      {loading ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div>
            <Icon iconName="smallLogo" />
          </div>
          <div>
            <Icon iconName="fullLogoWhite" />
          </div>
        </div>
      ) : (
        children
      )}
    </motion.div>
  );
};

export default PageLoader;
