import React from "react";

const Close3 = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="17.4109" height="17.4109" rx="8.70547" fill="#2D2D2D" />
      <path
        d="M5.65857 6.09204L11.3171 11.7506"
        stroke="white"
        strokeWidth="0.870548"
        strokeLinecap="round"
      />
      <path
        d="M11.3171 6.09204L5.65858 11.7506"
        stroke="white"
        strokeWidth="0.870548"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Close3;
