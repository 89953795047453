import { useState } from "react";
import Icon from "src/components/Icon";
import { useQuery } from "@apollo/client";
import * as Tooltip from "@radix-ui/react-tooltip";
import { InvitationCardTemplate } from "src/graphql/queries";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ShareInviteCard from "../invitation-card/ShareInviteCard";
import DialogModal from "src/components/Dialog";
import inviteDefaultImage from "src/assets/images/generalDefaultImage.png";
import AddGuestsSpreadsheet from "./AddGuestsSpreadsheet";
import useAppStore from "src/store/utils";

function AddGuest() {
  const [showSpreadsheet, setShowSpreadsheet] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState(false);
  const [openShareWebsite, setOpenShareWebsite] = useState(false);
  const [weddingWebsiteLinkInfo, setWeddingWebsiteLinkInfo] = useState(false);
  const [isPhotobookShareOpen, setIsPhotobookShareOpen] = useState(false);

  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { data: invitationCard } = useQuery(InvitationCardTemplate, {
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <>
      <div>
        {showSpreadsheet ? (
          <AddGuestsSpreadsheet setOpen={setShowSpreadsheet} />
        ) : (
          <div className="w-full h-full relative flex flex-col px-[1.2rem] md:px-0 py-[2.9rem] gap-[3.8rem]">
            <div className="flex flex-col gap-[1.5rem]">
              <h4 className="text-primary-1 text-[16.37px] leading-[24px] font-[600] -tracking-[0.02em]">
                Invite your guests
              </h4>
              <div className="w-full xl:w-max grid grid-cols-1 lg:grid-cols-2 gap-[2.5rem]">
                <div
                  onClick={() => setShowSpreadsheet(true)}
                  className="flex border border-secondary-2 rounded-[8px] w-full lg:max-w-[411px] h-max sm:h-[7.7rem] cursor-pointer hover:bg-primary-2/20  duration-300 ease-in-out transition-colors"
                >
                  <div className="grid place-items-center border-r border-r-secondary-2 min-w-[7.7rem]">
                    <Icon iconName="inviteAddManually" />
                  </div>
                  <div className="flex-grow flex flex-col justify-center px-4 py-4">
                    <h5 className="text-dark-1 font-[600] -tracking-[0.02em]">
                      Add Guests Manually
                    </h5>
                    <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                      Add all your guests manually by inputing their details
                      individually
                    </p>
                  </div>
                </div>

                <div className="flex border border-secondary-2 rounded-[8px] w-full lg:max-w-[411px] h-max sm:h-[7.7rem] cursor-pointer hover:bg-primary-2/20  duration-300 ease-in-out transition-colors">
                  <DialogModal
                    trigger={
                      <div className="flex w-full h-full ">
                        <div className="grid place-items-center border-r border-r-secondary-2 min-w-[7.7rem]">
                          <Icon iconName="inviteGeneralInvite" />
                        </div>
                        <div className="flex-grow flex flex-col justify-center px-4 py-4 text-left">
                          <h5 className="text-dark-1 font-[600] -tracking-[0.02em]">
                            General Invite Link
                          </h5>
                          <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                            Share a general link to your guest so they would be
                            able to add themselves to your list
                          </p>
                        </div>
                      </div>
                    }
                    open={openShare}
                    onOpenChange={setOpenShare}
                  >
                    <ShareInviteCard
                      setToggleModal={setOpenShare}
                      image={
                        invitationCard?.invitationCard?.template?.length
                          ? invitationCard?.invitationCard?.template
                          : inviteDefaultImage
                      }
                    />
                  </DialogModal>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[1.5rem]">
              <h4 className="text-primary-1 text-[16.37px] leading-[24px] font-[600] -tracking-[0.02em] ">
                Invite others
              </h4>
              <div className="w-full xl:w-max grid grid-cols-1 lg:grid-cols-2 gap-[2.5rem]">
                <div className="relative flex border border-secondary-2 rounded-[8px] w-full lg:max-w-[411px] h-max sm:h-[7.7rem] cursor-pointer hover:bg-primary-2/20  duration-300 ease-in-out transition-colors">
                  <DialogModal
                    trigger={
                      <div className="flex w-full h-full ">
                        <Tooltip.Provider delayDuration={200}>
                          <Tooltip.Root open={weddingWebsiteLinkInfo}>
                            <Tooltip.Trigger
                              onMouseEnter={() => {
                                setWeddingWebsiteLinkInfo(true);
                              }}
                              onMouseLeave={() => {
                                setWeddingWebsiteLinkInfo(false);
                              }}
                              onFocus={() => {
                                setWeddingWebsiteLinkInfo(true);
                              }}
                              onBlur={() => {
                                setWeddingWebsiteLinkInfo(false);
                              }}
                              asChild
                            >
                              <div
                                onClick={(e) => e?.stopPropagation()}
                                className="absolute w-[17px] h-[17px] bg-primary-3 text-[10.57px] font-[800] leading-[8px] rounded-[50px] grid place-items-center right-4 top-2"
                              >
                                i
                              </div>
                            </Tooltip.Trigger>
                            <Tooltip.Content
                              side="right"
                              className="bg-primary-4 py-[1.125rem] px-[1.375rem] flex flex-col gap-[1.625rem] rounded-[6px] border border-dashed border-primary-5"
                            >
                              <div className="flex flex-col gap-1">
                                <h6 className="text-dark-1 font-[600] text-[13px] leading-[19px] -tracking-[0.02em]">
                                  Restrictions
                                </h6>
                                {[
                                  "Cannot leave messages on your website",
                                  "Cannot see venue",
                                  "Cannot rsvp",
                                ]?.map((i, idx) => (
                                  <p
                                    key={idx}
                                    className="text-secondary-3 text-[14px] leading-[21px] -tracking-[0.02em]"
                                  >
                                    {i}
                                  </p>
                                ))}
                              </div>
                              <div className="flex flex-col gap-1">
                                <h6 className="text-dark-1 font-[600] text-[13px] leading-[19px] -tracking-[0.02em]">
                                  Everything else on the website is accessible
                                </h6>
                                {[
                                  "Wishlist",
                                  "Cash gift",
                                  "Photobook",
                                  "Vendors list",
                                  "Others",
                                ]?.map((i, idx) => (
                                  <p
                                    key={idx}
                                    className="text-secondary-3 text-[14px] leading-[21px] -tracking-[0.02em]"
                                  >
                                    {i}
                                  </p>
                                ))}
                              </div>
                              <Tooltip.Arrow className={`fill-primary-5`} />
                            </Tooltip.Content>
                          </Tooltip.Root>
                        </Tooltip.Provider>
                        <div className="grid place-items-center border-r border-r-secondary-2 min-w-[7.7rem]">
                          <Icon iconName="inviteWebsiteLink" />
                        </div>
                        <div className="flex-grow flex flex-col justify-center px-4 py-4 text-left">
                          <h5 className="text-dark-1 font-[600] -tracking-[0.02em]">
                            Wedding Website Link
                          </h5>
                          <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                            A link for non-guests to engage with your website.
                            It comes with some restrictions
                          </p>
                        </div>
                      </div>
                    }
                    open={openShareWebsite}
                    onOpenChange={setOpenShareWebsite}
                  >
                    <ShareInviteCard
                      customLink={`https://${event?.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}`}
                      setToggleModal={setOpenShareWebsite}
                      customTitle={`Wedding Website`}
                      preview={true}
                      customDescription={`Share your wedding website with everyone`}
                    />
                  </DialogModal>
                </div>
                <div className="  border border-secondary-2 rounded-[8px] w-full lg:max-w-[411px] h-max sm:h-[7.7rem] cursor-not-allowed hover:bg-primary-2/20  duration-300 ease-in-out transition-colors">
                  <Tooltip.Provider delayDuration={200}>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div className="w-full h-full flex">
                          <div className="grid place-items-center border-r border-r-secondary-2 min-w-[7.7rem]">
                            <Icon iconName="inviteGiftsLink" />
                          </div>
                          <div className="flex-grow flex flex-col justify-center px-4 py-4">
                            <h5 className="text-dark-1 font-[600] -tracking-[0.02em]">
                              Gifts Link
                            </h5>
                            <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                              Share a link to your wedding wishlist and cash
                              gifts
                            </p>
                          </div>
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        side="top"
                        className="bg-primary-4 py-[1.125rem] px-[1.375rem] flex flex-col gap-[1.625rem] rounded-[6px] border border-dashed border-primary-5"
                      >
                        <div className="flex flex-col gap-1">
                          <h6 className="text-dark-1 font-[600] text-[13px] leading-[19px] -tracking-[0.02em]">
                            Coming soon
                          </h6>
                          <p className="text-secondary-3 text-[14px] leading-[21px] -tracking-[0.02em]">
                            Currently, you can receive <br /> gifts by sending
                            out your <br />
                            general invite and wedding <br /> website links
                          </p>
                        </div>
                        <Tooltip.Arrow className={`fill-primary-5`} />
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
                <div className="flex border border-secondary-2 rounded-[8px] w-full lg:max-w-[411px] h-max sm:h-[7.7rem] cursor-not-allowed hover:bg-primary-2/20  duration-300 ease-in-out transition-colors">
                  <DialogModal
                    trigger={
                      <div className="w-full h-full flex">
                        <div className="grid place-items-center border-r border-r-secondary-2 min-w-[7.7rem]">
                          <Icon iconName="photobookLink" />
                        </div>
                        <div className="flex-grow flex flex-col justify-center px-4 py-4 text-left">
                          <h5 className="text-dark-1 font-[600] -tracking-[0.02em]">
                            Photobook Link
                          </h5>
                          <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                            Share link to your beautiful photobook
                          </p>
                        </div>
                      </div>
                    }
                    open={isPhotobookShareOpen}
                    onOpenChange={setIsPhotobookShareOpen}
                  >
                    <ShareInviteCard
                      customLink={`https://${event?.slug}.${process.env.REACT_APP_INAWO_ALBUM_SITE_END}`}
                      setToggleModal={setIsPhotobookShareOpen}
                      customTitle={`Photobook`}
                      customDescription={`Share a link to your wedding photos and memories`}
                      preview={true}
                    />
                  </DialogModal>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddGuest;
