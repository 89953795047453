import { ReactComponent as ArrowLeft } from "../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/arrowRight.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as EyeClose } from "../assets/icons/eyeClose.svg";
import { ReactComponent as Toggle } from "../assets/icons/toggle.svg";
import { ReactComponent as Close2 } from "../assets/icons/close2.svg";
import { ReactComponent as Close3 } from "../assets/icons/close3.svg";
import { ReactComponent as Hamburger } from "../assets/icons/hamburger.svg";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";
import { ReactComponent as ArrowRightBlack } from "../assets/icons/arrowRightBlack.svg";
import { ReactComponent as CaretDown } from "../assets/icons/caretDown.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import { ReactComponent as CaretDouble } from "../assets/icons/caretDouble.svg";
import { ReactComponent as CaretDoubleBlack } from "../assets/icons/caretDoubleBlack.svg";
import { ReactComponent as Kebab } from "../assets/icons/kebab.svg";
import { ReactComponent as Link } from "../assets/icons/link.svg";
import { ReactComponent as LinkOut } from "../assets/icons/linkOut.svg";
import { ReactComponent as RedArrowLeft } from "../assets/icons/redArrowLeft.svg";
import { ReactComponent as Tick } from "../assets/icons/tick.svg";
import { ReactComponent as ArrowLeftBlack } from "../assets/icons/arrowLeftBlack.svg";
import { ReactComponent as Polygon } from "../assets/icons/Polygon.svg";
import { ReactComponent as Add } from "../assets/icons/add.svg";
import { ReactComponent as Dashboard } from "../assets/icons/dashboard.svg";
import { ReactComponent as EventDetails } from "../assets/icons/eventDetails.svg";
import { ReactComponent as EventTeam } from "../assets/icons/eventTeam.svg";
import { ReactComponent as Gifts } from "../assets/icons/gifts.svg";
import { ReactComponent as InvitationCard } from "../assets/icons/invitationCard.svg";
import { ReactComponent as InviteList } from "../assets/icons/inviteList.svg";
import { ReactComponent as Messages } from "../assets/icons/messages.svg";
import { ReactComponent as Website } from "../assets/icons/website.svg";
import { ReactComponent as TemplateArrowRight } from "../assets/icons/templateArrowRight.svg";
import { ReactComponent as Landscape } from "../assets/icons/landscape.svg";
import { ReactComponent as Potrait } from "../assets/icons/potrait.svg";
import { ReactComponent as Warning } from "../assets/icons/warning.svg";
import { ReactComponent as CopyText } from "../assets/icons/copyText.svg";
import { ReactComponent as Bulb } from "../assets/icons/bulb.svg";
import { ReactComponent as Checked } from "../assets/icons/Checked.svg";
import { ReactComponent as RightArrow } from "../assets/icons/rightArrow.svg";
import { ReactComponent as EyeOpen } from "../assets/icons/eyeOpen.svg";
import { ReactComponent as Option } from "../assets/icons/Option.svg";
import { ReactComponent as SuccessCheck } from "../assets/icons/SuccessCheck.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/leftIcon.svg";
import { ReactComponent as InputIcon } from "../assets/icons/inputIcon.svg";
import { ReactComponent as Preview } from "../assets/icons/preview.svg";
import { ReactComponent as ShortRightArrow } from "../assets/icons/shortRightArrow.svg";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import { ReactComponent as Clock } from "../assets/icons/clock.svg";
import { ReactComponent as Location } from "../assets/icons/location.svg";
import { ReactComponent as Sad } from "../assets/icons/sad.svg";
import { ReactComponent as WalletEmpty } from "../assets/icons/walletEmpty.svg";
import { ReactComponent as Send } from "../assets/icons/send.svg";
import { ReactComponent as Withdraw } from "../assets/icons/withdraw.svg";
import { ReactComponent as Fund } from "../assets/icons/fund.svg";
import { ReactComponent as Unlink } from "../assets/icons/unlink.svg";
import { ReactComponent as NotFound } from "../assets/icons/notFound.svg";
import { ReactComponent as ComingSoon } from "../assets/icons/comingSoon.svg";
import { ReactComponent as Empty } from "../assets/icons/empty.svg";
import { ReactComponent as FirstAnkara } from "../assets/icons/ankara1.svg";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { ReactComponent as LogoWhite } from "../assets/icons/logowhite.svg";
import { ReactComponent as Tick2 } from "../assets/icons/tick2.svg";
import { ReactComponent as Previous } from "../assets/icons/previous.svg";
import { ReactComponent as Next } from "../assets/icons/next.svg";
import { ReactComponent as PreviewEye } from "../assets/icons/previewEye.svg";
import { ReactComponent as Parts } from "../assets/images/parts.svg";
import { ReactComponent as Vendors } from "../assets/images/vendor.svg";
import { ReactComponent as Event } from "../assets/images/event.svg";
import { ReactComponent as Gift } from "../assets/images/gift.svg";
import { ReactComponent as Gift2 } from "../assets/images/gift2.svg";
import { ReactComponent as Category } from "../assets/images/category.svg";
import { ReactComponent as Cards } from "../assets/images/cards.svg";
import { ReactComponent as Rsvp } from "../assets/images/rsvp.svg";
import { ReactComponent as Backstory } from "../assets/images/backstory.svg";
import { ReactComponent as Post } from "../assets/images/posts.svg";
import { ReactComponent as Photobook } from "../assets/images/photobook.svg";
import { ReactComponent as DeleteFilled } from "../assets/icons/deleteFilled.svg";
import { ReactComponent as Congrats } from "../assets/icons/congrats.svg";
import { ReactComponent as User } from "../assets/icons/users.svg";
import { ReactComponent as Filter } from "../assets/icons/filter.svg";
import { ReactComponent as Wallet } from "../assets/icons/wallet.svg";
import { ReactComponent as Help } from "../assets/icons/help.svg";
import { ReactComponent as BackArrow } from "../assets/icons/backArrow.svg";
import { ReactComponent as Guest } from "../assets/icons/guest.svg";
import { ReactComponent as TwoUsers } from "../assets/icons/2users.svg";
import { ReactComponent as Delete2 } from "../assets/icons/delete-2.svg";
import { ReactComponent as Rearrange } from "../assets/icons/rearrange.svg";
import { ReactComponent as Write } from "../assets/icons/write.svg";
import { ReactComponent as Link2 } from "../assets/icons/link-2.svg";
import { ReactComponent as Picture } from "../assets/icons/picture.svg";
import { ReactComponent as MenuVertical } from "../assets/icons/menu-vertical.svg";
import { ReactComponent as MenuHorizontal } from "../assets/icons/menu-horizontal.svg";
import { ReactComponent as DotHorizontal } from "../assets/icons/dotHorizontal.svg";
import { ReactComponent as DotsHorizontalWhite } from "../assets/icons/dotsHorizontalWhite.svg";
import { ReactComponent as Offline } from "../assets/icons/offline.svg";
import { ReactComponent as Online } from "../assets/icons/online.svg";
import { ReactComponent as DollarOptionIcon } from "../assets/icons/dollarOptionIcon.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/shareIcon.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/emailIcon.svg";
import { ReactComponent as EmailGrayIcon } from "../assets/icons/emailGray.svg";
import { ReactComponent as EmailOrangeIcon } from "../assets/icons/emailOrange.svg";
import { ReactComponent as EmailRedIcon } from "../assets/icons/emailRed.svg";
import { ReactComponent as EmailGreenIcon } from "../assets/icons/emailGreen.svg";
import { ReactComponent as WhatsappIcon } from "../assets/icons/whatsappIcon.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagramIcon.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitterIcon.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebookIcon.svg";
import { ReactComponent as ChainLinkIcon } from "../assets/icons/linkIcon.svg";
import { ReactComponent as AlertIconBlue } from "../assets/icons/alertIconBlue.svg";
import { ReactComponent as CashGiftIcon } from "../assets/icons/CashGiftIcon.svg";
import { ReactComponent as WishListIcon } from "../assets/icons/wishListIcon.svg";
import { ReactComponent as NairaGiftOption } from "../assets/icons/nairaOptionIcon.svg";
import { ReactComponent as DollarGiftOption } from "../assets/icons/dollarOptionIcon.svg";
import { ReactComponent as Paystack } from "../assets/icons/paystack.svg";
import { ReactComponent as InviteAddManually } from "../assets/icons/inviteAddManually.svg";
import { ReactComponent as InviteGeneralInvite } from "../assets/icons/inviteGeneralInvite.svg";
import { ReactComponent as InviteGiftsLink } from "../assets/icons/inviteGiftsLink.svg";
import { ReactComponent as InviteWebsiteLink } from "../assets/icons/inviteWebsiteLink.svg";
import { ReactComponent as UploadFile } from "../assets/icons/upload_file.svg";
import { ReactComponent as ArrowAngled } from "../assets/icons/arrowAngled.svg";
import { ReactComponent as SmArrowAngled } from "../assets/icons/smArrowAngled.svg";
import { ReactComponent as YoutubelLiveDemo } from "../assets/icons/youtubeLiveDemo.svg";
import { ReactComponent as EmptyCalender } from "../assets/icons/emptyCalender.svg";
import { ReactComponent as EmptyEvent } from "../assets/icons/emptyEvent.svg";
import { ReactComponent as CalenderIcon } from "../assets/icons/calenderIcon.svg";
import { ReactComponent as DotIcon } from "../assets/icons/dotIcon.svg";
import { ReactComponent as PencilIcon } from "../assets/icons/pencil.svg";
import { ReactComponent as Bin } from "../assets/icons/bin.svg";
import { ReactComponent as ShareV2 } from "../assets/icons/shareV2.svg";
import { ReactComponent as InstagramNaked } from "../assets/icons/instagramNaked.svg";
import { ReactComponent as TwitterNaked } from "../assets/icons/twitterNaked.svg";
import { ReactComponent as WhatsappNaked } from "../assets/icons/whatsappNaked.svg";
import { ReactComponent as FacebookNaked } from "../assets/icons/facebookNaked.svg";
import { ReactComponent as LiveScheduleShareBL } from "../assets/images/shareCardBottomLeft.svg";
import { ReactComponent as LiveScheduleShareTR } from "../assets/images/shareCardTopRight.svg";
import { ReactComponent as WhatsappBlackIcon } from "../assets/icons/whatsappBlack.svg";
import { ReactComponent as WhatsappOrangeIcon } from "../assets/icons/whatsappOrange.svg";
import { ReactComponent as WhatsappGrayIcon } from "../assets/icons/whatsappGray.svg";
import { ReactComponent as GreenDoubleTick } from "../assets/icons/greenDoubleTick.svg";
import { ReactComponent as OrangeDoubleTick } from "../assets/icons/orangeDoubleTick.svg";
import { ReactComponent as SingleGrayTick } from "../assets/icons/singleGrayTick.svg";
import { ReactComponent as WhatsappRedIcon } from "../assets/icons/whatsappRed.svg";
import { ReactComponent as WhatsappGreenIcon } from "../assets/icons/whatsappGreen.svg";
import { ReactComponent as RoundCautionIcon } from "../assets/icons/roundCaution.svg";
import { ReactComponent as CircleCheck } from "../assets/icons/circleCheck.svg";
import { ReactComponent as GreenYoutube } from "../assets/icons/greenYoutube.svg";
import { ReactComponent as UploadsComingSoon } from "../assets/icons/uploadsComingSoon.svg";
import { ReactComponent as ActivityComingSoon } from "../assets/icons/activityComingSoon.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as PhotoBookLinkIcon } from "../assets/images/photobookLink.svg";
import { ReactComponent as EyeOpenOutline } from "../assets/icons/eyeOpenOutline.svg";
import { ReactComponent as Capture } from "../assets/icons/capture.svg";
import { ReactComponent as ImageThumbnail } from "../assets/icons/imagethumbnail.svg";
import { ReactComponent as UploadPlaceholder } from "../assets/icons/uploadplaceholder.svg";
import { ReactComponent as ImagePlus } from "../assets/icons/imageplus.svg";
import { ReactComponent as SmallLogo } from "../assets/icons/logo-small-white.svg";
import { ReactComponent as FullLogoWhite } from "../assets/icons/logo-full-white.svg";

export type IconType =
  | "online"
  | "offline"
  | "picture"
  | "menuVertical"
  | "menuHorizontal"
  | "dotHorizontal"
  | "dotsHorizontalWhite"
  | "delete2"
  | "rearrange"
  | "write"
  | "link2"
  | "twoUsers"
  | "arrowLeft"
  | "arrowRight"
  | "arrowRightBlack"
  | "arrowLeftBlack"
  | "close"
  | "eyeClose"
  | "toggle"
  | "close2"
  | "previewEye"
  | "hamburger"
  | "copy"
  | "caretDown"
  | "search"
  | "kebab"
  | "link"
  | "tick"
  | "polygon"
  | "deleteFilled"
  | "dashboard"
  | "inviteList"
  | "eventDetails"
  | "congrats"
  | "invitationCard"
  | "gifts"
  | "eventTeam"
  | "messages"
  | "website"
  | "polygon"
  | "checked"
  | "templateArrowRight"
  | "potrait"
  | "landscape"
  | "bulb"
  | "warning"
  | "copyText"
  | "bulb"
  | "polygon"
  | "checked"
  | "rightArrow"
  | "eyeOpen"
  | "option"
  | "successCheck"
  | "leftArrow"
  | "inputIcon"
  | "preview"
  | "shortRightArrow"
  | "calendar"
  | "clock"
  | "location"
  | "sad"
  | "walletEmpty"
  | "send"
  | "withdraw"
  | "fund"
  | "unlink"
  | "notFound"
  | "comingSoon"
  | "empty"
  | "firstAnkara"
  | "logo"
  | "logowhite"
  | "caretDouble"
  | "caretDoubleBlack"
  | "linkOut"
  | "tick2"
  | "previous"
  | "next"
  | "redArrowLeft"
  | "parts"
  | "event"
  | "gift"
  | "gift2"
  | "close3"
  | "rsvp"
  | "cards"
  | "category"
  | "post"
  | "users"
  | "backstory"
  | "help"
  | "photobook"
  | "filter"
  | "wallet"
  | "add"
  | "backArrow"
  | "guest"
  | "vendor"
  | "dollarOptionIcon"
  | "share"
  | "chainLink"
  | "email"
  | "facebook"
  | "twitter"
  | "instagram"
  | "whatsapp"
  | "alertIconBlue"
  | "CashGiftIcon"
  | "wishListIcon"
  | "NairaGiftOption"
  | "DollarGiftOption"
  | "paystack"
  | "inviteAddManually"
  | "inviteGeneralInvite"
  | "inviteGiftsLink"
  | "inviteWebsiteLink"
  | "uploadFile"
  | "youtubeLiveDemo"
  | "arrow-angled"
  | "sm-arrow-angled"
  | "empty-calender"
  | "empty-event"
  | "calender-icon"
  | "dot-icon"
  | "pencil"
  | "shareV2"
  | "bin"
  | "twitterNaked"
  | "instagramNaked"
  | "whatsappNaked"
  | "whatsappBlack"
  | "facebookNaked"
  | "liveScheduleShareBgAssetTR"
  | "liveScheduleShareBgAssetBL"
  | "greenDoubleTick"
  | "orangeDoubleTick"
  | "singleGrayTick"
  | "whatsappOrange"
  | "whatsappGray"
  | "whatsappRed"
  | "whatsappGreen"
  | "roundCaution"
  | "circleCheck"
  | "emailGray"
  | "emailRed"
  | "emailOrange"
  | "emailGray"
  | "emailGreen"
  | "greenYoutube"
  | "uploadsComingSoon"
  | "activityComingSoon"
  | "plus"
  | "photobookLink"
  | "eyeOpenOutline"
  | "capture"
  | "thumbnail"
  | "uploadplaceholder"
  | "imageplus"
  | "smallLogo"
  | "fullLogoWhite";

export const icons: Record<IconType, JSX.Element> = {
  offline: <Offline />,
  online: <Online />,
  picture: <Picture />,
  menuVertical: <MenuVertical />,
  menuHorizontal: <MenuHorizontal />,
  dotHorizontal: <DotHorizontal />,
  dotsHorizontalWhite: <DotsHorizontalWhite />,
  delete2: <Delete2 />,
  link2: <Link2 />,
  write: <Write />,
  rearrange: <Rearrange />,
  twoUsers: <TwoUsers />,
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  arrowRightBlack: <ArrowRightBlack />,
  arrowLeftBlack: <ArrowLeftBlack />,
  close: <Close />,
  eyeClose: <EyeClose />,
  deleteFilled: <DeleteFilled />,
  toggle: <Toggle />,
  close2: <Close2 />,
  close3: <Close3 />,
  congrats: <Congrats />,
  hamburger: <Hamburger />,
  copy: <Copy />,
  caretDown: <CaretDown />,
  search: <Search />,
  kebab: <Kebab />,
  link: <Link />,
  linkOut: <LinkOut />,
  tick: <Tick />,
  add: <Add />,
  polygon: <Polygon />,
  dashboard: <Dashboard />,
  inviteList: <InviteList />,
  eventDetails: <EventDetails />,
  invitationCard: <InvitationCard />,
  gifts: <Gifts />,
  eventTeam: <EventTeam />,
  messages: <Messages />,
  website: <Website />,
  checked: <Checked />,
  templateArrowRight: <TemplateArrowRight />,
  potrait: <Potrait />,
  landscape: <Landscape />,
  bulb: <Bulb />,
  warning: <Warning />,
  copyText: <CopyText />,
  previewEye: <PreviewEye />,
  rightArrow: <RightArrow />,
  eyeOpen: <EyeOpen />,
  option: <Option />,
  tick2: <Tick2 />,
  successCheck: <SuccessCheck />,
  leftArrow: <LeftArrow />,
  inputIcon: <InputIcon />,
  preview: <Preview />,
  shortRightArrow: <ShortRightArrow />,
  calendar: <Calendar />,
  location: <Location />,
  clock: <Clock />,
  sad: <Sad />,
  walletEmpty: <WalletEmpty />,
  send: <Send />,
  withdraw: <Withdraw />,
  fund: <Fund />,
  wallet: <Wallet />,
  unlink: <Unlink />,
  notFound: <NotFound />,
  comingSoon: <ComingSoon />,
  empty: <Empty />,
  firstAnkara: <FirstAnkara />,
  logo: <Logo />,
  logowhite: <LogoWhite />,
  caretDouble: <CaretDouble />,
  caretDoubleBlack: <CaretDoubleBlack />,
  redArrowLeft: <RedArrowLeft />,
  previous: <Previous />,
  next: <Next />,
  parts: <Parts />,
  vendor: <Vendors />,
  event: <Event />,
  gift: <Gift />,
  gift2: <Gift2 />,
  category: <Category />,
  rsvp: <Rsvp />,
  cards: <Cards />,
  backstory: <Backstory />,
  post: <Post />,
  photobook: <Photobook />,
  help: <Help />,
  filter: <Filter />,
  users: <User />,
  backArrow: <BackArrow />,
  guest: <Guest />,
  dollarOptionIcon: <DollarOptionIcon />,
  share: <ShareIcon />,
  chainLink: <ChainLinkIcon />,
  email: <EmailIcon />,
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  twitter: <TwitterIcon />,
  whatsapp: <WhatsappIcon />,
  alertIconBlue: <AlertIconBlue />,
  CashGiftIcon: <CashGiftIcon />,
  wishListIcon: <WishListIcon />,
  DollarGiftOption: <DollarGiftOption />,
  NairaGiftOption: <NairaGiftOption />,
  paystack: <Paystack />,
  inviteAddManually: <InviteAddManually />,
  inviteGeneralInvite: <InviteGeneralInvite />,
  inviteGiftsLink: <InviteGiftsLink />,
  inviteWebsiteLink: <InviteWebsiteLink />,
  uploadFile: <UploadFile />,
  "arrow-angled": <ArrowAngled />,
  "sm-arrow-angled": <SmArrowAngled />,
  youtubeLiveDemo: <YoutubelLiveDemo />,
  "empty-calender": <EmptyCalender />,
  "empty-event": <EmptyEvent />,
  "calender-icon": <CalenderIcon />,
  "dot-icon": <DotIcon />,
  pencil: <PencilIcon />,
  bin: <Bin />,
  shareV2: <ShareV2 />,
  instagramNaked: <InstagramNaked />,
  twitterNaked: <TwitterNaked />,
  whatsappNaked: <WhatsappNaked />,
  whatsappBlack: <WhatsappBlackIcon />,
  whatsappOrange: <WhatsappOrangeIcon />,
  whatsappGray: <WhatsappGrayIcon />,
  whatsappRed: <WhatsappRedIcon />,
  facebookNaked: <FacebookNaked />,
  liveScheduleShareBgAssetBL: <LiveScheduleShareBL />,
  liveScheduleShareBgAssetTR: <LiveScheduleShareTR />,
  greenDoubleTick: <GreenDoubleTick />,
  orangeDoubleTick: <OrangeDoubleTick />,
  singleGrayTick: <SingleGrayTick />,
  whatsappGreen: <WhatsappGreenIcon />,
  roundCaution: <RoundCautionIcon />,
  circleCheck: <CircleCheck />,
  emailGray: <EmailGrayIcon />,
  emailGreen: <EmailGreenIcon />,
  emailOrange: <EmailOrangeIcon />,
  emailRed: <EmailRedIcon />,
  greenYoutube: <GreenYoutube />,
  uploadsComingSoon: <UploadsComingSoon />,
  activityComingSoon: <ActivityComingSoon />,
  plus: <Plus />,
  photobookLink: <PhotoBookLinkIcon />,
  eyeOpenOutline: <EyeOpenOutline />,
  capture: <Capture />,
  thumbnail: <ImageThumbnail />,
  uploadplaceholder: <UploadPlaceholder />,
  imageplus: <ImagePlus />,
  smallLogo: <SmallLogo />,
  fullLogoWhite: <FullLogoWhite />,
};
