import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "../Spinner";

interface IWebsitePreview {
  url: string;
}

const WebsitePreview = ({ url }: IWebsitePreview) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    if (iframeRef.current) {
      iframeRef.current.src = url;
    }
  }, [url]);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full grid place-items-center ">
          <Spinner />
        </div>
      ) : (
        <></>
      )}
      <div
        className={`h-full w-full flex justify-center z-[10] ${
          isLoading ? `opacity-0` : `opacity-100`
        }`}
      >
        <iframe
          className="w-full h-full"
          ref={iframeRef}
          title="Website Preview"
          src={url}
          onLoad={() => handleIframeLoad()}
        />
      </div>
    </>
  );
};

export default WebsitePreview;
