import React, {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { getAccessToken } from "src/helper/storage";
import useAppStore from "src/store/utils";
import { updateEventPreferenceVariables } from "src/types/api";

interface AppContextProps {
  children: JSX.Element;
}

export interface ValueProps {
  acceptTeamToken: string | null;
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  setWalletCode: Dispatch<SetStateAction<string | null | undefined>>;
  setAcceptTeamToken: Dispatch<SetStateAction<string | null>>;
  walletCode: string | null | undefined;
  phone: string | null | undefined;
  isAdmin: boolean;
  setIsAdmin: Dispatch<SetStateAction<boolean>>;
  setPhone: Dispatch<SetStateAction<string | null | undefined>>;
  eventPreferences: updateEventPreferenceVariables["input"];
  setEventPreferences: Dispatch<
    SetStateAction<updateEventPreferenceVariables["input"]>
  >;
  eventName: string;
  setEventName: Dispatch<SetStateAction<string>>;
}
export const AppContext = createContext<ValueProps | null>(null);

const AppContextProvider: React.FC<AppContextProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [acceptTeamToken, setAcceptTeamToken] = useState<string | null>(null);
  const [eventName, setEventName] = useState("");
  const [walletCode, setWalletCode] = useState<string | null | undefined>("");
  const [phone, setPhone] = useState<string | null | undefined>("");
  const { event } = useAppStore((state) => state);
  const [eventPreferences, setEventPreferences] = useState<
    updateEventPreferenceVariables["input"]
  >({
    event_id: event.id,
    live_hall: false,
    private: false,
    reservation: false,
    asoebi_selection: false,
    dress_code: false,
  });
  const getToken = () => {
    let data = getAccessToken();
    if (data) {
      setToken(data);
    }
  };

  console.log(eventPreferences);
  useEffect(() => {
    let wallet_code = localStorage.getItem("walletCode");
    if (wallet_code && wallet_code !== "undefined") {
      setWalletCode(JSON.parse(wallet_code));
    }
  }, []);

  useEffect(() => getToken, []);

  return (
    <AppContext.Provider
      value={{
        token,
        acceptTeamToken,
        setAcceptTeamToken,
        setToken,
        setWalletCode,
        walletCode,
        phone,
        setPhone,
        isAdmin,
        setIsAdmin,
        eventPreferences,
        setEventPreferences,
        eventName,
        setEventName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
