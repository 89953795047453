import * as Popover from "@radix-ui/react-popover";
import styles from "./styles.module.scss";

const DropDownMenu = ({
  trigger,
  children,
  opened,
  setOpened,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
  opened?: boolean;
  setOpened?: (isOpen: boolean) => void;
}) => {
  return (
    <div>
      <Popover.Root open={opened} onOpenChange={setOpened}>
        <Popover.Trigger asChild>
          <div className="cursor-pointer">
            {trigger}
            <span className="visually-hidden">open dropdown</span>
          </div>
        </Popover.Trigger>
        <Popover.Content
          className={`${styles["dropdown-card__dropdown"]} focus:outline-none`}
        >
          <div>{children}</div>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};

export default DropDownMenu;
