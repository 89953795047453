import PlayIcon from "src/assets/icons/play";
import Media from "src/components/Media";
import { GetAlbums } from "src/types/api";
import { SelectedAlbumType } from "src/utils/types";

const Album = ({
  element,
  setSelectedAlbum,
}: {
  element: GetAlbums["getAlbums"]["data"][0];
  setSelectedAlbum: (album: SelectedAlbumType) => void;
}) => {
  return (
    <div className="relative w-full !h-[260px] sm:w-[260px] sm:h-[260px]">
      {element?.Media?.data?.[0]?.type?.includes("video/") ? (
        <div className="relative w-full h-full sm:w-[260px] sm:h-[260px]">
          <video
            className="w-full h-full object-cover rounded-[5.48px]"
            controls={false}
          >
            <source
              src={element?.Media?.data?.[0]?.url ?? ""}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 flex items-center justify-center bg-black/30 rounded-[5.48px]">
            <PlayIcon />
          </div>
        </div>
      ) : (
        <img
          src={element?.Media?.data?.[0]?.url ?? ""}
          alt={`mage`}
          className="sm:w-[260px] h-[184px] object-cover rounded-[5.48px]"
        />
      )}
      <div className="absolute top-0 z-[50] w-fit flex justify-between items-center pl-[12.58px] pr-[15.14px] pt-[14.6px]">
        <p className="text-white font-semibold leading-[17.2px] text-sm hidden sm:block [text-shadow:_0_1px_0_rgb(0_0_0)]">
          {element?.name}
        </p>
      </div>
      <div className="absolute top-0 inset-0 bg-[#000]/50 opacity-0 hover:opacity-100 rounded-[5.48px] transition-opacity duration-300 flex flex-col items-center justify-center">
        <div
          className="border border-white rounded-[9.52px] py-2 sm:py-[12.92px] px-4 sm:px-[23.49px] text-[14.58px] !font-medium leading-[17.65px] text-white w-fit cursor-pointer"
          onClick={() => setSelectedAlbum(element)}
        >
          Open Album
        </div>
      </div>
      <div className="sm:w-[260px] h-full mt-[4px] grid grid-cols-3 gap-[4px]">
        {element?.Media?.data?.slice(1, 3)?.map((item) => (
          <Media
            media={{ type: item?.type ?? "", url: item?.url ?? "" }}
            className="w-full !h-[72px] sm:h-full rounded-[5.48px] "
            imageClassName="object-cover h-full"
          />
        ))}

        {element?.Media?.data?.length > 2 && (
          <div className="h-full sm:h-[72px] w-full relative">
            <Media
              media={{
                type: element?.Media?.data?.[3]?.type ?? "",
                url: element?.Media?.data?.[3]?.url ?? "",
              }}
              className="w-full !h-[72px] sm:h-full rounded-[5.48px] "
              imageClassName="object-cover h-full"
            />

            {element?.Media?.data?.length > 4 && (
              <p className="bg-[#000]/60 absolute rounded-[5.48px] top-0 w-full h-full flex justify-center items-center text-white">
                {element?.Media?.data?.length - 4}+
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Album;
