import { useState, SetStateAction, Dispatch, useMemo } from "react";
import styles from "../../photobook.module.scss";
import { GetAlbums, GetMedia } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
import toast from "react-hot-toast";
import {
  GetAlbums as getAlbums,
  GetMedia as getMedia,
  GetAlbums_getAlbums_data_Media_data,
  AddMediaToAlbum as AddMediaToAlbumType,
  AddMediaToAlbumVariables,
} from "src/types/api";
import DialogModal from "src/components/Dialog";
import EmptyState from "../../component/EmptyState";

import Icon from "src/components/Icon";
import { AddMediaToAlbum } from "src/graphql/mutations";
import Button from "src/components/Button";
import DeleteSelected from "../../component/DeleteSelected";
import DropDownMenu from "src/components/Dropdown";
import useAppStore from "src/store/utils";
import { photoBookTabTypes, SelectedAlbumType } from "src/utils/types";
import CustomSelect from "src/components/InputSelect";
import useCustomQuery from "src/hooks/useCustomQuery";
import useCustomMutation from "src/hooks/useCustomMutation";
import ImageList from "../../component/ImageList";
import AddPictures from "../../component/AddPictures";

type Props = {
  setSelectedAlbum: (album: SelectedAlbumType) => void;
  setTabState: Dispatch<SetStateAction<photoBookTabTypes>>;
};

const YourPhotos = ({ setSelectedAlbum, setTabState }: Props) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openAddToAlbum, setOpenAddToAlbum] = useState<boolean>(false);
  const [opened, setOpened] = useState(false);
  const [albumToAdd, setAlbumToAdd] = useState({ label: "", value: "" });
  const { event } = useAppStore((state) => state);
  const page = 1;
  const pageSize = 200;

  const { data: images, isLoading: getMediaLoading } = useCustomQuery<getMedia>(
    GetMedia,
    { eventId: event.uuid, filter: { no_album: true, page, pageSize } },
    !event.uuid
  );

  const { data: albums, isLoading: getAlbumsLoading } =
    useCustomQuery<getAlbums>(GetAlbums, { eventId: event.uuid }, !event.uuid);

  const groupedMedia = useMemo(() => {
    const media =
      albums?.getAlbums.data
        .filter((item) => item.Media.data.length > 0)
        .map((item) => ({
          ...item,
          isAlbum: true,
          created_at: item.Media?.data?.reduce(
            (latest: string, media: GetAlbums_getAlbums_data_Media_data) => {
              return !latest || new Date(media.created_at) > new Date(latest)
                ? media.created_at
                : latest;
            },
            ""
          ),
        })) ?? [];

    return [...(images?.getMedia ?? []), ...media].sort(
      (a, b) =>
        new Date(b!.created_at).getTime() - new Date(a!.created_at).getTime()
    );
  }, [images?.getMedia, albums?.getAlbums.data]);

  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);

  const handleChange = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const { mutate: addMedia, mutating: addMediaLoading } = useCustomMutation<
    AddMediaToAlbumType,
    AddMediaToAlbumVariables
  >(AddMediaToAlbum, {
    onSuccess: () => {
      toast.success(<p className="toast">Media added to Album successfully</p>);
      setOpenAddToAlbum(false);
    },
  });

  const handleAddToAlbum = async () => {
    await addMedia({
      variables: {
        eventId: event.uuid,
        albumId: albumToAdd.value,
        mediaIds: selected,
      },
      refetchQueries: [
        {
          query: GetMedia,
          variables: {
            eventId: event.uuid,
            filter: { no_album: true, page, pageSize },
          },
        },
        {
          query: GetMedia,
          variables: {
            eventId: event.uuid,
            filter: {
              album_id: albumToAdd.value,
              page,
              pageSize,
            },
          },
        },
        {
          query: GetAlbums,
          variables: { eventId: event.uuid },
        },
      ],
    });
  };

  return (
    <div>
      {getMediaLoading || getAlbumsLoading ? (
        <div className={styles["photobook__loading"]}>
          <InnerLoading />
        </div>
      ) : !!groupedMedia && groupedMedia.length > 0 ? (
        <>
          <div className={styles["photobook__trigger__group"]}>
            {selected?.length ? (
              <p className={styles["photobook__trigger__number"]}>
                Number of images: <span>{selected.length}</span>
              </p>
            ) : (
              !showSelected && (
                <div className="border border-secondary-6 sm:flex hidden text-[#787777] px-2.5 gap-[3px] rounded-md items-center justify-center">
                  <Icon iconName="thumbnail" />
                  <p>{groupedMedia?.length}</p>
                </div>
              )
            )}
            <div className={styles["photobook__trigger__right"]}>
              {showSelected ? (
                <>
                  <DialogModal
                    open={openAddToAlbum}
                    onOpenChange={setOpenAddToAlbum}
                    disabled={!selected.length}
                    trigger={
                      <div>
                        <Button
                          label="cancel"
                          type="button"
                          design="secondary"
                          style={{
                            borderRadius: "9.52px",
                            height: "37px",
                          }}
                          className="text-[14.58px]"
                        >
                          Add to Album
                        </Button>
                      </div>
                    }
                  >
                    <div className={styles["photobook__addPictures"]}>
                      <div className={"text-lg font-semibold mb-4"}>
                        <h4>Add Media to Album</h4>
                      </div>
                      <CustomSelect
                        placeholder="Select an album"
                        options={
                          albums?.getAlbums?.data.map((album) => ({
                            label: album.name,
                            value: album.id,
                          })) ?? []
                        }
                        value={{
                          label:
                            albumToAdd.label.charAt(0).toUpperCase() +
                            albumToAdd.label.slice(1),
                          value: albumToAdd.value,
                        }}
                        label={"Select an Album"}
                        onChange={(option) => {
                          setAlbumToAdd({
                            label: option?.label ?? "",
                            value: option?.value ?? "",
                          });
                        }}
                      />
                      <Button
                        label="delete"
                        type="button"
                        handleClick={handleAddToAlbum}
                        style={{
                          borderRadius: "9.52px",
                          marginTop: "24px",
                        }}
                        disabled={addMediaLoading}
                        loader={addMediaLoading}
                      >
                        Add to Album
                      </Button>
                    </div>
                  </DialogModal>

                  <div>
                    <DialogModal
                      open={openDelete}
                      onOpenChange={setOpenDelete}
                      disabled={!selected.length}
                      trigger={
                        <Button
                          label="delete"
                          type="button"
                          handleClick={() => {
                            setOpenDelete(true);
                          }}
                          design="secondary"
                          style={{
                            borderRadius: "9.52px",
                            height: "37px",
                            borderColor: "#DC2626",
                          }}
                          className="text-[14.58px] !text-[#DC2626]"
                        >
                          Delete{" "}
                          <span className="sm:inline-block hidden  !text-[#DC2626]">
                            Selected
                          </span>
                        </Button>
                      }
                    >
                      <DeleteSelected
                        setShowDelete={setShowSelected}
                        setOpen={setOpenDelete}
                        deleteList={selected}
                      />
                    </DialogModal>
                  </div>
                  <div>
                    <Button
                      label="cancel"
                      type="button"
                      handleClick={() => {
                        setShowSelected(false);
                        setSelected([]);
                      }}
                      design="secondary"
                      style={{
                        borderRadius: "9.52px",
                        height: "37px",
                      }}
                      className="text-[14.58px]"
                    >
                      Cancel{" "}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <DropDownMenu
                    opened={opened}
                    setOpened={setOpened}
                    trigger={
                      <div
                        className={styles["photobook__trigger"]}
                        onClick={() => setOpened(true)}
                      >
                        <span>Add</span>
                      </div>
                    }
                  >
                    <div className="flex flex-col px-4 gap-3 py-3 items-start">
                      <AddPictures
                        onClose={() => setOpened(false)}
                        type="photo"
                      />
                      <AddPictures
                        onClose={() => setOpened(false)}
                        type="album"
                      />
                    </div>
                  </DropDownMenu>
                  <div className="">
                    <Button
                      label="select"
                      type="button"
                      handleClick={() => {
                        setShowSelected(!showSelected);
                        setSelected([]);
                      }}
                      disabled={false}
                      design="secondary"
                      style={{
                        borderRadius: "9.52px",
                        height: "37px",
                      }}
                      className="flex items-center gap-[5px] text-[14.58px]"
                    >
                      <Icon iconName="capture" />
                      <span> Select</span>
                    </Button>
                  </div>
                  <a
                    href={`https://${event.slug}.${process.env.REACT_APP_INAWO_ALBUM_SITE_END}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      label="delete"
                      type="button"
                      disabled={false}
                      design="secondary"
                      className="flex items-center gap-[5px] text-[14.58px]"
                      style={{
                        borderRadius: "9.52px",
                        height: "37px",
                      }}
                    >
                      <Icon iconName="eyeOpenOutline" />
                      <span className="hidden sm:block"> See photobook</span>
                    </Button>
                  </a>
                </>
              )}
            </div>
          </div>

          <ImageList
            groupedMedia={groupedMedia}
            showSelected={showSelected}
            selected={selected}
            handleChange={handleChange}
            setSelectedAlbum={setSelectedAlbum}
            setTabState={setTabState}
          />
        </>
      ) : (
        <div className={styles["photobook__add-pictures-container"]}>
          <div className={styles["photobook__add-pictures"]}>
            <EmptyState />
          </div>
        </div>
      )}
      {/* <Pagination
        pageSize={pageSize}
        startPage={startPage}
        setPageSize={setPageSize}
        setStartPage={setStartPage}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
    /> */}
    </div>
  );
};

export default YourPhotos;
