import { Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";
import Dancer from "../../../assets/images/01.png";
import CloseV2 from "src/assets/icons/closeV2";
import RetryIcon from "src/assets/icons/retryIcon";
import { UploadValue } from "src/utils/types";

const UploadingView = ({
  fileUploaded,
  setFileUploaded,
  setStep,
  percentageCompleted,
  totalTimeLeft,
  isCompleted,
  setRetryUploads,
  onClose,
}: {
  fileUploaded: UploadValue;
  setFileUploaded: Dispatch<SetStateAction<UploadValue>>;
  setStep: Dispatch<SetStateAction<number>>;
  percentageCompleted: number;
  totalTimeLeft: number;
  isCompleted: boolean;
  setRetryUploads: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  return (
    <motion.div className="flex flex-col px-6 md:px-[81px] w-full">
      <p className="font-[500] text-[15px] text-[#71717A] mb-[15px]">
        Uploading {fileUploaded?.length} items
      </p>
      <div className="flex gap-[6px] flex-wrap">
        {fileUploaded.slice(0, 8).map(({ file, preview }: any, idx: number) => (
          <div
            key={idx}
            className="flex relative justify-center items-center overflow-hidden h-[55px] w-[55px] rounded-[9px]"
          >
            {file?.type?.includes("video/") ? (
              <video preload="none" className="h-full w-full object-cover">
                <source src={preview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={preview || Dancer}
                alt={`pov ${idx}`}
                className="w-full h-full object-cover"
              />
            )}
            {fileUploaded?.length > 8 && idx === 7 && (
              <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.3)] z-[1] grid place-items-center text-lg font-semibold text-white dark:text-black">
                <span>+{fileUploaded?.length - 3}</span>
              </div>
            )}
          </div>
        ))}
        <div className="bg-[#F2F2F2] rounded-[6px] h-[3.28px] w-full mt-3 relative">
          <span
            className={`absolute h-full rounded-[6px] bg-[#4E7E76]`}
            style={{
              width: `${percentageCompleted}%`,
            }}
          />
        </div>
        <div>
          <p className="text-xs text-[rgba(113,113,122,0.51)] !text-[14px] font-[500] dark:text-[rgba(113,113,122,1)] mt-3 mb-[18px]">
            Time remaining:{" "}
            <span className="text-[#4E7E76]">{totalTimeLeft}sec</span>
          </p>
          <div className="flex gap-[5px] items-center">
            {percentageCompleted === 100 && !isCompleted && (
              <div
                className="w-[29px] flex items-center justify-center h-[29px] rounded-[10px] bg-[#F2F4F3] text-[#71717A]"
                role="button"
                onClick={() => {
                  setRetryUploads(true);
                }}
              >
                <RetryIcon className="text-[#FFF1F1]" />
              </div>
            )}
            <div
              className="w-[29px] flex items-center justify-center h-[29px] rounded-[10px] bg-[#FFF1F1] text-[#DC2626]"
              role="button"
              onClick={onClose}
            >
              <CloseV2 className="stroke-[#DC2626]" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UploadingView;
