import styles from "./styles.module.scss";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import Button from "src/components/Button";
// import { AddPartType } from "src/utils/types";
import { useMutation } from "@apollo/client";
import { EditEventPart } from "src/graphql/mutations";
import { EventParts } from "src/graphql/queries";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import {
  eventParts_eventParts,
  updateEventPart,
  updateEventPartVariables,
} from "src/types/api";
import { getUtcOffset } from "src/utils";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";

interface Values {
  name: string;
  location: string;
  date: string;
}

const EditPartSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  location: Yup.string().required("Please input your event location"),
  date: Yup.date()
    .min(new Date().toISOString(), "You cannot choose a past date")
    .required("Date is required"),
});

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  part: eventParts_eventParts;
}

function EditPart({ setOpen, part }: Props) {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [updateEventPart, { loading: updateEventPartLoading }] = useMutation<
    updateEventPart,
    updateEventPartVariables
  >(EditEventPart, {
    onCompleted() {
      toast.success(<p>Successfully Updated</p>);
      setOpen(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error occurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["editPart"]}>
      <h1>Edit Part</h1>
      <Formik
        enableReinitialize
        initialValues={{
          name: part?.name || "",
          location: part?.address || "",
          date:
            getUtcOffset(part?.time as unknown as Date).format(
              "YYYY-MM-DDTHH:mm"
            ) || "",
        }}
        validationSchema={EditPartSchema}
        onSubmit={(values, actions) => {
          updateEventPart({
            variables: {
              input: {
                event_id: event.id,
                id: part.id,
                time: `${+new Date(`${values.date}`) / 1000}`,
                name: values.name,
                part: values.name,
                address: values.location,
              },
            },
            refetchQueries: [
              {
                query: EventParts,
                variables: { eventId: event.id, page: 1, pageSize: 50 },
              },
            ],
          });
          actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["editPart-form"]}>
            <div className={styles["editPart-form_container"]}>
              <Input
                type="text"
                name="name"
                label="Name"
                placeholder="e.g Mike and Ife’s Engagement"
              />
              <Input
                type="text"
                name="location"
                label="Location"
                placeholder="Enter Location"
              />
              <Input
                type="datetime-local"
                name="date"
                label="Date"
                placeholder="Select Date"
                min={new Date().toISOString()}
              />
            </div>
            <div className={styles["editPart-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Update Part"
                  disabled={
                    props.values.name === "" ||
                    props.values.location === "" ||
                    props.values.date === "" ||
                    !props.isValid ||
                    props.isSubmitting
                  }
                  loader={updateEventPartLoading}
                >
                  Update Part
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditPart;
