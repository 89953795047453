const NoHistory = () => {
  return (
    <svg
      width="117"
      height="115"
      viewBox="0 0 117 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="42.54"
        y="49.1738"
        width="29.5374"
        height="65.8263"
        fill="url(#paint0_linear_713_12339)"
      />
      <rect
        x="42.54"
        y="49.1738"
        width="29.5374"
        height="65.8263"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M108.683 49.4902V104.082C108.683 106.977 107.533 109.755 105.486 111.802C103.438 113.85 100.661 115 97.7652 115H65.0103V49.4902H108.683ZM17.6976 115C14.8019 115 12.0248 113.85 9.97719 111.802C7.92962 109.755 6.7793 106.977 6.7793 104.082V49.4902H50.4525V115H17.6976Z"
        fill="#4E7E77"
      />
      <path
        d="M22.3366 19.0761C22.3366 14.2499 24.2538 9.62137 27.6664 6.20873C31.0791 2.7961 35.7076 0.878906 40.5338 0.878906C45.36 0.878906 49.9885 2.7961 53.4011 6.20873C56.8138 9.62137 58.731 14.2499 58.731 19.0761C58.731 14.2499 60.6482 9.62137 64.0608 6.20873C67.4734 2.7961 72.1019 0.878906 76.9281 0.878906C81.7543 0.878906 86.3828 2.7961 89.7955 6.20873C93.2081 9.62137 95.1253 14.2499 95.1253 19.0761V19.1198C95.1253 19.6293 95.1253 21.085 94.8487 22.7155H109.683C111.614 22.7155 113.465 23.4824 114.83 24.8474C116.195 26.2125 116.962 28.0639 116.962 29.9944V37.2733C116.962 39.2037 116.195 41.0551 114.83 42.4202C113.465 43.7852 111.614 44.5521 109.683 44.5521H7.77887C5.84839 44.5521 3.99698 43.7852 2.63193 42.4202C1.26688 41.0551 0.5 39.2037 0.5 37.2733V29.9944C0.5 28.0639 1.26688 26.2125 2.63193 24.8474C3.99698 23.4824 5.84839 22.7155 7.77887 22.7155H22.6132C22.421 21.5267 22.3284 20.324 22.3366 19.1198V19.0761ZM30.1104 22.7155H51.4521V19.0761C51.4521 17.6423 51.1697 16.2225 50.621 14.8978C50.0723 13.5732 49.2681 12.3695 48.2542 11.3557C47.2403 10.3418 46.0367 9.53758 44.712 8.98888C43.3874 8.44019 41.9676 8.15777 40.5338 8.15777C39.1 8.15777 37.6802 8.44019 36.3555 8.98888C35.0309 9.53758 33.8272 10.3418 32.8134 11.3557C31.7995 12.3695 30.9953 13.5732 30.4466 14.8978C29.8979 16.2225 29.6155 17.6423 29.6155 19.0761C29.6155 19.6948 29.63 21.0705 29.943 22.206C29.9868 22.3796 30.0427 22.5498 30.1104 22.7155ZM66.0098 22.7155H87.3515C87.4184 22.5495 87.4743 22.3793 87.5189 22.206C87.8319 21.0705 87.8464 19.6948 87.8464 19.0761C87.8464 16.1804 86.6961 13.4033 84.6485 11.3557C82.601 9.30809 79.8238 8.15778 76.9281 8.15778C74.0324 8.15778 71.2553 9.30809 69.2077 11.3557C67.1601 13.4033 66.0098 16.1804 66.0098 19.0761V22.7155Z"
        fill="url(#paint1_linear_713_12339)"
      />
      <path
        d="M22.3366 19.0761C22.3366 14.2499 24.2538 9.62137 27.6664 6.20873C31.0791 2.7961 35.7076 0.878906 40.5338 0.878906C45.36 0.878906 49.9885 2.7961 53.4011 6.20873C56.8138 9.62137 58.731 14.2499 58.731 19.0761C58.731 14.2499 60.6482 9.62137 64.0608 6.20873C67.4734 2.7961 72.1019 0.878906 76.9281 0.878906C81.7543 0.878906 86.3828 2.7961 89.7955 6.20873C93.2081 9.62137 95.1253 14.2499 95.1253 19.0761V19.1198C95.1253 19.6293 95.1253 21.085 94.8487 22.7155H109.683C111.614 22.7155 113.465 23.4824 114.83 24.8474C116.195 26.2125 116.962 28.0639 116.962 29.9944V37.2733C116.962 39.2037 116.195 41.0551 114.83 42.4202C113.465 43.7852 111.614 44.5521 109.683 44.5521H7.77887C5.84839 44.5521 3.99698 43.7852 2.63193 42.4202C1.26688 41.0551 0.5 39.2037 0.5 37.2733V29.9944C0.5 28.0639 1.26688 26.2125 2.63193 24.8474C3.99698 23.4824 5.84839 22.7155 7.77887 22.7155H22.6132C22.421 21.5267 22.3284 20.324 22.3366 19.1198V19.0761ZM30.1104 22.7155H51.4521V19.0761C51.4521 17.6423 51.1697 16.2225 50.621 14.8978C50.0723 13.5732 49.2681 12.3695 48.2542 11.3557C47.2403 10.3418 46.0367 9.53758 44.712 8.98888C43.3874 8.44019 41.9676 8.15777 40.5338 8.15777C39.1 8.15777 37.6802 8.44019 36.3555 8.98888C35.0309 9.53758 33.8272 10.3418 32.8134 11.3557C31.7995 12.3695 30.9953 13.5732 30.4466 14.8978C29.8979 16.2225 29.6155 17.6423 29.6155 19.0761C29.6155 19.6948 29.63 21.0705 29.943 22.206C29.9868 22.3796 30.0427 22.5498 30.1104 22.7155ZM66.0098 22.7155H87.3515C87.4184 22.5495 87.4743 22.3793 87.5189 22.206C87.8319 21.0705 87.8464 19.6948 87.8464 19.0761C87.8464 16.1804 86.6961 13.4033 84.6485 11.3557C82.601 9.30809 79.8238 8.15778 76.9281 8.15778C74.0324 8.15778 71.2553 9.30809 69.2077 11.3557C67.1601 13.4033 66.0098 16.1804 66.0098 19.0761V22.7155Z"
        fill="black"
        fillOpacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_713_12339"
          x1="48.6087"
          y1="49.1738"
          x2="97.1575"
          y2="73.5803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_713_12339"
          x1="24.4278"
          y1="0.878906"
          x2="48.5871"
          y2="73.057"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoHistory;
