import { useState, useContext, Dispatch, SetStateAction } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./messages.module.scss";
import MessagesTable from "./MessagesTable";
// import SendBroadcast from "./SendBroadcast";
import { useQuery } from "@apollo/client";
import { GetMessagesByEvent } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { getMessagesByEvent, getMessagesByEventVariables } from "src/types/api";
import EmptyState from "./EmptyState";
import { useNavigate } from "react-router-dom";
import { SectionWebsiteCountType } from "src/utils/types";
import useAppStore from "src/store/utils";

type Props = {
  count: SectionWebsiteCountType;
  setCount: Dispatch<SetStateAction<SectionWebsiteCountType>>;
};

const Messages: React.FC<Props> = (props) => {
  // const [openBroadcast, setOpenBroadcast] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [pageSize, setPageSize] = useState(300);
  const [page, setPage] = useState(1);
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { data: messages, loading: getMessageLoading } = useQuery<
    getMessagesByEvent,
    getMessagesByEventVariables
  >(GetMessagesByEvent, {
    variables: {
      eventId: event.id,
      page: page,
      pageSize: pageSize,
    },
    skip: !event.id,
    fetchPolicy: "cache-and-network",
    onCompleted(response) {
      if (
        !!response?.getMessagesByEvent &&
        response?.getMessagesByEvent.length
      ) {
        setTotalPages(
          Math.floor(response?.getMessagesByEvent.length / pageSize)
        );
        props.setCount({
          ...props.count,
          messages: response.getMessagesByEvent.length,
        });
      }
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["messages"]}>
      <div className={styles["messages__button-container"]}>
        {/* <div className={styles["messages__button"]}>
          <DialogModal
            open={openBroadcast}
            onOpenChange={setOpenBroadcast}
            trigger={
              <div
                onClick={() => setOpenBroadcast(true)}
                className={styles["messages__trigger"]}
              >
                <span>Send Broadcast</span>
              </div>
            }
          >
            <SendBroadcast setToggleModal={setOpenBroadcast} />
          </DialogModal>
        </div> */}
      </div>
      <div className={styles["messages__table"]}>
        {getMessageLoading ? (
          <div className={styles["messages__loading"]}>
            <InnerLoading />
          </div>
        ) : !!messages?.getMessagesByEvent &&
          messages?.getMessagesByEvent.length > 0 ? (
          messages?.getMessagesByEvent?.map((element) => {
            return (
              <MessagesTable
                page={page}
                pageSize={pageSize}
                key={uuidv4()}
                message={element}
              />
            );
          })
        ) : (
          <EmptyState />
        )}

        {/*<div style={{ display: "none" }}>
          {!getMessageLoading && messages?.getMessagesByEvent && (
            <Pagination
              pageSize={pageSize}
              startPage={startPage}
              setPageSize={setPageSize}
              setStartPage={setStartPage}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
          )}
						</div>*/}
      </div>
    </div>
  );
};

export default Messages;
