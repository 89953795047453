import Close3 from "src/assets/icons/close3";
import { UploadValue } from "src/utils/types";

const ImageGallery = ({
  uploadResult,
  active,
  onRemoveImage,
}: {
  uploadResult: UploadValue;
  active: string;
  onRemoveImage: (preview: string) => void;
}) => {
  const mediaUpload =
    uploadResult.filter((item) => item?.preview === active).length > 0
      ? uploadResult.find((item) => item?.preview === active)
      : uploadResult[0];

  return (
    <div className="mx-auto p-4 w-full">
      <div className="relative">
        <div className="h-[194px] w-[154px] relative  mx-auto">
          {mediaUpload?.file?.type?.includes("video/") ? (
            <video
              preload="none"
              className="h-full w-full object-cover rounded-lg shadow-lg"
              controls
              key={mediaUpload?.preview}
            >
              <source src={mediaUpload?.preview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={mediaUpload?.preview}
              alt="Selected"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          )}
          <button
            type="button"
            className="absolute -top-2 -right-2 bg-gray-800 text-white rounded-full p-1"
            onClick={() =>
              onRemoveImage(
                uploadResult.find((item) => item?.preview === active)
                  ?.preview ?? ""
              )
            }
          >
            <Close3 />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
