import StaticImage from "../StaticImage";

const Media = ({
  media,
  className,
  imageClassName,
}: {
  media: { type: string; url: string };
  className: string;
  imageClassName?: string;
}) => {
  return (
    <>
      {media?.type?.includes("video/") ? (
        <video className={className} controls={false}>
          <source src={media?.url ?? ""} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <StaticImage
          src={media?.url ?? ""}
          alt={`mage`}
          className={className}
          imageClassName={imageClassName}
        />
      )}
    </>
  );
};

export default Media;
