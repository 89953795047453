import { Dispatch, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import InputPhone from "src/components/InputPhone";
import Button from "src/components/Button";
import styles from "./styles.module.scss";
import { GetPlanner } from "src/graphql/queries";
import { UpdateEventVendor } from "src/graphql/mutations";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import {
  eventVendor_eventVendor,
  updateEventVendor,
  updateEventVendorVariables,
} from "src/types/api";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";

interface Values {
  name: string;
  role: string;
  email: string;
  instagram: string;
}

const EditVendorFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .required("Please fill in your vendor's name"),
  role: Yup.string()
    .min(2, "Too Short!")
    .matches(/^[aA-zZ\s]+$/, "Please fill in only alphabets")
    .required("Please fill in your vendor's role"),
  email: Yup.string().email("Please enter a correct email address"),
  instagram: Yup.string().optional(),
});

interface EditVendorProps {
  page: number;
  pageSize: number;
  vendor: eventVendor_eventVendor | null;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

const EditVendor = ({ page, pageSize, vendor, setOpen }: EditVendorProps) => {
  const navigate = useNavigate();
  const [editEventVendor, { loading }] = useMutation<
    updateEventVendor,
    updateEventVendorVariables
  >(UpdateEventVendor, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">You have successfully updated a vendor.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const { event } = useAppStore((state) => state);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(
    vendor?.phone_number ?? ""
  );

  return (
    <div className={`${styles["editPlannerModal--content"]} ${styles["show"]}`}>
      <h1>Edit Vendor</h1>
      <Formik
        enableReinitialize
        initialValues={{
          name: vendor?.name || "",
          role: vendor?.role || "",
          email: vendor?.email || "",
          instagram: vendor?.instagram_handle || "",
        }}
        validationSchema={EditVendorFormSchema}
        onSubmit={(values, actions) => {
          vendor &&
            editEventVendor({
              variables: {
                input: {
                  id: vendor?.id,
                  name: values.name,
                  event_id: event.id,
                  role: values.role,
                  email: values.email,
                  instagram_handle: values.instagram,
                  phone_number: `${phoneNumberValue}`,
                },
              },
              refetchQueries: [
                {
                  query: GetPlanner,
                  variables: { eventId: event.id, page, pageSize },
                },
              ],
            });
          setOpen(false);
          actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["editPlannerModal--content-form"]}>
            <div className={styles["editPlannerModal--content-form_container"]}>
              <Input
                type="text"
                name="name"
                label="Vendor Name"
                placeholder="e.g Steve Bakery"
              />
              <Input
                type="text"
                name="role"
                label="Vendor Role"
                placeholder="e.g Catering Service"
              />
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="user@gmail.com"
              />
              <InputPhone
                name="number"
                label="WhatsApp Number"
                placeholder="Enter WhatsApp Number"
                value={phoneNumberValue}
                setValue={setPhoneNumberValue}
              />
              <Input
                type="text"
                name="instagram"
                label="Instagram (Optional)"
                placeholder="@vendorname"
              />
            </div>
            <div className={styles["editPlannerModal--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Edit Vendor"
                  loader={loading}
                  disabled={
                    props.values.name === "" ||
                    props.values.role === "" ||
                    phoneNumberValue === ""
                  }
                >
                  Edit Vendor
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditVendor;
