import AddPictures from "../AddPictures";
import { AddEmptyIcon } from "src/assets/svg/add";

const EmptyState = () => {
  return (
    <div className="flex justify-center items-center w-full mt-[4rem]">
      <div className="max-w-[400px] flex flex-col justify-center items-center">
        <AddEmptyIcon />
        <p className="text-center text-[#707070] leading-5">
          Add all your beautiful photos/videos and albums for your wedding and
          see those from your guests
        </p>

        <AddPictures
          type="photo"
          trigger={
            <div
              className={
                "bg-[#34544F] text-white rounded-[11px] py-3 px-7 mt-5 w-fit"
              }
            >
              <h4>Add</h4>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default EmptyState;
