const CloseV2 = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_588_885)">
        <path
          d="M19 6.5079L17.59 5.0979L12 10.6879L6.41 5.0979L5 6.5079L10.59 12.0979L5 17.6879L6.41 19.0979L12 13.5079L17.59 19.0979L19 17.6879L13.41 12.0979L19 6.5079Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_588_885">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.0979004)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseV2;
