const ExpandIcon = () => {
  return (
    <svg
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73736 0.737354C8.99826 0.476525 9.35208 0.33 9.72101 0.33C10.0899 0.33 10.4437 0.476525 10.7047 0.737354L18.5753 8.60795C18.7081 8.73629 18.8141 8.88982 18.887 9.05956C18.96 9.2293 18.9983 9.41187 18.9999 9.59661C19.0016 9.78134 18.9664 9.96455 18.8964 10.1355C18.8264 10.3065 18.7231 10.4619 18.5925 10.5925C18.4619 10.7231 18.3065 10.8264 18.1355 10.8964C17.9646 10.9664 17.7813 11.0016 17.5966 10.9999C17.4119 10.9983 17.2293 10.96 17.0596 10.887C16.8898 10.8141 16.7363 10.7081 16.608 10.5753L9.72101 3.68831L2.83406 10.5753C2.57166 10.8287 2.22021 10.9689 1.85542 10.9658C1.49062 10.9626 1.14167 10.8163 0.883707 10.5583C0.625748 10.3003 0.479425 9.95139 0.476255 9.58659C0.473085 9.2218 0.613321 8.87035 0.866758 8.60795L8.73736 0.737354Z"
        fill="black"
      />
    </svg>
  );
};

export default ExpandIcon;
