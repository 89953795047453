import { Dispatch } from "react";
import { Formik, Form, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "src/components/Input";
import Button from "src/components/Button";
import addTask from "./addTask.module.scss";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import {
  addCustomMyPlanTask,
  addCustomMyPlanTaskVariables,
} from "src/types/api";
import { AddCustomMyPlanTask } from "src/graphql/mutations/event.mutation";
import { GetCustomMyPlanTask } from "src/graphql/queries/event.queries";
import useAppStore from "src/store/utils";

interface Values {
  name: string;
  description: string;
}

const GeneralFormSchema = Yup.object().shape({
  name: Yup.string().required("Task name is required"),
  description: Yup.string(),
});

interface AddTasksProps {
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

const AddTask = ({ setOpen }: AddTasksProps) => {
  const navigate = useNavigate();
  const [addTaskFn, { loading }] = useMutation<
    addCustomMyPlanTask,
    addCustomMyPlanTaskVariables
  >(AddCustomMyPlanTask, {
    onCompleted() {
      setOpen(false);
      toast.success(<p>Task Added.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const { event } = useAppStore((state) => state);

  return (
    <div className={addTask["addTask--content"]}>
      <div className={addTask["addTask--content__top"]}>
        <h1>New Task</h1>
        <p className={addTask["addTask--content__sub"]}>
          Add a new task to your wedding
        </p>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          description: "",
        }}
        validationSchema={GeneralFormSchema}
        onSubmit={(values) => {
          addTaskFn({
            variables: {
              eventId: event.id,
              description: values.description,
              taskName: values.name,
            },
            refetchQueries: [
              {
                query: GetCustomMyPlanTask,
                variables: {
                  eventId: event.id,
                },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={addTask["addTask--content-form"]}>
            <div className={addTask["addTask--content-form_container"]}>
              <Input
                type="text"
                name="name"
                label="Task Name"
                placeholder="e.g Visit the Caterer"
              />
              <Input
                type="textarea"
                rows={4}
                name="description"
                label="Brief Description"
                placeholder="e.g Check on the caterer to see the progress of the cakes"
              />
            </div>
            <div className={addTask["addTask--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Task"
                  loader={loading}
                  disabled={props.values.name === ""}
                >
                  Add Task
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddTask;
