import React from "react";

const UpcomingSchedule = () => {
  return (
    <svg
      width="353"
      height="216"
      viewBox="0 0 353 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.948 91.1661L149.455 92.6858C165.782 96.5046 170.77 104.571 166.951 120.859L163.132 137.147C159.859 151.214 153.391 156.903 141.233 155.734C139.285 155.579 137.18 155.228 134.92 154.682L128.374 153.124C112.125 149.266 107.098 141.239 110.917 124.95L114.735 108.623C115.515 105.311 116.45 102.428 117.619 100.051C122.178 90.6205 129.933 88.0877 142.948 91.1661Z"
        fill="url(#paint0_linear_837_11576)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.948 91.1661L149.455 92.6858C165.782 96.5046 170.77 104.571 166.951 120.859L163.132 137.147C159.859 151.214 153.391 156.903 141.233 155.734C139.285 155.579 137.18 155.228 134.92 154.682L128.374 153.124C112.125 149.266 107.098 141.239 110.917 124.95L114.735 108.623C115.515 105.311 116.45 102.428 117.619 100.051C122.178 90.6205 129.933 88.0877 142.948 91.1661Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g filter="url(#filter0_bi_837_11576)">
        <rect
          x="129.868"
          y="82.7686"
          width="49.781"
          height="56.2582"
          rx="18.3681"
          transform="rotate(12.7985 129.868 82.7686)"
          fill="#FDFDFD"
          fillOpacity="0.78"
        />
        <rect
          x="130.19"
          y="83.28"
          width="48.9262"
          height="55.4035"
          rx="17.9407"
          transform="rotate(12.7985 130.19 83.28)"
          stroke="white"
          strokeWidth="0.854723"
        />
      </g>
      <path
        d="M250.829 115.728C250.245 115.162 249.38 114.936 248.588 115.145L229.898 119.914L232.673 106.555C233.674 101.736 230.567 96.9989 225.748 95.9978L155.48 81.4009C150.661 80.3998 145.923 83.5074 144.922 88.3264L136.611 128.335C135.61 133.155 138.718 137.892 143.537 138.893L213.805 153.49C218.624 154.491 223.361 151.383 224.362 146.564L227.137 133.205L242.382 145.023C242.685 145.25 243.003 145.41 243.362 145.484C243.788 145.573 244.237 145.549 244.669 145.382C245.452 145.1 246.014 144.422 246.182 143.615L251.513 117.951C251.681 117.144 251.435 116.298 250.829 115.728ZM182.748 126.509C177.749 125.471 174.535 120.571 175.573 115.573C176.612 110.575 181.511 107.361 186.51 108.399C191.508 109.437 194.722 114.337 193.684 119.335C192.646 124.334 187.746 127.548 182.748 126.509Z"
        fill="url(#paint1_linear_837_11576)"
      />
      <path
        d="M201.06 119.692L190.397 128.052C188.319 129.706 185.613 130.349 183.012 129.809C180.411 129.269 178.185 127.6 176.937 125.256L170.793 113.68C169.691 111.615 169.412 109.208 170.009 106.945C170.607 104.682 172.038 102.728 174.016 101.476C176.139 100.204 178.696 99.8764 181.069 100.572C183.457 101.253 185.445 102.913 186.54 105.141L187.828 107.537L189.638 106.094C191.435 104.594 193.734 103.831 196.071 103.959C198.408 104.089 200.61 105.099 202.231 106.787C203.788 108.655 204.524 111.075 204.27 113.494C204.092 115.912 202.932 118.152 201.06 119.692L201.06 119.692Z"
        fill="url(#paint2_linear_837_11576)"
      />
      <circle cx="64.8412" cy="74.9886" r="11.763" fill="#619D94" />
      <circle
        cx="66.9779"
        cy="88.8773"
        r="11.763"
        fill="url(#paint3_linear_837_11576)"
      />
      <circle
        cx="66.9779"
        cy="88.8773"
        r="11.763"
        fill="black"
        fillOpacity="0.1"
      />
      <circle cx="286.001" cy="72.8519" r="11.763" fill="#619D94" />
      <circle
        cx="288.138"
        cy="86.7416"
        r="11.763"
        fill="url(#paint4_linear_837_11576)"
      />
      <circle
        cx="288.138"
        cy="86.7416"
        r="11.763"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M145.777 190.733C140.424 189.968 135.357 184.779 131.961 180.124C130.269 177.804 126.727 177.468 124.895 179.68L117.803 188.238C116.596 189.696 116.558 191.797 117.819 193.208C121.972 197.85 129.67 205.098 138.603 209.565C147.436 213.981 155.463 214.269 159.957 213.652C161.457 213.446 162.536 212.215 162.804 210.724L165.436 196.105C165.916 193.435 163.674 191.034 160.965 191.177C156.541 191.411 150.76 191.445 145.777 190.733Z"
        fill="url(#paint5_linear_837_11576)"
      />
      <path
        d="M145.777 190.733C140.424 189.968 135.357 184.779 131.961 180.124C130.269 177.804 126.727 177.468 124.895 179.68L117.803 188.238C116.596 189.696 116.558 191.797 117.819 193.208C121.972 197.85 129.67 205.098 138.603 209.565C147.436 213.981 155.463 214.269 159.957 213.652C161.457 213.446 162.536 212.215 162.804 210.724L165.436 196.105C165.916 193.435 163.674 191.034 160.965 191.177C156.541 191.411 150.76 191.445 145.777 190.733Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g opacity="0.3">
        <path
          d="M145.087 192.733C139.733 191.968 134.666 186.779 131.271 182.124C129.578 179.804 126.037 179.468 124.205 181.68L117.113 190.238C115.905 191.696 115.867 193.797 117.129 195.208C121.281 199.85 128.979 207.098 137.913 211.565C146.746 215.981 154.773 216.269 159.267 215.652C160.767 215.446 161.846 214.215 162.114 212.724L164.745 198.105C165.226 195.435 162.984 193.034 160.274 193.177C155.85 193.411 150.07 193.445 145.087 192.733Z"
          fill="url(#paint6_linear_837_11576)"
        />
        <path
          d="M145.087 192.733C139.733 191.968 134.666 186.779 131.271 182.124C129.578 179.804 126.037 179.468 124.205 181.68L117.113 190.238C115.905 191.696 115.867 193.797 117.129 195.208C121.281 199.85 128.979 207.098 137.913 211.565C146.746 215.981 154.773 216.269 159.267 215.652C160.767 215.446 161.846 214.215 162.114 212.724L164.745 198.105C165.226 195.435 162.984 193.034 160.274 193.177C155.85 193.411 150.07 193.445 145.087 192.733Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <ellipse
        cx="140.621"
        cy="200.015"
        rx="4.70794"
        ry="3.45249"
        fill="#DFEBEA"
        fillOpacity="0.48"
      />
      <path
        d="M204.671 187.641C199.449 186.234 192.748 189.007 187.792 191.947C185.322 193.413 181.936 192.326 181.123 189.571L177.978 178.911C177.442 177.095 178.235 175.149 179.951 174.35C185.596 171.72 195.527 168.091 205.498 167.506C215.357 166.928 222.848 169.826 226.735 172.164C228.033 172.945 228.539 174.501 228.198 175.977L224.856 190.45C224.245 193.094 221.239 194.417 218.805 193.218C214.831 191.259 209.531 188.95 204.671 187.641Z"
        fill="url(#paint7_linear_837_11576)"
      />
      <path
        d="M204.671 187.641C199.449 186.234 192.748 189.007 187.792 191.947C185.322 193.413 181.936 192.326 181.123 189.571L177.978 178.911C177.442 177.095 178.235 175.149 179.951 174.35C185.596 171.72 195.527 168.091 205.498 167.506C215.357 166.928 222.848 169.826 226.735 172.164C228.033 172.945 228.539 174.501 228.198 175.977L224.856 190.45C224.245 193.094 221.239 194.417 218.805 193.218C214.831 191.259 209.531 188.95 204.671 187.641Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M203.082 184.521C197.86 183.114 191.159 185.887 186.204 188.827C183.733 190.293 180.347 189.206 179.534 186.451L176.389 175.791C175.853 173.975 176.646 172.029 178.362 171.229C184.007 168.6 193.938 164.971 203.91 164.386C213.768 163.807 221.259 166.706 225.146 169.044C226.444 169.825 226.95 171.381 226.609 172.857L223.267 187.33C222.656 189.974 219.65 191.297 217.216 190.097C213.242 188.139 207.942 185.83 203.082 184.521Z"
        fill="url(#paint8_linear_837_11576)"
      />
      <path
        d="M203.082 184.521C197.86 183.114 191.159 185.887 186.204 188.827C183.733 190.293 180.347 189.206 179.534 186.451L176.389 175.791C175.853 173.975 176.646 172.029 178.362 171.229C184.007 168.6 193.938 164.971 203.91 164.386C213.768 163.807 221.259 166.706 225.146 169.044C226.444 169.825 226.95 171.381 226.609 172.857L223.267 187.33C222.656 189.974 219.65 191.297 217.216 190.097C213.242 188.139 207.942 185.83 203.082 184.521Z"
        fill="black"
        fillOpacity="0.1"
      />
      <ellipse
        cx="4.70794"
        cy="3.45249"
        rx="4.70794"
        ry="3.45249"
        transform="matrix(0.919088 0.394053 0.394053 -0.919088 197.902 178.396)"
        fill="#DFEBEA"
        fillOpacity="0.48"
      />
      <path
        d="M57.0001 11.9998C46.3334 11.3332 22.2 14 11 46V190"
        stroke="url(#paint9_linear_837_11576)"
        strokeWidth="22"
        strokeLinecap="round"
      />
      <path
        d="M57.0001 11.9998C46.3334 11.3332 22.2 14 11 46V190"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="22"
        strokeLinecap="round"
      />
      <path
        d="M296 11.9998C306.667 11.3332 330.8 14 342 46V190"
        stroke="url(#paint10_linear_837_11576)"
        strokeWidth="22"
        strokeLinecap="round"
      />
      <path
        d="M296 11.9998C306.667 11.3332 330.8 14 342 46V190"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="22"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_bi_837_11576"
          x="107.125"
          y="72.4877"
          width="81.5676"
          height="86.4494"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.94462" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_837_11576"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_837_11576"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.27362" />
          <feGaussianBlur stdDeviation="8.54723" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_837_11576"
          />
        </filter>
        <linearGradient
          id="paint0_linear_837_11576"
          x1="121.715"
          y1="89.9248"
          x2="182.324"
          y2="150.114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_837_11576"
          x1="200.779"
          y1="90.8109"
          x2="226.937"
          y2="148.214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37645D" />
          <stop offset="1" stopColor="#1F5C53" stopOpacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_837_11576"
          x1="188.846"
          y1="102.158"
          x2="183.099"
          y2="129.827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2C3BE" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_837_11576"
          x1="60.0484"
          y1="77.1143"
          x2="81.5282"
          y2="101.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_837_11576"
          x1="281.209"
          y1="74.9785"
          x2="302.688"
          y2="99.0434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_837_11576"
          x1="126.146"
          y1="175.04"
          x2="159.431"
          y2="223.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_837_11576"
          x1="125.456"
          y1="177.04"
          x2="158.741"
          y2="225.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_837_11576"
          x1="180.444"
          y1="194.329"
          x2="230.198"
          y2="162.753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_837_11576"
          x1="178.856"
          y1="191.208"
          x2="228.609"
          y2="159.633"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_837_11576"
          x1="20.451"
          y1="11.917"
          x2="107.847"
          y2="37.2089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_837_11576"
          x1="332.549"
          y1="11.917"
          x2="245.153"
          y2="37.2089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UpcomingSchedule;
