import * as React from "react";
const MenuHorizontal = () => (
  <svg
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-[26px] w-[26px] sm:h-[44px] sm:w-[44px]"
  >
    <path
      d="M0.273438 0.939453H43.2454V43.9114H5.8182C2.75591 43.9114 0.273438 41.4289 0.273438 38.3666V0.939453Z"
      fill="black"
      fillOpacity={0.67}
    />
    <circle cx={13.607} cy={22.0655} r={1.59965} fill="white" />
    <circle cx={21.4173} cy={22.0655} r={1.59965} fill="white" />
    <circle cx={29.2276} cy={22.0655} r={1.59965} fill="white" />
  </svg>
);
export default MenuHorizontal;
