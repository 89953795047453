import { useState } from "react";
import styles from "./input.module.scss";

import Icon from "../Icon";

import { useField } from "formik";

interface InputProps {
  type:
    | "text"
    | "password"
    | "tel"
    | "number"
    | "email"
    | "date"
    | "time"
    | "textarea"
    | "datetime-local";
  placeholder: string;
  name: string;
  label: string;
  id?: string;
  min?: string;
  rows?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  required?: boolean;
  value?: string;
}

interface SearchProps extends Omit<InputProps, "type" | "label"> {
  value?: string;
  icon?: React.ReactElement;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  const isError = Boolean(meta.error && meta.touched);

  const [toggleShowPassword, setToggleShowPassword] = useState(false);

  const [active, setActive] = useState(false);

  const inputType =
    props.type !== "password"
      ? props.type
      : toggleShowPassword
      ? "text"
      : "password";

  const handleClearIcon = () => {
    helpers.setValue("");
  };

  const handleToggleShowPassword = () => {
    setToggleShowPassword(!toggleShowPassword);
  };

  const textIcon = active && (
    <button
      type="button"
      className={styles["input__icon"]}
      onClick={handleClearIcon}
    >
      {props.type === "datetime-local" || props.type === "date" ? (
        ""
      ) : (
        <Icon iconName="close" />
      )}
    </button>
  );

  const passwordIcon = (
    <button
      type="button"
      aria-label="password"
      className={styles["input__icon"]}
      onClick={handleToggleShowPassword}
    >
      <Icon iconName={toggleShowPassword ? "eyeOpen" : "eyeClose"} />
    </button>
  );

  return (
    <div className={styles["input"]}>
      <label htmlFor={props.name}>{props.label}</label>
      <div
        className={styles["input__field"]}
        data-input={props.type === "tel"}
        data-focused={active}
      >
        {props.type === "textarea" ? (
          <textarea
            {...field}
            {...props}
            onFocus={() => setActive(true)}
          ></textarea>
        ) : (
          <input
            {...field}
            {...props}
            type={inputType}
            onFocus={() => setActive(true)}
          ></input>
        )}

        <div className={styles["input__label-icon"]}>
          {props.type === "password"
            ? passwordIcon
            : props.type === "date" || props.type === "time"
            ? ""
            : textIcon}
        </div>
      </div>
      {isError && (
        <p className={styles["input__errors"]} data-error={isError}>
          <span className={styles["input__errors-message"]}>{meta.error}</span>
        </p>
      )}
    </div>
  );
};

// Reusable Search Input exported as a named export
const SearchInput: React.FC<SearchProps> = ({ ...props }) => {
  return (
    <div className={styles["search-input"]}>
      {props.icon}
      <input
        type="search"
        {...props}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        autoComplete="false"
      />
    </div>
  );
};

export { SearchInput };
export default Input;
