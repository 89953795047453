import React from "react";

const NoInvite = () => {
  return (
    <svg
      width="134"
      height="135"
      viewBox="0 0 134 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M111.686 114.967H22.4277C16.2913 114.967 11.2705 109.946 11.2705 103.81V45.5133C11.2705 41.8872 12.9441 38.54 16.0123 36.3086L67.0566 0.605469L118.101 36.3086C121.169 38.2611 122.843 41.8872 122.843 45.5133V103.81C122.843 109.946 117.822 114.967 111.686 114.967Z"
        fill="url(#paint0_linear_713_11390)"
      />
      <path
        d="M33.3926 32.3916C33.3926 27.6039 37.2738 23.7227 42.0615 23.7227H91.667C96.4547 23.7227 100.336 27.6039 100.336 32.3916V87.2948C100.336 92.0825 96.4547 95.9637 91.667 95.9637H42.0615C37.2738 95.9637 33.3926 92.0825 33.3926 87.2948V32.3916Z"
        fill="url(#paint1_linear_713_11390)"
      />
      <path
        d="M51.2686 48.9304L59.9899 58.8977C60.4415 59.4138 61.2069 59.5125 61.7747 59.1279L81.843 45.5332"
        stroke="url(#paint2_linear_713_11390)"
        strokeWidth="3.39717"
        strokeLinecap="round"
      />
      <path
        d="M51.2686 48.9304L59.9899 58.8977C60.4415 59.4138 61.2069 59.5125 61.7747 59.1279L81.843 45.5332"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="3.39717"
        strokeLinecap="round"
      />
      <path
        d="M111.686 114.966H22.4277C16.2913 114.966 11.2705 109.945 11.2705 103.809V48.0225L67.0566 84.2835L122.843 48.0225V103.809C122.843 109.945 117.822 114.966 111.686 114.966Z"
        fill="url(#paint3_linear_713_11390)"
      />
      <path
        d="M111.686 114.966H22.4277C16.2913 114.966 11.2705 109.945 11.2705 103.809V48.0225L67.0566 84.2835L122.843 48.0225V103.809C122.843 109.945 117.822 114.966 111.686 114.966Z"
        fill="black"
        fillOpacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_713_11390"
          x1="34.1937"
          y1="0.605469"
          x2="138.869"
          y2="115.018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#446762" stopOpacity="0.29" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_713_11390"
          x1="66.8643"
          y1="23.7227"
          x2="66.8643"
          y2="95.9637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0D8D4" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_713_11390"
          x1="57.5503"
          y1="45.5332"
          x2="66.858"
          y2="67.8787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_713_11390"
          x1="34.1937"
          y1="48.0225"
          x2="85.3972"
          y2="143.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoInvite;
