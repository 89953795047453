import { Spinner } from "../Spinner";
import styles from "./button.module.scss";

interface ButtonProps {
  children: React.ReactNode;
  label: string;
  handleClick?: () => void;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  loader?: boolean;
  design?: "orange" | "primary" | "secondary" | "tertiary";
  className?: string;
  wrapperBtnClassName?: string;
  fontSize?: string;
  style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  children,
  type,
  label,
  handleClick,
  disabled,
  loader,
  design = "primary",
  className,
  wrapperBtnClassName,
  fontSize,
  style,
}) => {
  return (
    <button
      onClick={() => handleClick?.()}
      aria-label={label}
      type={type}
      className={`
			${design === "primary" && styles["button"]} 
			${design === "tertiary" && styles["button--tertiary"]} 
			${design === "secondary" && styles["button--secondary"]}
			${design === "orange" && styles["button--orange"]} 
      ${wrapperBtnClassName ? wrapperBtnClassName : ``}
      `}
      disabled={loader || disabled}
      style={style}
    >
      <span className={`text-${fontSize} ${className ? className : ``} `}>
        {loader ? <Spinner /> : children}
      </span>
    </button>
  );
};

export default Button;
