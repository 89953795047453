const CollapseIcon = () => {
  return (
    <svg
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2626 10.2626C10.0017 10.5235 9.64792 10.67 9.27899 10.67C8.91007 10.67 8.55625 10.5235 8.29534 10.2626L0.424748 2.39205C0.291865 2.26371 0.185872 2.11018 0.112955 1.94044C0.0400387 1.7707 0.00165784 1.58813 5.25305e-05 1.40339C-0.00155278 1.21866 0.0336499 1.03545 0.103606 0.864464C0.173562 0.693478 0.27687 0.538136 0.407503 0.407503C0.538136 0.27687 0.693478 0.173561 0.864464 0.103605C1.03545 0.0336492 1.21866 -0.00155278 1.40339 5.25315e-05C1.58813 0.00165784 1.77069 0.0400387 1.94044 0.112955C2.11018 0.185872 2.26371 0.291864 2.39205 0.424748L9.27899 7.31169L16.1659 0.424748C16.4283 0.171311 16.7798 0.0310755 17.1446 0.0342454C17.5094 0.0374154 17.8583 0.183737 18.1163 0.441696C18.3743 0.699654 18.5206 1.04861 18.5237 1.41341C18.5269 1.7782 18.3867 2.12965 18.1332 2.39205L10.2626 10.2626Z"
        fill="black"
      />
    </svg>
  );
};

export default CollapseIcon;
