import { useFormik, FormikProps } from "formik";
import React, { useContext, useState, useMemo } from "react";
import Button from "src/components/Button";
import styles from "../AddForiegnAcct/addForeignAcct.module.scss";
import * as Yup from "yup";
import Icon from "src/components/Icon";
import ErrorSpan from "src/components/ErrorSpan";
import { Country } from "country-state-city";
import * as Popover from "@radix-ui/react-popover";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { SearchInput } from "src/components/Input";
import { v4 as uuidv4 } from "uuid";
import { getStatesOfCountry } from "country-state-city/lib/state";
import { useMutation } from "@apollo/client";
import {
  Currency,
  foreignAccountDetails,
  setForeignAccountDetails,
  setForeignAccountDetailsVariables,
} from "src/types/api.d";
import { UpdateForeignAccountDetails } from "src/graphql/mutations/wallets.mutations";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AppContext, ValueProps } from "src/context";
import { GetForeignWalletDetails } from "src/graphql/queries/wallets.queries";
import useAppStore from "src/store/utils";

interface ForeignDetailsInterface {
  account_number: string;
  bank_name: string;
  beneficiary_name: string;
  swift_code: string;
  country: string;
  city: string;
  bank_address?: string | null;
  post_code: string;
  sort_code: string;
}

const AddForeignAcctSchema: Yup.SchemaOf<ForeignDetailsInterface> =
  Yup.object().shape({
    account_number: Yup.string().required(),
    bank_name: Yup.string().required(),
    beneficiary_name: Yup.string().required(),
    swift_code: Yup.string().required(),
    country: Yup.string().required(),
    city: Yup.string().required(),
    bank_address: Yup.string().required(),
    post_code: Yup.string().required(),
    sort_code: Yup.string().required(),
  });

interface EditForeignAcctInterface {
  foreignDetails: foreignAccountDetails;
  setOpen: (i: boolean) => void;
}

const EditForiegnAcct = ({
  foreignDetails,
  setOpen,
}: EditForeignAcctInterface) => {
  const [openCountrySearch, setOpenCountrySearch] = useState(false);
  const [countrySearch, setCountrySearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [openCitySearch, setOpenCitySearch] = useState(false);
  const contextValue = useContext<ValueProps | null>(AppContext);
  const { event } = useAppStore((state) => state);
  const walletId = contextValue?.walletCode;

  const navigate = useNavigate();

  const [setForeignAcctFn, { loading, error }] = useMutation<
    setForeignAccountDetails,
    setForeignAccountDetailsVariables
  >(UpdateForeignAccountDetails, {
    onCompleted(res) {
      toast.success(
        <p>{`Your ${
          foreignDetails.currency === Currency.usd ? `Dollar` : `Pound`
        } Account has been updated`}</p>
      );
      formik.resetForm();
      setOpen(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const formik: FormikProps<ForeignDetailsInterface> =
    useFormik<ForeignDetailsInterface>({
      initialValues: {
        account_number: foreignDetails.account_number,
        bank_address: foreignDetails.bank_address,
        bank_name: foreignDetails.bank_name,
        beneficiary_name: foreignDetails.beneficiary_name,
        city: foreignDetails.city,
        country: foreignDetails.country,
        post_code: foreignDetails.post_code,
        swift_code: foreignDetails.swift_code,
        sort_code: foreignDetails.sort_code,
      },
      validationSchema: AddForeignAcctSchema,
      onSubmit: (values) => {
        if (walletId) {
          setForeignAcctFn({
            variables: {
              args: {
                ...values,
                currency: foreignDetails.currency,
                event_id: event.id,
                walletId: walletId,
              },
            },
            refetchQueries: [
              {
                query: GetForeignWalletDetails,
                variables: {
                  eventId: event.id,
                },
              },
            ],
          });
        }
      },
    });

  const countries = useMemo(() => {
    const res = Country.getAllCountries().filter((c) =>
      c.name.toLowerCase().includes(countrySearch.toLowerCase())
    );
    return res;
  }, [countrySearch]);

  const cityOptions = useMemo(() => {
    const code = Country.getAllCountries().find(
      (c) => c.name === formik.values.country
    )?.isoCode;
    const res = getStatesOfCountry(code).filter((c) =>
      c.name.toLowerCase().includes(citySearch.toLowerCase())
    );
    return res;
  }, [formik.values.country, citySearch]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles["addForeignAcct_content_form_container"]}>
        <div className={styles["addForeignAcct_content_form_container__group"]}>
          <p>Beneficiary Name</p>
          <input
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__input"
              ]
            }
            type="text"
            name="beneficiary_name"
            value={formik.values.beneficiary_name}
            placeholder="e.g Steve Bakery"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"beneficiary_name"}
            formik={formik}
          />
        </div>
        <div className={styles["addForeignAcct_content_form_container__group"]}>
          <p>IBAN/Account Number</p>
          <input
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__input"
              ]
            }
            type="string"
            name="account_number"
            value={formik.values.account_number}
            placeholder="e.g 01234567890"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"account_number"}
            formik={formik}
          />
        </div>
        <div className={styles["addForeignAcct_content_form_container__group"]}>
          <p>SWIFT/BIC Code</p>
          <input
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__input"
              ]
            }
            type="string"
            name="swift_code"
            value={formik.values.swift_code}
            placeholder="eg. HDBBYB873U9"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"swift_code"}
            formik={formik}
          />
        </div>
        <div
          className={styles["addForeignAcct_content_form_container__select"]}
        >
          <label htmlFor="Search Country">Beneficiary Country</label>

          <Popover.Root
            open={openCountrySearch}
            onOpenChange={(open: boolean) => setOpenCountrySearch(open)}
          >
            <Popover.Trigger>
              <div
                className={
                  styles["addForeignAcct_content_form_container__select-css"]
                }
              >
                {formik.values.country || "Select Country"}

                {formik.values.country ? "" : <Icon iconName="caretDouble" />}
              </div>
            </Popover.Trigger>
            <ScrollArea.Root>
              <ScrollArea.Viewport>
                <Popover.Content
                  className={
                    styles[
                      "addForeignAcct_content_form_container__select-css__content"
                    ]
                  }
                >
                  <>
                    <div
                      className={
                        styles[
                          "addForeignAcct_content_form_container__select-css__content__item"
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            "addForeignAcct_content_form_container__searchbar"
                          ]
                        }
                      >
                        <SearchInput
                          icon={<Icon iconName="search" />}
                          name="Search Country"
                          placeholder="Search Country"
                          value={countrySearch}
                          onChange={(e) => setCountrySearch(e.target.value)}
                        />
                      </div>
                      {countries.map((country) => (
                        <div
                          className={
                            styles[
                              "addForeignAcct_content_form_container__banks"
                            ]
                          }
                          key={uuidv4()}
                          onClick={() => {
                            formik.setFieldValue("country", country.name, true);
                            setOpenCountrySearch(false);
                            setCountrySearch("");
                          }}
                        >
                          {country?.name}
                        </div>
                      ))}
                    </div>
                  </>
                </Popover.Content>
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar orientation="vertical">
                <ScrollArea.Thumb />
              </ScrollArea.Scrollbar>
              <ScrollArea.Corner />
            </ScrollArea.Root>
          </Popover.Root>

          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"country"}
            formik={formik}
          />
        </div>

        <div
          className={styles["addForeignAcct_content_form_container__select"]}
        >
          <label htmlFor="Search City">Beneficiary City</label>

          <Popover.Root
            open={openCitySearch}
            onOpenChange={(open: boolean) => setOpenCitySearch(open)}
          >
            <Popover.Trigger>
              <div
                className={
                  styles["addForeignAcct_content_form_container__select-css"]
                }
              >
                {formik.values.city || "Select City"}

                {formik.values.city ? "" : <Icon iconName="caretDouble" />}
              </div>
            </Popover.Trigger>
            <ScrollArea.Root>
              <ScrollArea.Viewport>
                <Popover.Content
                  className={
                    styles[
                      "addForeignAcct_content_form_container__select-css__content"
                    ]
                  }
                >
                  <>
                    <div
                      className={
                        styles[
                          "addForeignAcct_content_form_container__select-css__content__item"
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            "addForeignAcct_content_form_container__searchbar"
                          ]
                        }
                      >
                        <SearchInput
                          icon={<Icon iconName="search" />}
                          name="Search City"
                          placeholder="Search City"
                          value={citySearch}
                          onChange={(e) => setCitySearch(e.target.value)}
                        />
                      </div>
                      {cityOptions.map((city) => (
                        <div
                          className={
                            styles[
                              "addForeignAcct_content_form_container__banks"
                            ]
                          }
                          key={uuidv4()}
                          onClick={() => {
                            formik.setFieldValue("city", city.name, true);
                            setOpenCitySearch(false);
                            setCitySearch("");
                          }}
                        >
                          {city?.name}
                        </div>
                      ))}
                    </div>
                  </>
                </Popover.Content>
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar orientation="vertical">
                <ScrollArea.Thumb />
              </ScrollArea.Scrollbar>
              <ScrollArea.Corner />
            </ScrollArea.Root>
          </Popover.Root>

          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"city"}
            formik={formik}
          />
        </div>
        <div className={styles["addForeignAcct_content_form_container__group"]}>
          <p>Beneficiary Bank Address</p>
          <input
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__input"
              ]
            }
            type="text"
            name="bank_address"
            value={formik.values.bank_address ?? ""}
            placeholder="Enter Amount"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"bank_address"}
            formik={formik}
          />
        </div>
        <div className={styles["addForeignAcct_content_form_container__group"]}>
          <p>Bank Name</p>
          <input
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__input"
              ]
            }
            type="text"
            name="bank_name"
            value={formik.values.bank_name}
            placeholder="eg. Citi Bank"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"bank_name"}
            formik={formik}
          />
        </div>
        <div className={styles["addForeignAcct_content_form_container__group"]}>
          <p>Sort Code</p>
          <input
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__input"
              ]
            }
            type="string"
            name="sort_code"
            value={formik.values.sort_code}
            placeholder="enter sort code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"sort_code"}
            formik={formik}
          />
        </div>
        <div className={styles["addForeignAcct_content_form_container__group"]}>
          <p>Post Code</p>
          <input
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__input"
              ]
            }
            type="string"
            name="post_code"
            value={formik.values.post_code}
            placeholder="eg. bde304"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorSpan
            className={
              styles[
                "addForeignAcct_content_form_container__select-css__content__error"
              ]
            }
            name={"post_code"}
            formik={formik}
          />
        </div>
      </div>
      <Button
        loader={loading && !error}
        type="submit"
        label="Transfer"
        disabled={false}
      >
        Edit Account Details
      </Button>
    </form>
  );
};

export default EditForiegnAcct;
