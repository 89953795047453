import React from "react";
import { isEmpty } from "lodash";
import { GetPov } from "src/types/api";
import { Dialog, DialogContent } from "src/components/CustomDialog";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Video from "src/components/Video";
import { cn } from "src/lib/utils";

const GalleryModal = ({
  selectedPov,
  closeModal,
  selectedItem,
  setSelectedItem,
}: {
  selectedPov?: Partial<GetPov["getPov"][0]>;
  closeModal: () => void;
  selectedItem?: {
    item: GetPov["getPov"][0]["media"][0];
    group: GetPov["getPov"][0]["media"];
  };
  setSelectedItem: React.Dispatch<
    React.SetStateAction<
      | {
          item: GetPov["getPov"][0]["media"][0];
          group: GetPov["getPov"][0]["media"];
        }
      | undefined
    >
  >;
}) => {
  return (
    <Dialog open={!isEmpty(selectedPov)} onOpenChange={closeModal}>
      <DialogContent
        className="w-screen max-w-none fixed h-screen backdrop-blur-lg bg-modal-liners p-[1px] overflow-hidden shadow-inawo-3 transition-height ease-in-out duration-300 z-50 !bg-transparent grid place-items-center"
        closeBtnClassName="rounded-full p-[18px]  dark:!bg-black/50 !bg-[#49494966] w-fit mx-auto left-0  md:left-auto [&_svg]:!text-white"
      >
        <div className="w-full">
          <div className="flex relative justify-center items-center overflow-hidden max-w max-h md:w-[784px] md:h-[483px] mx-auto">
            {selectedItem?.item?.type?.includes("image/") ? (
              <LazyLoadImage
                src={selectedItem?.item?.url as string}
                alt="pov-image"
                className="w-full h-full object-cover"
              />
            ) : selectedItem?.item?.type?.includes("video/") ? (
              <Video
                autoPlay
                controls
                preload="none"
                url={selectedItem?.item?.url as string}
              />
            ) : (
              <div className="w-full h-full bg-inawo-black-500"></div>
            )}
          </div>
          <div className="w-screen mx-auto absolute bottom-[13px] left-1/2 transform -translate-x-1/2">
            <div className="flex items-center justify-center  flex-nowrap scrollbar-hide overflow-x-auto space-x-4 mx-2 md:mx-6">
              {selectedItem?.group.map((item, i) => (
                <div
                  className={cn(
                    "flex-none relative flex justify-center items-center overflow-hidden",
                    selectedItem?.item?.id === item.id
                      ? "w-[85px] h-[85px] transition-all duration-300 ease-in-out"
                      : "w-[68px] h-[68px] hover:h-[85px] hover:w-[85px] transition-all duration-300 ease-in-out"
                  )}
                  key={i}
                  onClick={() => {
                    setSelectedItem({ item, group: selectedItem?.group });
                  }}
                >
                  {item?.type?.includes("image/") ? (
                    <LazyLoadImage
                      src={item?.url as string}
                      alt="pov-image"
                      className="w-full h-full object-cover"
                    />
                  ) : item?.type?.includes("video/") ? (
                    <Video url={item?.url as string} />
                  ) : (
                    <div className="w-full h-full bg-inawo-black-500"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GalleryModal;
