import { gql } from "@apollo/client";

export const GetEventDetailsWithToken = gql`
  query getEventDetailsWithToken($eventToken: String!) {
    getEventDetailsWithToken(event_token: $eventToken) {
      event {
        name
        id
        welcome_msg
        slug
      }
      invitation_card {
        template
      }
    }
  }
`;

export const GetTeamMember = gql`
  query getTeamMember($eventId: Int!) {
    getTeamMember(event_id: $eventId) {
      event_id
      user_id
      user {
        first_name
        id
        phone_number
        last_name
        verified
        email
        image
      }
      admin
      id
      role
    }
  }
`;

export const CheckEventSlug = gql`
  query checkEventSlug($slug: String!) {
    checkEventSlug(slug: $slug)
  }
`;

export const GetEventStreamers = gql`
  query getEventStreamers($eventId: Int!) {
    getEventStreamers(eventId: $eventId) {
      Id
      event_uuid
      is_streaming
      playback_url
      ingest_endpoint
      stream_key
      deviceName
      viewingUrl
    }
  }
`;

export const GetEventPlan = gql`
  query getEventPlan($eventId: Int!) {
    getEventPlan(event_id: $eventId) {
      website_template
      wallet_pin_set
      send_out_invites
      upload_pictures
      invitation_card
      event_id
      event_details
      build_invite_list
      categories
      add_wishlist
      add_event_vendors
      add_back_story
      add_foreign_account
      add_live_hall
    }
  }
`;

export const GetCustomMyPlanTask = gql`
  query getCustomMyPlanTask($eventId: Int!) {
    getCustomMyPlanTask(event_id: $eventId) {
      id
      event_id
      eventUUID
      name
      description
      done
      updated_at
      created_at
    }
  }
`;

export const GetLiveSchedules = gql`
  query getLiveSchedule($eventUuid: String!, $page: Int, $pageSize: Int) {
    getLiveSchedule(eventUuid: $eventUuid, page: $page, pageSize: $pageSize) {
      data {
        id
        event_uuid
        event_part_uuid
        link
        time
        created_at
        updated_at
        event_part {
          id
          name
          part
        }
      }
      page
      pageCount
      pageSize
      recordCount
    }
  }
`;
