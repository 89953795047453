import { motion } from "framer-motion";
import Dancer from "../../../assets/images/01.png";
import CloseV2 from "src/assets/icons/closeV2";
import RetryIcon from "src/assets/icons/retryIcon";
import { Dispatch, SetStateAction } from "react";

export type UploadValue = { file: File; preview: string }[];

const CollapsedUpload = ({
  collapsed,
  fileUploaded,
  percent,
  totalItems,
  totalUploaded,
  timeLeft,
  isCompleted,
  setRetryUploads,
  onClose,
}: {
  fileUploaded: UploadValue;
  collapsed: boolean;
  percent: number;
  totalItems: number;
  totalUploaded: number;
  timeLeft: number;
  isCompleted: boolean;
  setRetryUploads: () => void;
  onClose: () => void;
}) => {
  return (
    <motion.div
      initial={{ height: 700, opacity: 0 }}
      animate={{
        height: !collapsed ? 0 : "auto",
        opacity: !collapsed ? 0 : 1,
      }}
      transition={{ duration: 0.3 }}
      className="bg-white px-[30px] py-[14px] rounded-[14px] w-full  md:w-[524px]"
    >
      <p className="font-[500] text-[15px] text-[#71717A] mb-[15px]">
        Uploading {totalUploaded} of {totalItems} items
      </p>
      <div className="overflow-hidden flex justify-between items-center">
        <div className="flex gap-[5px] w-full">
          {fileUploaded
            ?.slice(0, 3)
            .map(({ file, preview }: any, idx: number) => (
              <div
                key={idx}
                className="flex relative justify-center items-center overflow-hidden h-[45px] w-[45px] rounded-[9px]"
              >
                {file?.type?.includes("video/") ? (
                  <video preload="none" className="h-full w-full object-cover">
                    <source src={preview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={preview || Dancer}
                    alt={`pov ${idx}`}
                    className="w-full h-full object-cover"
                  />
                )}
                {fileUploaded?.length > 3 && idx === 2 && (
                  <div className="absolute top-0 left-0 w-full h-full bg-[rgba(59,95,89,0.5)] z-[1] grid place-items-center text-lg font-semibold text-white dark:text-black">
                    <span>+{fileUploaded?.length - 3}</span>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className="flex gap-[5px] items-center">
          {/* <div
            className="w-[29px] flex items-center justify-center h-[29px] rounded-[10px] bg-[#F2F4F3] text-[#71717A]"
            role="button"
          >
            <PauseIcon />
          </div> */}
          {percent === 100 && !isCompleted && (
            <div
              className="w-[29px] flex items-center justify-center h-[29px] rounded-[10px] bg-[#F2F4F3] text-[#71717A]"
              role="button"
              onClick={() => {
                setRetryUploads();
              }}
            >
              <RetryIcon className="text-[#FFF1F1]" />
            </div>
          )}
          <div
            className="w-[29px] flex items-center justify-center h-[29px] rounded-[10px] bg-[#FFF1F1] text-[#DC2626]"
            role="button"
            onClick={onClose}
          >
            <CloseV2 className="stroke-[#DC2626]" />
          </div>
        </div>
      </div>
      <div className="bg-[#F2F2F2] rounded-[6px] h-[3px] w-full mt-3 relative">
        <span
          className={`absolute h-full rounded-[6px] bg-[#4E7E76]`}
          style={{
            width: `${percent}%`,
          }}
        />
      </div>
      <p className="text-xs text-[rgba(113,113,122,0.51)] font-[500] dark:text-[rgba(113,113,122,1)] mt-3">
        <span className="text-[#4E7E76]">{timeLeft}sec</span> left
      </p>
    </motion.div>
  );
};

export default CollapsedUpload;
