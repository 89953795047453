export function numberWithCommas(x: number) {
  if (typeof x === "undefined" || isNaN(x)) return;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function convertToKobo(amount: number) {
  return amount * 100;
}

export function divideKobo(amount: number) {
  return amount / 100;
}

export const objectKeyToWord = (key: string) => {
  const res: string[] = [];
  key
    .split("_")
    .forEach((i) => res.push(`${i[0].toUpperCase()}${i.slice(1, i.length)}`));
  return res.join(" ");
};

export const paystackChargeCalculator = (amount: number) => {
  if (!amount) return 0;
  if (amount < 5000) {
    return 10;
  } else if (amount < 50000) {
    return 25;
  } else {
    return 50;
  }
};

export const formatMoney = (n: number) => {
  const res = new Intl.NumberFormat().format(n);
  return res;
};

export const sentenceCase = (i: string) => {
  return i.toLowerCase().charAt(0).toUpperCase() + i.slice(1);
};

export const asyncForEach = async <T,>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => Promise<void>
): Promise<void> => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const getFullName = (
  firstName: string | null = "",
  lastName: string | null = "",
  emptyState?: string
) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  else if (firstName) return firstName;
  else if (lastName) return lastName;
  return emptyState ?? "-";
};

export const getFileDimension = (
  file: File
): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        const { height, width } = image;
        resolve({ height, width });
      };
    };
  });
};
