import { Dispatch } from "react";
import { useMutation } from "@apollo/client";
import PinInput from "react-pin-input";
import { transferToBank, transferToBankVariables } from "src/types/api";
import toast from "react-hot-toast";
import { TransferToBank } from "src/graphql/mutations";
import styles from "./enterPin.module.scss";
import Button from "src/components/Button";
import { useNavigate } from "react-router-dom";

interface WalletPinProps {
  setOpenPin: Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  setPin: Dispatch<React.SetStateAction<string>>;
  setOpenTransferOTP: Dispatch<React.SetStateAction<boolean>>;
  onTransferComplete: () => void;
  openTransferOTP: any;
  amount: string;
  accountNumber: string;
  accountBank: any;
  name: any;
  bankCode: any;
  bankName: any;
  walletPin: string;
  currency: any;
  walletId: string | null | undefined;
}

const WalletPin = ({
  setOpenPin,
  eventId,
  setPin,
  amount,
  accountBank,
  accountNumber,
  name,
  bankCode,
  currency,
  walletId,
  walletPin,
  openTransferOTP,
  setOpenTransferOTP,
  onTransferComplete,
}: WalletPinProps) => {
  const navigate = useNavigate();

  const [transferFn, { loading }] = useMutation<
    transferToBank,
    transferToBankVariables
  >(TransferToBank, {
    onCompleted(res) {
      if (res.transferToBank?.otp_is_required) {
        setOpenTransferOTP(true);
        setOpenPin(false);
      } else if (res.transferToBank?.status) {
        toast.success(
          <p>You transferred {Number(amount).toLocaleString()}.</p>
        );
        onTransferComplete();
        setOpenPin(false);
      } else if (!res.transferToBank?.status) {
        toast.error(<p>Trasnsaction failed.</p>);
        setOpenPin(false);
      }
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["enterPin__main"]}>
      <div className={styles["enterPin__main-container"]}>
        <div className={styles["enterPin__title"]}>
          <h1>Enter PIN</h1>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (walletId) {
              eventId &&
                transferFn({
                  variables: {
                    input: {
                      currency: currency,
                      event_id: eventId,
                      walletId: walletId,
                      amount: Number(amount),
                      accountNumber: accountNumber,
                      accountBank: accountBank,
                      narration: "",
                      bankCode: bankCode,
                      reference: "",
                      walletPin: walletPin,
                      accountName: name,
                    },
                  },
                });
            } else {
              toast.error(<p>There's no wallet for this event.</p>);
            }
          }}
          className={styles["enterPin__field-form"]}
        >
          <PinInput
            length={4}
            focus
            initialValue=""
            secret
            onChange={(value, _index) => {
              setPin(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "12px",
              display: " flex",
              justifyContent: " center",
              alignItems: " center",
              gap: "0.5rem",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
            }}
            inputStyle={{
              borderColor: "#808080",
              width: "17.5%",
              height: "78px",
              fontSize: "2.25rem",
              borderRadius: "2px",
            }}
            inputFocusStyle={{
              borderColor: "#1fad3e",
              caretColor: "#1fad3e",
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <div className={styles["enterPin__button"]}>
            <Button
              type="submit"
              label="Proceed"
              loader={loading}
              disabled={
                loading ||
                accountNumber === "" ||
                amount === "" ||
                name === "" ||
                walletPin.length !== 4
              }
            >
              Proceed
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WalletPin;
