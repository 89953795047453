import { gql } from "@apollo/client";

export const InvitationListBySearch = gql`
  query invitationListBySearch(
    $eventId: Int!
    $searchTerm: String
    $page: Int
    $pageSize: Int
  ) {
    invitationListBySearch(
      event_id: $eventId
      searchTerm: $searchTerm
      page: $page
      pageSize: $pageSize
    ) {
      event_id
      email
      first_name
      id
      last_name
      phone
      category {
        id
        name
      }
      rsvp_status
      title
    }
  }
`;

export const EstimateInvitationCost = gql`
  mutation estimateInvitationCost(
    $eventUuid: String!
    $req: EstimateInvitationCostReq!
  ) {
    estimateInvitationCost(event_id: $eventUuid, req: $req) {
      currency
      usd_rate
      rate
      cost
    }
  }
`;

export const AddGuestToInviteList = gql`
  mutation addGuestToInviteList($eventId: Int!, $guest: AddToInviteListReq!) {
    addGuestToInviteList(event_id: $eventId, guest: $guest)
  }
`;
