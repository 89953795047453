import { useMutation } from "@apollo/client";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { RemoveStreamerFromInviteList } from "src/graphql/mutations/event.mutation";
import { GetEventStreamers } from "src/graphql/queries/event.queries";
import {
  removeStreamerFromInviteList,
  removeStreamerFromInviteListVariables,
} from "src/types/api";
import styles from "./styles.module.scss";
import useAppStore from "src/store/utils";

interface RemoveEventVendorProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: string | undefined;
}

const RemoveStreamer = ({ id, setOpen }: RemoveEventVendorProps) => {
  const navigate = useNavigate();
  const { event } = useAppStore((state) => state);
  const [removeStreamerFn, { loading }] = useMutation<
    removeStreamerFromInviteList,
    removeStreamerFromInviteListVariables
  >(RemoveStreamerFromInviteList, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">You have successfully removed a streamer.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={`${styles["removeStreamer--content"]} ${styles["show"]}`}>
      <h1>Remove Streamer</h1>
      <p>
        You are about to remove this streamer from your event. Do you wish to
        proceed?
      </p>
      <span className={styles["removeStreamer--content-btnContainer"]}>
        <div>
          <Button
            type="submit"
            handleClick={() =>
              id &&
              removeStreamerFn({
                variables: {
                  streamerId: id,
                },
                refetchQueries: [
                  {
                    query: GetEventStreamers,
                    variables: { eventId: event.id },
                  },
                ],
              })
            }
            loader={loading}
            label="Yes, Proceed"
          >
            Yes, Proceed
          </Button>
        </div>
      </span>
    </div>
  );
};

export default RemoveStreamer;
