import React from "react";

const NoGift = () => {
  return (
    <svg
      width="117"
      height="144"
      viewBox="0 0 117 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.8619 59.5956C73.2655 59.4095 69.594 56.2722 67.0629 53.3887C65.8012 51.9513 63.4327 51.9411 62.3501 53.5178L58.1605 59.6191C57.4471 60.658 57.5478 62.054 58.4692 62.9138C61.5014 65.743 67.0411 70.0882 73.2338 72.5147C79.3563 74.9137 84.697 74.6232 87.6404 73.9443C88.6228 73.7177 89.2642 72.8364 89.3527 71.832L90.221 61.979C90.3796 60.1791 88.7487 58.7215 86.9604 58.9791C84.0405 59.3997 80.209 59.7688 76.8619 59.5956Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path
        d="M76.8619 59.5956C73.2655 59.4095 69.594 56.2722 67.0629 53.3887C65.8012 51.9513 63.4327 51.9411 62.3501 53.5178L58.1605 59.6191C57.4471 60.658 57.5478 62.054 58.4692 62.9138C61.5014 65.743 67.0411 70.0882 73.2338 72.5147C79.3563 74.9137 84.697 74.6232 87.6404 73.9443C88.6228 73.7177 89.2642 72.8364 89.3527 71.832L90.221 61.979C90.3796 60.1791 88.7487 58.7215 86.9604 58.9791C84.0405 59.3997 80.209 59.7688 76.8619 59.5956Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g opacity="0.3">
        <path
          d="M76.524 60.9628C72.9276 60.7767 69.2561 57.6394 66.725 54.7559C65.4633 53.3185 63.0948 53.3083 62.0122 54.8849L57.8226 60.9863C57.1093 62.0252 57.2099 63.4212 58.1313 64.281C61.1636 67.1102 66.7032 71.4554 72.8959 73.8819C79.0184 76.2809 84.3591 75.9903 87.3025 75.3115C88.2849 75.0849 88.9263 74.2036 89.0148 73.1992L89.8831 63.3461C90.0417 61.5463 88.4108 60.0886 86.6225 60.3463C83.7026 60.7669 79.8711 61.136 76.524 60.9628Z"
          fill="url(#paint1_linear_0_1)"
        />
        <path
          d="M76.524 60.9628C72.9276 60.7767 69.2561 57.6394 66.725 54.7559C65.4633 53.3185 63.0948 53.3083 62.0122 54.8849L57.8226 60.9863C57.1093 62.0252 57.2099 63.4212 58.1313 64.281C61.1636 67.1102 66.7032 71.4554 72.8959 73.8819C79.0184 76.2809 84.3591 75.9903 87.3025 75.3115C88.2849 75.0849 88.9263 74.2036 89.0148 73.1992L89.8831 63.3461C90.0417 61.5463 88.4108 60.0886 86.6225 60.3463C83.7026 60.7669 79.8711 61.136 76.524 60.9628Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <ellipse
        cx="73.9992"
        cy="66.06"
        rx="3.13493"
        ry="2.29895"
        transform="rotate(-5.16808 73.9992 66.06)"
        fill="#DFEBEA"
        fillOpacity="0.48"
      />
      <rect x="21" y="53" width="21" height="20" rx="5.53" fill="#B0CECA" />
      <g opacity="0.9">
        <ellipse
          cx="44.6029"
          cy="70.2836"
          rx="10.9711"
          ry="11.393"
          fill="url(#paint2_linear_0_1)"
        />
        <ellipse
          cx="44.6029"
          cy="70.2836"
          rx="10.9711"
          ry="11.393"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <rect
        x="42.54"
        y="78.1738"
        width="29.5374"
        height="65.8263"
        fill="url(#paint3_linear_0_1)"
      />
      <rect
        x="42.54"
        y="78.1738"
        width="29.5374"
        height="65.8263"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M108.683 78.4902V133.082C108.683 135.977 107.533 138.755 105.486 140.802C103.438 142.85 100.661 144 97.7652 144H65.0103V78.4902H108.683ZM17.6976 144C14.8019 144 12.0248 142.85 9.97719 140.802C7.92962 138.755 6.7793 135.977 6.7793 133.082V78.4902H50.4525V144H17.6976Z"
        fill="#4E7E77"
      />
      <path
        d="M22.3366 19.0761C22.3366 14.2499 24.2538 9.62137 27.6664 6.20873C31.0791 2.7961 35.7076 0.878906 40.5338 0.878906C45.36 0.878906 49.9885 2.7961 53.4011 6.20873C56.8138 9.62137 58.731 14.2499 58.731 19.0761C58.731 14.2499 60.6482 9.62137 64.0608 6.20873C67.4734 2.7961 72.1019 0.878906 76.9281 0.878906C81.7543 0.878906 86.3828 2.7961 89.7955 6.20873C93.2081 9.62137 95.1253 14.2499 95.1253 19.0761V19.1198C95.1253 19.6293 95.1253 21.085 94.8487 22.7155H109.683C111.614 22.7155 113.465 23.4824 114.83 24.8474C116.195 26.2125 116.962 28.0639 116.962 29.9944V37.2733C116.962 39.2037 116.195 41.0551 114.83 42.4202C113.465 43.7852 111.614 44.5521 109.683 44.5521H7.77887C5.84839 44.5521 3.99698 43.7852 2.63193 42.4202C1.26688 41.0551 0.5 39.2037 0.5 37.2733V29.9944C0.5 28.0639 1.26688 26.2125 2.63193 24.8474C3.99698 23.4824 5.84839 22.7155 7.77887 22.7155H22.6132C22.421 21.5267 22.3284 20.324 22.3366 19.1198V19.0761ZM30.1104 22.7155H51.4521V19.0761C51.4521 17.6423 51.1697 16.2225 50.621 14.8978C50.0723 13.5732 49.2681 12.3695 48.2542 11.3557C47.2403 10.3418 46.0367 9.53758 44.712 8.98888C43.3874 8.44019 41.9676 8.15777 40.5338 8.15777C39.1 8.15777 37.6802 8.44019 36.3555 8.98888C35.0309 9.53758 33.8272 10.3418 32.8134 11.3557C31.7995 12.3695 30.9953 13.5732 30.4466 14.8978C29.8979 16.2225 29.6155 17.6423 29.6155 19.0761C29.6155 19.6948 29.63 21.0705 29.943 22.206C29.9868 22.3796 30.0427 22.5498 30.1104 22.7155ZM66.0098 22.7155H87.3515C87.4184 22.5495 87.4743 22.3793 87.5189 22.206C87.8319 21.0705 87.8464 19.6948 87.8464 19.0761C87.8464 16.1804 86.6961 13.4033 84.6485 11.3557C82.601 9.30809 79.8238 8.15778 76.9281 8.15778C74.0324 8.15778 71.2553 9.30809 69.2077 11.3557C67.1601 13.4033 66.0098 16.1804 66.0098 19.0761V22.7155Z"
        fill="url(#paint4_linear_0_1)"
      />
      <path
        d="M22.3366 19.0761C22.3366 14.2499 24.2538 9.62137 27.6664 6.20873C31.0791 2.7961 35.7076 0.878906 40.5338 0.878906C45.36 0.878906 49.9885 2.7961 53.4011 6.20873C56.8138 9.62137 58.731 14.2499 58.731 19.0761C58.731 14.2499 60.6482 9.62137 64.0608 6.20873C67.4734 2.7961 72.1019 0.878906 76.9281 0.878906C81.7543 0.878906 86.3828 2.7961 89.7955 6.20873C93.2081 9.62137 95.1253 14.2499 95.1253 19.0761V19.1198C95.1253 19.6293 95.1253 21.085 94.8487 22.7155H109.683C111.614 22.7155 113.465 23.4824 114.83 24.8474C116.195 26.2125 116.962 28.0639 116.962 29.9944V37.2733C116.962 39.2037 116.195 41.0551 114.83 42.4202C113.465 43.7852 111.614 44.5521 109.683 44.5521H7.77887C5.84839 44.5521 3.99698 43.7852 2.63193 42.4202C1.26688 41.0551 0.5 39.2037 0.5 37.2733V29.9944C0.5 28.0639 1.26688 26.2125 2.63193 24.8474C3.99698 23.4824 5.84839 22.7155 7.77887 22.7155H22.6132C22.421 21.5267 22.3284 20.324 22.3366 19.1198V19.0761ZM30.1104 22.7155H51.4521V19.0761C51.4521 17.6423 51.1697 16.2225 50.621 14.8978C50.0723 13.5732 49.2681 12.3695 48.2542 11.3557C47.2403 10.3418 46.0367 9.53758 44.712 8.98888C43.3874 8.44019 41.9676 8.15777 40.5338 8.15777C39.1 8.15777 37.6802 8.44019 36.3555 8.98888C35.0309 9.53758 33.8272 10.3418 32.8134 11.3557C31.7995 12.3695 30.9953 13.5732 30.4466 14.8978C29.8979 16.2225 29.6155 17.6423 29.6155 19.0761C29.6155 19.6948 29.63 21.0705 29.943 22.206C29.9868 22.3796 30.0427 22.5498 30.1104 22.7155ZM66.0098 22.7155H87.3515C87.4184 22.5495 87.4743 22.3793 87.5189 22.206C87.8319 21.0705 87.8464 19.6948 87.8464 19.0761C87.8464 16.1804 86.6961 13.4033 84.6485 11.3557C82.601 9.30809 79.8238 8.15778 76.9281 8.15778C74.0324 8.15778 71.2553 9.30809 69.2077 11.3557C67.1601 13.4033 66.0098 16.1804 66.0098 19.0761V22.7155Z"
        fill="black"
        fillOpacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="62.9015"
          y1="50.3658"
          x2="87.892"
          y2="80.6171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="62.5636"
          y1="51.733"
          x2="87.5541"
          y2="81.9843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_1"
          x1="38.14"
          y1="58.8906"
          x2="59.0184"
          y2="81.4154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_1"
          x1="48.6087"
          y1="78.1738"
          x2="97.1575"
          y2="102.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_1"
          x1="24.4278"
          y1="0.878906"
          x2="48.5871"
          y2="73.057"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoGift;
