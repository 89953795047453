const NoVendor = () => {
  return (
    <svg
      width="141"
      height="111"
      viewBox="0 0 141 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="68.9234" cy="21.4663" r="21.0786" fill="#619D94" />
      <circle cx="22.982" cy="34.8306" r="12.2222" fill="#619D94" />
      <path
        d="M37.186 51.072C37.186 48.0335 39.6492 45.5703 42.6878 45.5703H97.1548C100.193 45.5703 102.656 48.0335 102.656 51.072V77.7554C102.656 95.8345 88.0004 110.491 69.9213 110.491C51.8421 110.491 37.186 95.8345 37.186 77.7554V51.072Z"
        fill="url(#paint0_linear_713_10801)"
      />
      <path
        d="M37.186 51.072C37.186 48.0335 39.6492 45.5703 42.6878 45.5703H97.1548C100.193 45.5703 102.656 48.0335 102.656 51.072V77.7554C102.656 95.8345 88.0004 110.491 69.9213 110.491C51.8421 110.491 37.186 95.8345 37.186 77.7554V51.072Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M46.5386 59.1468C46.5386 53.6653 42.095 49.2217 36.6135 49.2217H10.2492C4.76774 49.2217 0.324135 53.6653 0.324135 59.1468V83.8825C0.324135 96.6443 10.6696 106.99 23.4314 106.99C36.1931 106.99 46.5386 96.6443 46.5386 83.8825V59.1468Z"
        fill="url(#paint1_linear_713_10801)"
      />
      <path
        d="M46.5386 59.1468C46.5386 53.6653 42.095 49.2217 36.6135 49.2217H10.2492C4.76774 49.2217 0.324135 53.6653 0.324135 59.1468V83.8825C0.324135 96.6443 10.6696 106.99 23.4314 106.99C36.1931 106.99 46.5386 96.6443 46.5386 83.8825V59.1468Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M93.8535 59.1468C93.8535 53.6653 98.2971 49.2217 103.779 49.2217H130.143C135.624 49.2217 140.068 53.6653 140.068 59.1468V83.8825C140.068 96.6443 129.722 106.99 116.961 106.99C104.199 106.99 93.8535 96.6443 93.8535 83.8825V59.1468Z"
        fill="url(#paint2_linear_713_10801)"
      />
      <path
        d="M93.8535 59.1468C93.8535 53.6653 98.2971 49.2217 103.779 49.2217H130.143C135.624 49.2217 140.068 53.6653 140.068 59.1468V83.8825C140.068 96.6443 129.722 106.99 116.961 106.99C104.199 106.99 93.8535 96.6443 93.8535 83.8825V59.1468Z"
        fill="black"
        fillOpacity="0.1"
      />
      <circle cx="118.982" cy="34.8306" r="12.2222" fill="#619D94" />
      <defs>
        <linearGradient
          id="paint0_linear_713_10801"
          x1="50.6374"
          y1="45.5703"
          x2="109.852"
          y2="112.474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_713_10801"
          x1="37.0435"
          y1="49.2217"
          x2="-15.7241"
          y2="96.5162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_713_10801"
          x1="103.349"
          y1="49.2217"
          x2="156.116"
          y2="96.5162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoVendor;
