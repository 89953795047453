import React from "react";

const NoBackStory = () => {
  return (
    <svg
      width="200"
      height="85"
      viewBox="0 0 200 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_bi_790_12526)">
        <circle cx="165" cy="50" r="35" fill="#51837C" />
        <circle
          cx="165"
          cy="50"
          r="34.5977"
          stroke="url(#paint0_linear_790_12526)"
          strokeOpacity="0.5"
          strokeWidth="0.804598"
        />
      </g>
      <path
        d="M170.393 47.4826C170.308 47.3 169.982 46.6273 169.191 46.2413C168.798 46.0536 168.362 45.9632 167.924 45.9782C166.554 45.9968 165.62 46.9555 165.403 47.1899C165.186 46.9555 164.252 45.9985 162.882 45.9782C162.443 45.9632 162.008 46.0536 161.615 46.2413C160.824 46.6277 160.497 47.3 160.412 47.4826C160.245 47.8475 160.164 48.2433 160.174 48.6418C160.174 48.7822 160.186 49.3551 160.561 50.105C160.944 50.872 161.486 51.3351 161.856 51.6829C162.761 52.5278 163.771 53.2633 164.857 53.8791C165.194 54.0703 165.612 54.0703 165.949 53.8791C167.034 53.2633 168.045 52.5278 168.949 51.6829C169.317 51.3357 169.861 50.8727 170.245 50.105C170.619 49.3551 170.631 48.7822 170.632 48.6418C170.642 48.2432 170.56 47.8475 170.393 47.4826Z"
        fill="white"
      />
      <path
        d="M187.289 47.4826C187.204 47.3 186.877 46.6273 186.086 46.2413C185.693 46.0536 185.258 45.9632 184.819 45.9782C183.449 45.9968 182.515 46.9555 182.298 47.1899C182.082 46.9555 181.147 45.9985 179.777 45.9782C179.339 45.9632 178.903 46.0536 178.51 46.2413C177.719 46.6277 177.393 47.3 177.308 47.4826C177.141 47.8475 177.059 48.2433 177.069 48.6418C177.069 48.7822 177.082 49.3551 177.457 50.105C177.84 50.872 178.382 51.3351 178.752 51.6829C179.656 52.5278 180.667 53.2633 181.752 53.8791C182.089 54.0703 182.507 54.0703 182.844 53.8791C183.93 53.2633 184.94 52.5278 185.845 51.6829C186.213 51.3357 186.757 50.8727 187.14 50.105C187.515 49.3551 187.527 48.7822 187.527 48.6418C187.537 48.2432 187.456 47.8475 187.289 47.4826Z"
        fill="white"
      />
      <path
        d="M153.505 47.4826C153.423 47.3 153.11 46.6273 152.349 46.2413C151.971 46.0536 151.552 45.9632 151.131 45.9782C149.813 45.9968 148.915 46.9555 148.706 47.1899C148.498 46.9555 147.6 45.9985 146.282 45.9782C145.861 45.9632 145.442 46.0536 145.064 46.2413C144.303 46.6277 143.99 47.3 143.908 47.4826C143.747 47.8475 143.669 48.2433 143.678 48.6418C143.678 48.7822 143.69 49.3551 144.051 50.105C144.42 50.872 144.941 51.3351 145.297 51.6829C146.166 52.5278 147.138 53.2633 148.181 53.8791C148.506 54.0703 148.907 54.0703 149.231 53.8791C150.275 53.2633 151.247 52.5278 152.116 51.6829C152.471 51.3357 152.993 50.8727 153.362 50.105C153.723 49.3551 153.734 48.7822 153.734 48.6418C153.744 48.2432 153.666 47.8475 153.505 47.4826Z"
        fill="white"
      />
      <path
        d="M145.358 44.5711L124.649 69.1047C120.625 73.943 114.656 76.7411 108.362 76.7411C102.067 76.7411 96.0991 73.943 92.073 69.1047L72.2399 45.2091C68.6877 40.9489 66.8794 35.5005 67.1736 29.9623C67.4696 24.4221 69.8478 19.1994 73.8341 15.3412C78.1464 11.367 83.9206 9.37503 89.7629 9.84507C95.6313 10.2731 101.044 13.1673 104.658 17.8097L108.8 22.748L112.304 18.5258C115.751 14.1795 120.717 11.3015 126.202 10.473C131.686 9.64697 137.28 10.9291 141.854 14.0652C146.369 17.6495 149.241 22.908 149.819 28.6423C150.571 34.3387 148.959 40.0949 145.359 44.5711H145.358Z"
        fill="url(#paint1_linear_790_12526)"
      />
      <path
        d="M78.2156 34.7938L57.5064 59.3273C53.4822 64.1656 47.5136 66.9638 41.2192 66.9638C34.9247 66.9638 28.9565 64.1656 24.9304 59.3273L5.0973 35.4318C1.54507 31.1715 -0.26317 25.7231 0.0309927 20.185C0.327009 14.6448 2.70518 9.42205 6.69151 5.56389C11.0038 1.58964 16.778 -0.402317 22.6203 0.0677291C28.4888 0.495758 33.9009 3.38995 37.5154 8.0324L41.6576 12.9707L45.1619 8.74842C48.6081 4.40214 53.5746 1.52415 59.0589 0.695686C64.5433 -0.130376 70.1378 1.15172 74.7118 4.2879C79.2261 7.87213 82.0984 13.1307 82.6765 18.865C83.4285 24.5613 81.8164 30.3176 78.2162 34.7938H78.2156Z"
        fill="url(#paint2_linear_790_12526)"
      />
      <path
        d="M78.2156 34.7938L57.5064 59.3273C53.4822 64.1656 47.5136 66.9638 41.2192 66.9638C34.9247 66.9638 28.9565 64.1656 24.9304 59.3273L5.0973 35.4318C1.54507 31.1715 -0.26317 25.7231 0.0309927 20.185C0.327009 14.6448 2.70518 9.42205 6.69151 5.56389C11.0038 1.58964 16.778 -0.402317 22.6203 0.0677291C28.4888 0.495758 33.9009 3.38995 37.5154 8.0324L41.6576 12.9707L45.1619 8.74842C48.6081 4.40214 53.5746 1.52415 59.0589 0.695686C64.5433 -0.130376 70.1378 1.15172 74.7118 4.2879C79.2261 7.87213 82.0984 13.1307 82.6765 18.865C83.4285 24.5613 81.8164 30.3176 78.2162 34.7938H78.2156Z"
        fill="black"
        fillOpacity="0.1"
      />
      <defs>
        <filter
          id="filter0_bi_790_12526"
          x="113.908"
          y="-1.09196"
          width="102.184"
          height="102.184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.04598" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_790_12526"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_790_12526"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4.02299" dy="4.02299" />
          <feGaussianBlur stdDeviation="4.02299" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_790_12526"
          />
        </filter>
        <linearGradient
          id="paint0_linear_790_12526"
          x1="139.253"
          y1="24.2529"
          x2="190.345"
          y2="75.3448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_790_12526"
          x1="108.571"
          y1="9.77734"
          x2="152.909"
          y2="49.6012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37645D" />
          <stop offset="1" stopColor="#1F5C53" stopOpacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_790_12526"
          x1="17.0236"
          y1="1.87396e-07"
          x2="75.4158"
          y2="80.9469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoBackStory;
