import { Dispatch, SetStateAction, useState } from "react";
import { Formik, Form, FormikProps, Field } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import InputPhone from "src/components/InputPhone";
import Button from "src/components/Button";
import styles from "./styles.module.scss";
import { InvitationList, NumberOfGuestsByCategory } from "src/graphql/queries";
import { v4 as uuidv4 } from "uuid";
import {
  addToInviteList,
  noGuestPerCategory,
  noGuestPerCategory_noGuestPerCategory,
} from "src/types/api";
import { AddToInviteList } from "src/graphql/mutations";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "src/utils";
import useAppStore from "src/store/utils";

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  selectCategory: string;
}

const AddGuestFormSchema = Yup.object().shape({
  firstName: Yup.string().required("Please fill in the first name"),
  lastName: Yup.string().required("Please fill in the last name"),
  email: Yup.string().email("Please enter a correct email address"),
});

interface AddGuestProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  categories: noGuestPerCategory | null | undefined;
  category?: noGuestPerCategory_noGuestPerCategory | null | undefined;
  page: number;
  pageSize: number;
}

const AddGuest = ({
  setOpen,
  categories,
  category,
  page,
  pageSize,
}: AddGuestProps) => {
  const { event } = useAppStore((state) => state);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const navigate = useNavigate();

  const [addToInviteListFn, { loading }] = useMutation<addToInviteList>(
    AddToInviteList,
    {
      onCompleted() {
        toast.success(
          <p className="toast">
            You have successfully added a guest to the invite list.
          </p>
        );
        setOpen(false);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  return (
    <div className={`${styles["addGuestModal--content"]} ${styles["show"]}`}>
      <h1> Add Guest </h1>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          selectCategory: category ? category.category_name : "",
        }}
        validationSchema={AddGuestFormSchema}
        onSubmit={(values) => {
          const element = categories?.noGuestPerCategory.find(
            (element) => element?.category_name === values.selectCategory
          );

          addToInviteListFn({
            variables: {
              eventId: event.id,
              invite_list: [
                {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  phone: formatPhoneNumber(phoneNumberValue),
                  email: values.email,
                  category_id: element?.category_id,
                },
              ],
            },
            refetchQueries: [
              {
                query: InvitationList,
                variables: {
                  eventId: event.id,
                  page: page,
                  pageSize: pageSize,
                },
              },
              {
                query: NumberOfGuestsByCategory,
                variables: { eventId: event.id },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["addGuestModal--content-form"]}>
            <div className={styles["addGuestModal--content-form_container"]}>
              <Input
                type="text"
                name="firstName"
                label="First Name"
                placeholder="Enter First Name"
              />
              <Input
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Enter Last Name"
              />
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="Enter Email"
              />
              <InputPhone
                name="phoneNumber"
                label="WhatsApp Number"
                placeholder="Enter WhatsApp Number"
                value={phoneNumberValue}
                setValue={setPhoneNumberValue}
              />
              <div className={styles["addGuestModal__select"]}>
                <label htmlFor="selectCategory">Select a Category </label>
                <Field
                  as="select"
                  name="selectCategory"
                  className={styles["select-css"]}
                  onChange={(e: any) =>
                    props.setFieldValue("selectCategory", e.target.value)
                  }
                >
                  <option disabled hidden value="">
                    Select Category
                  </option>
                  {categories?.noGuestPerCategory.map((element, _index) => {
                    return (
                      <option key={uuidv4()} value={element?.category_name}>
                        {element?.category_name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
            <div className={styles["addGuestModal--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Guest"
                  disabled={
                    props.values.firstName === "" ||
                    props.values.lastName === "" ||
                    phoneNumberValue.length < 6 ||
                    !props.isValid ||
                    props.isSubmitting ||
                    props.values.selectCategory === ""
                  }
                  loader={loading}
                >
                  Add Guests
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddGuest;
