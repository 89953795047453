import React from "react";

const NoRSVPPending = () => {
  return (
    <svg
      width="132"
      height="132"
      viewBox="0 0 132 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M109.609 112.838H22.0116C15.9893 112.838 11.062 107.911 11.062 101.889V44.6767C11.062 41.1181 12.7045 37.8332 15.7156 35.6433L65.8101 0.604492L115.905 35.6433C118.916 37.5595 120.558 41.1181 120.558 44.6767V101.889C120.558 107.911 115.631 112.838 109.609 112.838Z"
        fill="url(#paint0_linear_713_10595)"
      />
      <path
        d="M32.7729 31.7986C32.7729 27.1 36.5819 23.291 41.2806 23.291H89.9631C94.6617 23.291 98.4707 27.1 98.4707 31.7986V85.6803C98.4707 90.3789 94.6617 94.1879 89.9631 94.1879H41.2806C36.5819 94.1879 32.7729 90.3789 32.7729 85.6803V31.7986Z"
        fill="url(#paint1_linear_713_10595)"
      />
      <path
        d="M42.6982 38.416C41.9151 38.416 41.2803 37.7812 41.2803 36.9981L41.2803 36.5254C41.2803 35.7423 41.9151 35.1075 42.6982 35.1075L75.7834 35.1075C76.5665 35.1075 77.2013 35.7423 77.2013 36.5254L77.2013 36.9981C77.2013 37.7812 76.5665 38.416 75.7834 38.416L42.6982 38.416Z"
        fill="url(#paint2_linear_713_10595)"
      />
      <path
        d="M42.6982 38.416C41.9151 38.416 41.2803 37.7812 41.2803 36.9981L41.2803 36.5254C41.2803 35.7423 41.9151 35.1075 42.6982 35.1075L75.7834 35.1075C76.5665 35.1075 77.2013 35.7423 77.2013 36.5254L77.2013 36.9981C77.2013 37.7812 76.5665 38.416 75.7834 38.416L42.6982 38.416Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M49.3154 43.1425C49.3154 42.3594 49.9503 41.7246 50.7334 41.7246H83.8186C84.6017 41.7246 85.2365 42.3594 85.2365 43.1425V43.6152C85.2365 44.3983 84.6017 45.0331 83.8186 45.0331H50.7334C49.9503 45.0331 49.3154 44.3983 49.3154 43.6152V43.1425Z"
        fill="url(#paint3_linear_713_10595)"
      />
      <path
        d="M49.3154 43.1425C49.3154 42.3594 49.9503 41.7246 50.7334 41.7246H83.8186C84.6017 41.7246 85.2365 42.3594 85.2365 43.1425V43.6152C85.2365 44.3983 84.6017 45.0331 83.8186 45.0331H50.7334C49.9503 45.0331 49.3154 44.3983 49.3154 43.6152V43.1425Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M109.609 112.837H22.0116C15.9893 112.837 11.062 107.91 11.062 101.888V47.1396L65.8101 82.7259L120.558 47.1396V101.888C120.558 107.91 115.631 112.837 109.609 112.837Z"
        fill="url(#paint4_linear_713_10595)"
      />
      <path
        d="M109.609 112.837H22.0116C15.9893 112.837 11.062 107.91 11.062 101.888V47.1396L65.8101 82.7259L120.558 47.1396V101.888C120.558 107.91 115.631 112.837 109.609 112.837Z"
        fill="black"
        fillOpacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_713_10595"
          x1="33.5587"
          y1="0.604493"
          x2="136.286"
          y2="112.888"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#446762" stopOpacity="0.29" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_713_10595"
          x1="65.6218"
          y1="23.291"
          x2="65.6218"
          y2="94.1879"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0D8D4" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_713_10595"
          x1="41.2803"
          y1="37.7363"
          x2="41.9758"
          y2="30.9957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stop-color="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_713_10595"
          x1="56.6956"
          y1="41.7246"
          x2="57.1922"
          y2="47.7643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stop-color="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_713_10595"
          x1="33.5587"
          y1="47.1396"
          x2="83.8095"
          y2="140.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoRSVPPending;
