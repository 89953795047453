import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Button from "src/components/Button";
import { useMutation, useQuery } from "@apollo/client";
import {
  addGuestToInviteList,
  addGuestToInviteListVariables,
  filterInvitationListBy,
  filterInvitationListByVariables,
  invitationListFilter,
  noGuestPerCategory,
  updateInviteList,
} from "src/types/api.d";
import {
  FilterInvitationList,
  NumberOfGuestsByCategory,
} from "src/graphql/queries";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.min.css";
import { UpdateInviteList } from "src/graphql/mutations";
import InnerLoading from "src/components/InnerLoading";
import Icon from "src/components/Icon";
import parsePhoneNumberFromString from "libphonenumber-js";
import "./styles.css";
import Dialog from "src/components/Dialog";
import SendInvitesOptionsModal from "./modals/SendInvite";
import { AddGuestToInviteList } from "src/graphql/mutations/invitation.mutation";
import useAppStore from "src/store/utils";

// register Handsontable's modules
registerAllModules();

interface TableData {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  category_id?: string;
}

interface AddGuestsSpreadsheetProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddGuestsSpreadsheet = ({ setOpen }: AddGuestsSpreadsheetProps) => {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const hotRef = useRef<any>(null);
  const [data, setData] = useState<TableData[]>([]);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [, setNoOfRows] = useState<number>(0);

  //categories data
  const { data: categories } = useQuery<noGuestPerCategory>(
    NumberOfGuestsByCategory,
    {
      variables: {
        eventId: event.id,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const [updateInviteListFn] = useMutation<updateInviteList>(UpdateInviteList, {
    onCompleted() {
      toast.success(
        <p className="toast">
          You have successfully updated the guest information.
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const categoryNames: string[] =
    categories?.noGuestPerCategory?.map(
      (category) => category?.category_name ?? ""
    ) ?? [];

  const getCategoryIdByName = (categoryName: string | undefined) => {
    const category = categories?.noGuestPerCategory?.find(
      (item) => item?.category_name === categoryName
    );

    return category?.category_id || null;
  };

  //cell validators
  const phoneValidator = (
    value: string,
    callback: (isValid: boolean, errorMessage?: string) => void
  ): void => {
    setTimeout(() => {
      const phoneNumber = parsePhoneNumberFromString(value);

      if (phoneNumber && phoneNumber.isValid()) {
        callback(true);
      } else {
        callback(false);
        toast.error("Phone number should be in this format: +234801928382");
      }
    }, 1000);
  };

  const emailValidator = (
    value: string | null | undefined,
    callback: (isValid: boolean, errorMessage?: string) => void
  ): void => {
    setTimeout(() => {
      if (value && /.+@.+/.test(value)) {
        callback(true);
      } else {
        callback(false);
        toast.error("Please enter a valid email");
      }
    }, 1000);
  };

  //table columns
  const columns = [
    { data: "first_name", title: "First Name", type: "text" },
    { data: "last_name", title: "Last Name", type: "text" },
    {
      data: "email",
      title: "Email (Optional)",
      type: "text",
      validator: emailValidator,
      allowInvalid: true,
    },
    {
      data: "phone",
      title: "Phone Number",
      type: "text",
      validator: phoneValidator,
      allowInvalid: true,
    },
    {
      data: "category_id",
      type: "dropdown",
      title: "Category",
      source: categoryNames ?? [],
    },
  ];

  //invite list data
  const {
    data: inviteList,
    loading: inviteListLoader,
    refetch,
  } = useQuery<filterInvitationListBy, filterInvitationListByVariables>(
    FilterInvitationList,
    {
      variables: {
        eventId: event.id,
        filter: invitationListFilter.not_sent,
        page: 1,
        pageSize: 1000,
      },
      skip: !event.id,
      fetchPolicy: "network-only",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  //invitation ids
  const invitationIDs =
    inviteList?.filterInvitationListBy?.map((item) => item?.uuid ?? "") ?? [];

  //add data to invite list
  const [addToInviteListFn] = useMutation<
    addGuestToInviteList,
    addGuestToInviteListVariables
  >(AddGuestToInviteList, {
    onCompleted() {
      refetch();
      toast.success(
        <p className="toast">
          You have successfully added a guest to the invite list.
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">
            {
              "This guest cannot be added, please make sure the details have not been entered for another guest"
            }
          </p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  //fetch data for handsonTable
  useEffect(() => {
    if (inviteList && Array.isArray(inviteList.filterInvitationListBy)) {
      const updatedData = inviteList.filterInvitationListBy.map((invite) => {
        if (!invite) return {};
        return {
          first_name: invite.first_name ?? "",
          last_name: invite.last_name ?? "",
          email: invite.email ?? "",
          phone: invite.phone ?? "",
          category_id: invite.category?.name ?? "",
        };
      });

      setData(updatedData);
    }
  }, [event.id, inviteList]);

  const hotSettings: Handsontable.GridSettings = {
    data: data,
    columns: columns,
    stretchH: "all",
    minRows: 20,
    height: "auto",
    rowHeaders: true,
    colHeaders: true,
    contextMenu: true,
    afterSelection: (r: number, c: number) => {
      setSelectedRowIndex(r);
    },
    afterChange: (changes, source) => {
      if (source === "edit" && changes && selectedRowIndex !== null) {
        const updatedData = data.map((rowData, index) => {
          const changesForIndex = changes.filter(([row]) => row === index);
          if (changesForIndex.length > 0) {
            const updatedRowData = changesForIndex.reduce(
              (acc, [, prop, , newValue]) => {
                return { ...acc, [prop as string]: newValue };
              },
              rowData
            );

            const updatedRowFilled: {
              category_id: number | null;
              first_name?: string | undefined;
              last_name?: string | undefined;
              email?: string | undefined;
              phone?: string | undefined;
              [key: string]: number | null | string | undefined;
            } = {
              ...updatedRowData,
              category_id: getCategoryIdByName(updatedRowData?.category_id),
            };

            const requiredFieldsFilled = [
              "first_name",
              "last_name",
              "phone",
              "category_id",
            ].every(
              (field) =>
                updatedRowFilled[field] !== undefined &&
                updatedRowFilled[field] !== null &&
                updatedRowFilled[field] !== ""
            );

            const isExistingRow =
              inviteList?.filterInvitationListBy?.[index]?.id;

            //update existing invite
            if (isExistingRow !== null && isExistingRow !== undefined) {
              const updatedRowFilledWithId = {
                ...updatedRowFilled,
                id: isExistingRow || 0,
              };

              updateInviteListFn({
                variables: {
                  eventId: event.id,
                  invite_list: [updatedRowFilledWithId],
                },
              });
            }

            //add invite to invite list only when row is filled and it is not an existing row
            if (
              requiredFieldsFilled &&
              !isExistingRow &&
              selectedRowIndex !== null
            ) {
              addToInviteListFn({
                variables: {
                  eventId: event.id,
                  guest:
                    updatedRowFilled as addGuestToInviteListVariables["guest"],
                },
                // refetchQueries: [
                //   {
                //     query: FilterInvitationList,
                //     variables: {
                //       eventId: eventId,
                //       page: 1,
                //       pageSize: 1000,
                //       filter: invitationListFilter.not_sent,
                //     },
                //   },
                //   {
                //     query: NumberOfGuestsByCategory,
                //     variables: { eventId: eventId },
                //   },
                // ],
              });
            }

            return updatedRowData;
          }

          return rowData;
        });

        setData(updatedData);
      }
    },
  };

  const addEmptyRows = () => {
    const newRows = Array.from({ length: 10 }, (_, index) => {
      const newId = data.length + index + 1;
      return {
        id: newId,
        category_id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
      };
    });

    setData((prevData) => [...prevData, ...newRows]);
    setNoOfRows((prev) => prev + 10);
  };

  return (
    <div className="pb-8">
      {categories && categories?.noGuestPerCategory?.length === 0 ? (
        <div className="flex justify-center items-center w-full h-screen flex-col gap-4">
          <h1 className="text-xl font-bold">
            You currently have no category added, please add categories
          </h1>
          <div>
            <Button
              label="add row"
              type="button"
              design="primary"
              handleClick={() => navigate(`/invitations?tab=categories`)}
              className=" flex items-center gap-[0.7rem] !text-white"
            >
              Add category
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-between mb-5">
            <div
              className="flex hover:bg-[#f2f2f2] transition-all duration-300 ease-out rounded-md cursor-pointer items-center gap-2 pointer py-2 px-3"
              onClick={() => setOpen(false)}
            >
              <div>
                <Icon iconName="backArrow" />
              </div>
              <span>Go Back</span>
            </div>
            <div>
              <Dialog
                open={openInviteModal}
                onOpenChange={setOpenInviteModal}
                trigger={
                  <Button
                    label="Send invites"
                    type="button"
                    design="primary"
                    className=" flex items-center gap-[0.7rem] !text-white"
                  >
                    Send Invites
                  </Button>
                }
              >
                <SendInvitesOptionsModal
                  invitationFilter={invitationListFilter.not_sent}
                  noOfGuests={inviteList?.filterInvitationListBy?.length ?? 0}
                  invitationIDs={invitationIDs}
                  setOpenInviteModal={setOpenInviteModal}
                />
              </Dialog>
            </div>
          </div>
          <div>
            {inviteListLoader ? (
              <div>
                <InnerLoading />
              </div>
            ) : (
              <>
                <HotTable
                  ref={hotRef}
                  settings={hotSettings}
                  licenseKey="non-commercial-and-evaluation"
                  autoRowSize={false}
                  autoColumnSize={false}
                />
                <div className="flex justify-start mt-4">
                  <div>
                    <Button
                      label="add row"
                      type="button"
                      design="primary"
                      handleClick={addEmptyRows}
                      className=" flex items-center gap-[0.7rem] !text-white"
                    >
                      <Icon iconName="add" />
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddGuestsSpreadsheet;
