import styles from "./uploadCard.module.scss";
import { Formik, Form, FormikProps } from "formik";
import Button from "src/components/Button";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { UpdateInvitationCard } from "src/graphql/mutations";
import { useMutation } from "@apollo/client";
import { InvitationCardTemplate } from "src/graphql/queries";
import toast from "react-hot-toast";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";

export interface Values {
  file: string;
}

const UploadCardSchema = Yup.object().shape({
  file: Yup.mixed(),
});

interface UploadCardProps {
  setToggleModal: (modalActiveStates: boolean) => void;
}

const UploadCard: React.FC<UploadCardProps> = ({ setToggleModal }) => {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [cloudinaryLoading, setCloudinaryLoading] = useState(false);
  const { event } = useAppStore((state) => state);
  const [isSelected, setIsSelected] = useState(false);
  const reader = new FileReader();

  const uploadPicture = useCallback(() => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_FOLDER!);
    axios
      .post(
        "https://api.Cloudinary.com/v1_1/inawo-medias/image/upload",
        formData
      )
      .then((response) => {
        setUrl(
          `${response?.data?.secure_url}?inawoHeight=${response?.data?.height}&inawoWidth=${response?.data?.width}`
        );
        setCloudinaryLoading(false);
        setImage(response.data.secure_url);
      })
      .catch((error) => {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
        setCloudinaryLoading(false);
      });
    setCloudinaryLoading(true);
  }, [image]);

  const [updateInvitationCard, { loading: updateInvitationCardLoading }] =
    useMutation(UpdateInvitationCard, {
      onCompleted() {
        setIsSelected(false);
        setToggleModal(false);
        toast.success(
          <p className="toast">Your invitation card has been uploaded.</p>
        );
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  useEffect(() => {
    if (url === "" && image !== "") {
      uploadPicture();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <div className={styles["uploadCard"]}>
      <div className={styles["uploadCard-heading"]}>
        <h4>Upload Invitation Card</h4>
      </div>
      <Formik
        initialValues={{
          file: "",
        }}
        validationSchema={UploadCardSchema}
        onSubmit={(_values, actions) => {
          updateInvitationCard({
            variables: {
              eventId: event.id,
              template: url,
            },
            refetchQueries: [
              {
                query: InvitationCardTemplate,
                variables: { eventId: event.id },
              },
            ],
          });
          actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["uploadCard-form"]}>
            <div className={styles["uploadCard-form-container"]}>
              <div className={styles["uploadCard-image-box"]}>
                {isSelected === false && url === "" ? (
                  <>
                    <input
                      id="add-file"
                      type="file"
                      className={styles["file"]}
                      name="file"
                      accept="image/*"
                      onChange={(event: any) => {
                        if (event.target.files[0] && event.target.files) {
                          reader.onload = function (e: any) {
                            setImage(e.target.result);
                          };
                          reader.readAsDataURL(event.target.files[0]);
                          setIsSelected(true);
                        }
                      }}
                      onBlur={props.handleBlur}
                    ></input>
                    <label htmlFor="add-file">
                      <span>
                        <Icon iconName="link" />
                        Click here to upload file
                      </span>
                      <br />
                      <span>.png or .jpg only</span>
                    </label>
                  </>
                ) : isSelected === true && url === "" ? (
                  <InnerLoading />
                ) : (
                  <img src={image} alt="addPictures" />
                )}
              </div>
            </div>
            <div className={styles["uploadCard-button-container"]}>
              <div className={styles["uploadCard-button"]}>
                <Button
                  type="submit"
                  label="Upload"
                  disabled={url === "" || cloudinaryLoading}
                  loader={updateInvitationCardLoading}
                >
                  Upload
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UploadCard;
