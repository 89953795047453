import { useQuery } from "@apollo/client";
import { useContext, useState, SetStateAction, Dispatch } from "react";
import {
  InvitationListByRSVP,
  NumberOfGuestsByCategory,
} from "src/graphql/queries";
import styles from "./pending.module.scss";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import EmptyState from "./EmptyState";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { invitationListByRSVPStatus, noGuestPerCategory } from "src/types/api";
import { SearchInput } from "src/components/Input";
import Icon from "src/components/Icon";
import Row from "./Row";
import { useNavigate } from "react-router-dom";
import { SectionRsvpCountType } from "src/utils/types";
import useAppStore from "src/store/utils";

type Props = {
  count: SectionRsvpCountType;
  setCount: Dispatch<SetStateAction<SectionRsvpCountType>>;
};

const Pending: React.FC<Props> = (props) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startPage, setStartPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const contextValue = useContext<ValueProps | null>(AppContext);
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { loading: pendingLoading, data: pending } =
    useQuery<invitationListByRSVPStatus>(InvitationListByRSVP, {
      variables: {
        eventId: event.id,
        rsvpStatus: "pending",
        page: page,
        pageSize: pageSize,
      },
      onCompleted(response) {
        setTotalPages(response?.invitationListByRSVPStatus.length);
        props.setCount({
          ...props.count,
          pending: response.invitationListByRSVPStatus.length,
        });
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const { data: categories } = useQuery<noGuestPerCategory>(
    NumberOfGuestsByCategory,
    {
      variables: {
        eventId: event.id,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const results = !!pending
    ? pending?.invitationListByRSVPStatus?.filter(
        (guest) =>
          guest.first_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          guest.last_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
      )
    : [];

  return (
    <div className={styles["pending"]}>
      {pendingLoading ? (
        <div className={styles["pending__loading"]}>
          <InnerLoading />
        </div>
      ) : !!pending &&
        !!pending.invitationListByRSVPStatus &&
        pending.invitationListByRSVPStatus.length ? (
        <>
          <div className={styles["search"]}>
            <div className={styles["search__inner"]}>
              <SearchInput
                icon={<Icon iconName="search" />}
                name="Search names, emails etc."
                placeholder="Search names, emails etc."
                value={searchValue}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setSearchValue(e.target.value)
                }
              />
            </div>
            <div className={styles["count"]}>
              <Icon iconName="twoUsers" />{" "}
              {pending.invitationListByRSVPStatus.length}
            </div>
          </div>
          <main className={styles["pending__main"]}>
            <div className={styles["pending__table"]}>
              <div className={styles["pending__table-header"]}>
                <h4>NAME</h4>
                <h4>EMAIL</h4>
                <h4>WHATSAPP NUMBER</h4>
              </div>
              <div className={styles["pending__table-list"]}>
                {results?.map((data) => (
                  <>
                    <Row
                      key={uuidv4()}
                      id={data?.id}
                      firstName={data?.first_name}
                      lastName={data?.last_name}
                      phoneNumber={data?.phone}
                      email={data?.email ?? ""}
                    />
                  </>
                ))}
              </div>
            </div>
          </main>
        </>
      ) : (
        <EmptyState categories={categories} page={page} pageSize={pageSize} />
      )}

      {/*!!pending && !!pending.invitationListByRSVPStatus && (
        <Pagination
          pageSize={pageSize}
          startPage={startPage}
          setPageSize={setPageSize}
          setStartPage={setStartPage}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
					)*/}
    </div>
  );
};

export default Pending;
