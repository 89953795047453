import { useMutation } from "@apollo/client";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import Button from "src/components/Button";
import { BulkDeleteMedia } from "src/graphql/mutations";
import {
  BulkDeleteMedia as bulkDeleteMedia,
  BulkDeleteMediaVariables,
} from "src/types/api";
import styles from "./deleteSelected.module.scss";
import { useNavigate } from "react-router-dom";
import { GetMedia } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import { SelectedAlbumType } from "src/utils/types";

interface DeleteBackstoryProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setShowDelete: Dispatch<SetStateAction<boolean>>;
  // page: number;
  // pageSize: number;
  deleteList: string[];
  selectedAlbum?: SelectedAlbumType;
}

const DeleteSelected = ({
  setOpen,
  setShowDelete,
  deleteList,
  selectedAlbum,
}: DeleteBackstoryProps) => {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const page = 1;
  const pageSize = 200;

  const [deleteMediasFn, { loading }] = useMutation<
    bulkDeleteMedia,
    BulkDeleteMediaVariables
  >(BulkDeleteMedia, {
    onCompleted() {
      setOpen(false);
      setShowDelete(false);
      toast.success(
        <p className="toast">
          You have successfully deleted{" "}
          {deleteList.length > 0 ? deleteList.length : ""} pictures from your
          photobook.
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={`${styles["deleteSelected--content"]} ${styles["show"]}`}>
      <h1>Delete Picture(s)</h1>
      <p>
        You are about to remove {deleteList.length > 0 ? deleteList.length : ""}{" "}
        pictures from your photobook. Do you wish to proceed?
      </p>
      <span className={styles["deleteSelected--content-btnContainer"]}>
        <div>
          <Button
            label="no cancel"
            type="button"
            design="secondary"
            handleClick={() => {
              setOpen(false);
            }}
          >
            No, Cancel
          </Button>
          <Button
            type="submit"
            handleClick={() => {
              deleteMediasFn({
                variables: { eventId: event.uuid, mediaId: deleteList },
                refetchQueries: [
                  {
                    query: GetMedia,
                    fetchPolicy: "cache-first",
                    variables: {
                      eventId: event.uuid,
                      filter: {
                        no_album: true,
                        page,
                        pageSize,
                      },
                    },
                  },
                  {
                    query: GetMedia,
                    variables: {
                      eventId: event.uuid,
                      filter: {
                        album_id: selectedAlbum?.id,
                        page,
                        pageSize,
                      },
                    },
                  },
                ],
              });
            }}
            design="tertiary"
            label="Yes, Proceed"
            loader={loading}
          >
            Yes, Delete
          </Button>
        </div>
      </span>
    </div>
  );
};

export default DeleteSelected;
