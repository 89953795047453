import Button from "src/components/Button";
import { v4 as uuidv4 } from "uuid";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import styles from "./styles.module.scss";
import { Dispatch, SetStateAction, useState } from "react";
import { SearchInput } from "src/components/Input";
import Icon from "src/components/Icon";
import { useMutation, useQuery } from "@apollo/client";
import {
  addStreamerFromInviteList,
  addStreamerFromInviteListVariables,
  filterInvitationListBy,
  filterInvitationListByVariables,
  filterInvitationListBy_filterInvitationListBy,
  invitationListFilter,
} from "src/types/api.d";
import { FilterInvitationList } from "src/graphql/queries";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AddStreamerFromInviteList } from "src/graphql/mutations/event.mutation";
import { GetEventStreamers } from "src/graphql/queries/event.queries";
import { formatPhoneNumber } from "src/utils";
import useAppStore from "src/store/utils";

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  page: number;
  pageSize: number;
}

function AddStreamer({ setOpen, page, pageSize }: Props) {
  const [searchValue, setSearchValue] = useState("");
  const [streamerDetails, setStreamerDetails] =
    useState<filterInvitationListBy_filterInvitationListBy>(
      {} as filterInvitationListBy_filterInvitationListBy
    );
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [filter] = useState<invitationListFilter>(
    invitationListFilter.all ||
      invitationListFilter.not_sent ||
      invitationListFilter.sent
  );

  const [addStreamerFn, { loading: addStreamerLoading }] = useMutation<
    addStreamerFromInviteList,
    addStreamerFromInviteListVariables
  >(AddStreamerFromInviteList, {
    onCompleted() {
      toast.success(
        <p className="toast">You have successfully added a streamer.</p>
      );
      setOpen(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const { data: guestList, loading: guestListLoading } = useQuery<
    filterInvitationListBy,
    filterInvitationListByVariables
  >(FilterInvitationList, {
    variables: {
      eventId: event.id,
      filter: filter,
      page,
      pageSize,
    },
    skip: !event.id,
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const results =
    guestList?.filterInvitationListBy?.filter(
      (guest) =>
        guest?.first_name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        guest?.last_name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
    ) ?? [];

  return (
    <div className={styles["addStreamer"]}>
      <h1>Select a Streamer</h1>
      <SearchInput
        icon={<Icon iconName="search" />}
        name="Search Guests"
        placeholder="Search Guests"
        value={searchValue}
        onChange={(e: { target: { value: SetStateAction<string> } }) =>
          setSearchValue(e.target.value)
        }
      />
      <ScrollArea.Root className={styles["addStreamer--content__area"]}>
        <ScrollArea.Viewport className={styles["addStreamer--content__list"]}>
          {guestListLoading ? (
            <p>Loading...</p>
          ) : !!guestList &&
            !!guestList?.filterInvitationListBy?.length &&
            results.length &&
            guestList?.filterInvitationListBy.length > 0 ? (
            results.map((guest) => (
              <div
                key={uuidv4()}
                className={`${styles["addStreamer--content__list__item"]} ${
                  guest?.id === streamerDetails.id
                    ? styles["addStreamer__list__item__selected"]
                    : styles["addStreamer--content__list__item"]
                }`}
                onClick={() => !!guest?.id && setStreamerDetails(guest)}
              >
                <p>
                  {guest?.first_name} {guest?.last_name}
                </p>
              </div>
            ))
          ) : (
            <p className={styles["addStreamer--content__none"]}>
              {searchValue !== ""
                ? "No guests found"
                : "You've not added guests"}
            </p>
          )}
        </ScrollArea.Viewport>
        {/* <ScrollArea.Scrollbar orientation="horizontal">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar> */}
        <ScrollArea.Scrollbar
          className={styles["addStreamer--content__list__scrollbar"]}
          orientation="vertical"
        >
          <ScrollArea.Thumb
            className={styles["addStreamer--content__list__thumb"]}
          />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner />
      </ScrollArea.Root>

      <div className={styles["addStreamer--btn"]}>
        <Button
          type="submit"
          label="Add streamer"
          handleClick={() =>
            streamerDetails.id &&
            addStreamerFn({
              variables: {
                args: [
                  {
                    eventId: event.id,
                    inviteListId: streamerDetails.id,
                    streamer_name: `${streamerDetails.first_name} ${streamerDetails.last_name}`,
                    streamer_email: streamerDetails.email,
                    streamer_phone: formatPhoneNumber(streamerDetails.phone),
                  },
                ],
              },
              refetchQueries: [
                {
                  query: GetEventStreamers,
                  variables: { eventId: event.id },
                },
              ],
            })
          }
          loader={addStreamerLoading}
        >
          Add streamer
        </Button>
      </div>
    </div>
  );
}

export default AddStreamer;
