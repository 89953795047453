import { useQuery } from "@apollo/client";
import InnerLoading from "src/components/InnerLoading";
import DashboardLayout from "src/layouts/dashboard";
import { getEventStreamers, getEventStreamersVariables } from "src/types/api";
import EmptyState from "./EmptyState";
import LiveHallList from "./LiveHallList";
import styles from "./styles.module.scss";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GetEventStreamers } from "src/graphql/queries/event.queries";
import DialogModal from "src/components/Dialog";
import ChangeStreamer from "./modals/ChangeStreamer";
import useAppStore from "src/store/utils";

const InawoLive = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { event } = useAppStore((state) => state);
  const [pagination] = useState({ page: 1, pageSize: 3 });
  const { data: streamersData, loading } = useQuery<
    getEventStreamers,
    getEventStreamersVariables
  >(GetEventStreamers, {
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <DashboardLayout pageName="Live Hall">
      <div className={styles["liveHall"]}>
        <div className={styles["liveHall__main"]}>
          <div
            className={`${
              loading ? styles["liveHall"] : styles["liveHall-empty"]
            }`}
          >
            {loading ? (
              <div className={styles["liveHall__loading"]}>
                <InnerLoading />
              </div>
            ) : !!streamersData?.getEventStreamers &&
              streamersData?.getEventStreamers.length > 0 ? (
              <>
                <header className={styles["liveHall__header"]}>
                  <div className={styles["liveHall__header__button"]}>
                    <DialogModal
                      open={open}
                      onOpenChange={setOpen}
                      trigger={
                        <div
                          onClick={() => setOpen(true)}
                          className={styles["liveHall__trigger"]}
                        >
                          <span>Change Streamer</span>
                        </div>
                      }
                    >
                      <ChangeStreamer
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        setOpen={setOpen}
                      />
                    </DialogModal>
                  </div>
                </header>

                <main className={styles["liveHall__main"]}>
                  <div className={styles["liveHall__table"]}>
                    <div className={styles["liveHall__table-header"]}>
                      <h4>Name</h4>
                      <h4>Status</h4>
                      <h4 className={styles["liveHall__table-header__end"]}>
                        &nbsp;
                      </h4>
                    </div>

                    <div className={styles["liveHall__table-list"]}>
                      {streamersData.getEventStreamers.map((item) => (
                        <LiveHallList
                          status={item?.is_streaming ? "online" : "offline"}
                          name={item?.deviceName ?? "Unavailable"}
                          id={item?.Id}
                        />
                      ))}
                    </div>
                  </div>
                </main>
              </>
            ) : (
              <EmptyState
                page={pagination.page}
                pageSize={pagination.pageSize}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default InawoLive;
